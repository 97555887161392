import axios from "axios";
import {Loading, Message} from 'element-ui';
import {SERVERTAPI} from "@/config/index";
import store from '@/store/index'

const instance = axios.create({
  baseURL: SERVERTAPI,
  timeout: 20000,
  headers: {}
});
let loadingInstance
instance.interceptors.request.use(function (config) {
  // 在发送请求之前显示loading
  store.commit('SET_LOAD_STATUS', true)

  // console.log(config.config.isLoading)

  // config.config.isLoading && (loadingInstance = Loading.service({
  //   lock: true,
  //   text: '加载中',
  //   spinner: 'el-icon-loading',
  //   background: 'rgba(0, 0, 0, 0.5)'
  // }));
  // 在请求头中添加token和device_token
  config.token = localStorage.getItem('TOKEN') || '';
  config.device_token = "";
  return config;
}, function (error) {
  // 对请求错误做些什么
  return Promise.reject(error);
});

instance.interceptors.response.use(function (response) {
  // 对响应数据做点什么
  const {status, data} = response;
  // 隐藏loading
  store.commit('SET_LOAD_STATUS', false)
  // loadingInstance.close();
  if (status === 200) {
    switch (data.status) {
      case 400:
        // Message.error(data.mess);
        // Router.push({path: '/Login'})
        break;
    }
    return Promise.resolve(data);
  } else {
    if (error.code === 'ECONNABORTED' && error.message.indexOf('timeout') !== -1) {
      // 处理超时请求提示
      Message.error('请求超时，请检查您的网络连接');
    } else {
      Message.error(error);
    }
    Message.error(data.mess);
    // return Promise.reject(response);
  }
}, error => {
  // 对响应错误做点什么
  // 隐藏loading
  store.commit('SET_LOAD_STATUS', false)
  // loadingInstance.close();
  Message.error(error);
  // return Promise.reject(error);
  return error;
});

export default instance;
