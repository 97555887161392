import en from './en.json'
import zhHans from './zh-Hans.json'
import zhHant from './zh-Hant.json'
import de from './de.json'
import es from './es.json'
import fr from './fr.json'
import ida from './ida.json'
import ja from './ja.json'
import ko from './ko.json'
import may from './may.json'
import pt from './pt.json'
import ru from './ru.json'
import th from './th.json'
import yn from './yn.json'
import ara from './ara.json'
export default {
	'zh-Hans': zhHans,
	'en': en,
	'zh-Hant': zhHant,
	'de': de,
	'es': es,
	'fr': fr,
	'ida': ida,
	'ja': ja,
	'ko': ko,
	'may': may,
	'pt': pt,
	'ru': ru,
	'th': th,
	'yn': yn,
	'ara': ara,
}
