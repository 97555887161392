<template>
  <div class='home'>
    <div class="container-page" v-if="!isSearch">
      <div class="containerPage">
        <!-- 轮播图 -->
        <div class="carousel" v-loading="isLoading">
          <el-carousel :interval="5000" arrow="hover" trigger="click">
            <el-carousel-item v-for="(item, index) in carouselList" :key="index">
              <img :src="item.ad_pic" alt="">
            </el-carousel-item>
          </el-carousel>
        </div>
        <div class="column" v-if="($store.state.userInfo.rz_zt || $store.state.userInfo.shop_id == 1)">
          <div class="column_header">
            <div class="l">
              <span>{{ $t('auctionCompany.t33') }}</span>
            </div>
            <div class="r">
              <span @click="$router.push('/auctionCompany')">{{ $t('auctionCompany.t34') }} ></span>
            </div>
          </div>
          <div class="column_view">
            <div class="column_viewItem" v-for="item in goodsNew1List" @click="goLink(item.id, 1)">
              <goodsProduct :info="item" />
            </div>
          </div>
        </div>

        <!--最新商品-->
        <div class="column">
          <div class="column_header">
            <div class="l">
              <span>{{ $t('auctionCompany.t35') }}</span>
            </div>
            <div class="r">
              <span @click="$router.push('/consignment')">{{ $t('auctionCompany.t34') }} ></span>
            </div>
          </div>
          <div class="column_view">
            <div class="column_viewItem" v-for="item in goodsNewList" @click="goLink(item.id)">
              <goodsProduct :info="item" />
            </div>
          </div>
        </div>

        <!-- 最新优惠 -->
        <div class="specialOffer" v-if="false">
          <div class="title">{{ $t('home.t1') }}</div>
          <div class="content">
            <div class="item" v-for="(item, index) in lastofferList" :key="index" @click="goolsTo(item.shop_id)">
              <div class="imgContainer">
                <img :src="item.thumb_url" alt="">
              </div>
              <div class="price">
                <span>${{ item.zs_price }}</span>
                <span>${{ item.shop_price }}</span>
              </div>
            </div>
            <div class="last">
              <div class="top">{{ $t('home.t2') }}</div>
              <div class="center">{{ $t('home.t3') }}</div>
              <div class="btn" @click="handleClickTo">{{ $t('home.t4') }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <searchAll v-else></searchAll>
  </div>
</template>
<script>
import { Swiper, SwiperSlide } from 'swiper/vue';
import { Navigation, Pagination } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation'
import 'swiper/css/pagination'
import hotswiper from "@/components/hotswiper.vue";
import moreoffers from "@/components/moreoffers.vue";
import searchAll from "@/components/searchAll.vue";
import { mapState } from 'vuex';
import goodsProduct from "@/views/consignment/components/goods_product.vue";

export default {

  data() {
    return {
      modules: [Pagination, Navigation],
      carouselList: [],
      lastofferList: [],
      demandProducts: [],
      goodslist: [],
      hotShopData: [],
      page: 1,
      shopId: '',
      logo: '',
      goodsNewList: [], // 最新商品
      goodsNew1List: []

    }
  },
  created() {
    if (!this.isShowBuy_seller) {
      this.$http.getAdByTag({
        tag: 'pc_index_banner'
      }).then(res => {
        const { status, data } = res
        if (status === 200) {
          this.carouselList = data
        } else {
          console.log('banner数据获取异常');
        }
      })
      // await this.getListShops()
      // await this.getGoodsList(this.shopId)
      // this.getPcIndexShowcase()
      // this.getPcIndexGoodsHome()
      // this.getGoodsListTo() // 最新优惠
    }
    this.getNewList(); //最新商品
    this.getShopGoodsAllLists()
  },
  components: {
    goodsProduct,
    Swiper,
    SwiperSlide,
    hotswiper,
    moreoffers,
    searchAll
  },
  computed: {
    ...mapState(['isLoading', 'isSearch', 'isLogin', 'isShowBuy_seller'])
  },
  methods: {
    // 获取最新商品
    async getNewList() {
      const res = await this.$http.getCategoryGoodsList({
        page: 1,
        size: 10,
        cate_id: 273,
        sort: 'new'
      })
      if (res.status === 200 && res.data.length !== 0) {
        // this.goodsNewList = res.data.slice(0, 5);
        this.goodsNewList = res.data.goodres;
      }
    },
    // 获取拍卖商品
    getShopGoodsAllLists() {
      this.$http.getShopGoodsAllList({
        page: 1,
        cate_id: 273,
        sort: 'new'
      }).then(res => {
        if (res.status === 200) {
          this.goodsNew1List = res.data
        }
      })
    },
    // 最新商品点击
    goLink(id,type) {
      if (type === 1) {
        this.$router.push({
          path: "/AuctionentryDetails",
          query: {
            id
          },
        });
      } else {
        this.$router.push({
          path: "/goodsDetails",
          query: {
            id
          },
        });
      }
    },
    //  最新优惠
    // getGoodsListTo() {
    //   this.$http.getGoodsList({
    //     page: 1,
    //     size: 5
    //   }).then(res => {
    //     if (res.status === 200) {
    //       this.lastofferList = res.data.slice(0, 5)
    //     }
    //   })
    // },
    // 获取最新优惠
    // getPcIndexShowcase() {
    //   this.$http.getPcIndexShowcase().then(res => {
    //     if (res.status === 200) {
    //       this.lastofferList = res.data.new_goods.list;
    //     }
    //   })
    // },
    // 获取商品
    async getGoodsList(id) {
      try {
        await this.getListShops(); // 确保在获取商品列表之前先获取店铺列表
        const res = await this.$http.getallgoods({
          shop_id: id,
          page: 1,
          sort: "zonghe",
        });
        this.goodslist = res.data.data.slice(0, 10);
      } catch (error) {
        console.error('Error fetching list of goods:', error);
      }
    },
    // 跳转至商品详情页
    // handleClickGoodsDetails(id) {
    //   if (!this.isLogin) {
    //     this.$message.warning(this.$t('home.t7'))
    //     return
    //   }
    //   this.$router.push({
    //     path: '/goodsDetail',
    //     query: {
    //       id: id
    //     },
    //   })
    // },
    // 热门商品
    getPcIndexGoodsHome() {
      this.$http.getPcIndexGoodsHome({ tag: 'is_hot', num: 6 }).then(res => {
        if (res.status === 200) {
          this.demandProducts = res.data;
        }
      })
    },
    // 跳转商品详情
    goolsTo(id) {
      if (!this.isLogin) {
        this.$message.warning(this.$t('home.t7'))
        return
      }
      // 跳转
      this.$router.push({
        path: "/goodsDetail",
        query: {
          id
        },
      });
    },
    handleClickTo() {
      this.$router.push({ name: 'myHeart', query: { isSelectedStores: true } })
    }
  },
}
</script>
<style lang='less' scoped>
.home {
  width: 100%;
  margin: auto;
  max-width: 1200px;
  box-sizing: border-box;
  // padding: 0 96px;
  background-color: #fff;

  .container-page {
    .containerPage {

      //轮播图
      .carousel {
        height: 440px;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }

      // 最新商品
      .column {
        margin-top: 30px;
        width: 100%;
        padding: 0 20px;
        box-sizing: border-box;

        .column_header {
          display: flex;
          padding: 8px 35px 8px 10px;
          box-sizing: border-box;
          justify-content: space-between;
          border-bottom: 2px solid #000;

          span {
            font-weight: 700;
            cursor: pointer;
            user-select: none;
          }

        }

        .column_view {
          min-height: 500px;
          padding: 20px 0;
          display: grid;
          grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
          justify-content: center;
          grid-column-gap: 20px;

          .column_viewItem {
            display: flex;
            justify-content: center;
          }
        }
      }

      //最新优惠
      .specialOffer {
        padding: 15px;
        box-sizing: border-box;
        background-color: #fff;

        .title {
          color: #000c22;
          font-family: Inter;
          font-size: 20px;
          font-style: normal;
          font-weight: 700;
          line-height: 20px;
        }

        .content {
          margin-top: 10px;
          //display: flex;
          //flex-wrap: nowrap;
          display: grid;
          grid-template-columns: repeat(6, 1fr);

          .item {
            height: 200px;
            position: relative;

            .imgContainer {
              height: 200px;
              width: 100%;
              border-radius: 0px;
              overflow: hidden;
              background-color: #fff;

              img {
                width: 100%;
                height: 100%;
              }
            }

            .price {
              padding: 2px 14px;
              display: flex;
              flex-direction: row;
              justify-content: space-around;
              align-items: center;
              position: absolute;
              bottom: 0;
              left: 50%;
              font-family: D-DIN-PRO;
              width: fit-content;
              height: 26px;
              background-color: #222;
              color: #fff;
              transform: translate(-50%) translateY(-50%);

              span {
                &:last-child {
                  margin-left: 4px;
                  color: #fff;
                  font-size: 14px;
                  font-style: normal;
                  font-weight: 400;
                  line-height: normal;
                  text-decoration-line: line-through;
                }
              }

              span {}
            }
          }

          .last {
            position: relative;
            border: 2px solid #e6e6e6;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            .top {
              color: #000c22;
              text-align: center;
              font-family: Inter;
              font-size: 20px;
              font-style: normal;
              font-weight: 700;
              line-height: 30px;
              text-transform: none;
            }

            .center {
              margin: 16px 0 21px;
              color: #bdb1a1;
              font-family: PingFang SC;
              font-size: 14px;
              font-style: normal;
              font-weight: 400;
              line-height: 14px;
            }

            .btn {
              cursor: pointer;
              width: 154px;
              height: 48px;
              flex-shrink: 0;
              background-color: #d73a4e;
              color: #fff;
              font-family: Inter;
              font-size: 18px;
              font-style: normal;
              font-weight: 700;
              line-height: normal;
              text-transform: none;
              display: flex;
              justify-content: center;
              align-items: center;
            }
          }
        }
      }
    }
  }
}

::v-deep {
  .el-carousel__container {
    height: 440px;
  }

  .el-carousel__button {
    width: 10px;
    height: 10px;
    margin: 2.5px;
    background-color: #0000;
    border-radius: 50px;
    box-sizing: border-box;
    border: 1px solid #222;
  }

  .is-active {

    .el-carousel__button {
      width: 26px;
      background-color: #222;
    }
  }

}

@media screen and (max-width: 1200px) {
  .carousel img {
    object-fit: unset !important;
  }
}

@media screen and (max-width: 576px) {
  .containerPage {
    .carousel {
      height: 166px !important;

      /deep/ .el-carousel__container {
        height: 166px !important;
      }
    }
  }
}

@media screen and (max-width: 1000px) {
  .carousel {
    height: 300px !important;

    /deep/ .el-carousel__container {
      height: 300px !important;
    }
  }
}

@media screen and (min-width: 1001px) {
  .carousel {
    height: 440px !important;

    /deep/ .el-carousel__container {
      height: 440px !important;
    }
  }
}

@media screen and (max-width: 1200px) {
  .content {
    grid-template-columns: repeat(5, 1fr) !important;
    //grid-row-gap: 20px !important;
  }
}

@media screen and (max-width: 900px) {
  .content {
    grid-template-columns: repeat(4, 1fr) !important;
    grid-row-gap: 10px !important;
  }
}

@media screen and (max-width: 800px) {
  .content {
    grid-template-columns: repeat(3, 1fr) !important;
    grid-row-gap: 20px !important;
  }
}

@media screen and (max-width: 550px) {
  .content {
    grid-template-columns: repeat(2, 1fr) !important;
    grid-row-gap: 10px !important;
    grid-column-gap: 10px !important;

    .item {
      height: 200px !important;
    }
  }

  .specialOffer {
    padding: 5px !important;
  }
}

@media screen and (max-width: 400px) {
  .content {

    .item,
    .last {
      width: 140px !important;
      height: 150px !important;
      overflow: hidden;

      img {
        width: 140px;
        height: 150px !important;
      }
    }
  }

  .specialOffer {}
}

@media screen and (max-width: 440px) {
  .column_view {
    padding: 20px 10px !important;
    grid-template-columns: repeat(auto-fit, minmax(150px, 1fr)) !important;
  }
}

@media screen and (max-width: 340px) {
  .column_view {
    grid-template-columns: repeat(auto-fit, minmax(120px, 1fr)) !important;

    /deep/ .goods-product {
      .el-image {
        display: flex;
        justify-content: center;

        .el-image__inner {
          width: 120px !important;
          height: 120px !important;
        }
      }
    }
  }
}
</style>
