import apiList from "@/api/api"
import { Message } from 'element-ui';
// 获取购物车列表
export const addShoppingCart = ({ dispatch }, params) => {
    return new Promise(async(resolve, reject) => {
      const {status,mess} = await apiList.getaddcart(params)
      if(status===200){
            Message.success(mess)
            dispatch('getCartList')
            dispatch('getCartNum')
            resolve()
      }
    });
};
export const getCartList =  ({dispatch, commit }, params={page:1}) => {
  return new Promise(async(resolve, reject) => {
        const {status, data} = await apiList.getCartList(params)
        if(status===200){
          const _data = data.map(res=>{
            res.selected =false
            res.goodres.forEach(goods=>{
              goods.selected = false
            })
            return res
          })
          commit("ADD_SHOPPING_CART", _data);
          dispatch('getCartNum')
          resolve(data)
        }
  });
}

// 获取购物车数量
export const getCartNum = ({ commit }, params) => {
  return new Promise(async(resolve, reject) => {
    const {data:{countnum}={},status} = await apiList.getgetnum()
    if(status===200){
          commit("SHOPPING_CART_NUM", countnum)
          resolve(countnum)
    }
  });
}

// // 退出登陆
// export const signOut = ({ commit }) => {
//     localStorage.removeItem("loginInfo");
//     localStorage.removeItem('TOKEN')
//     commit('SET_USER_ADDRESS', []);
//     commit('SET_ADDRESS_LIST', []);
//     commit("SET_USER_LOGIN_INFO", {});
//     router.push("/");
// };