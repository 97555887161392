// 各项全局常量  cdn静态资源配置
const APPNAME = 'Kogans'; //app名称
const LIVESOCKET = 'wss://paimai.kogans.cyou/wss'; // 聊天sokect地址，服务器端需要放行8273端口
let SERVERTAPI = 'https://paimai.kogans.cyou/'; // 线上环境
const STATICURL = 'https://paimai.kogans.cyou/'; //静态资源地址 ***正式上线必须换成自己的***
const SECRETKEY = 'yiling6670238160ravntyoneapp7926'; //  api密钥约定，搭配md5加密在请求时生成api_token，对应后端数据表sp_secret的client_secret字段值
if (process.env.NODE_ENV === "development") {
	SERVERTAPI = 'https://paimai.kogans.cyou/'; // 测服地址
}
localStorage.setItem('baseUrl', SERVERTAPI)

export {
	APPNAME,
	LIVESOCKET,
	SERVERTAPI,
	STATICURL,
	SECRETKEY
}