import Vue from "vue";
import Vuex from "vuex";
import {createVuexPersistedState} from "vue-persistedstate";
import * as actions from "./actions";
import * as mutations from "./mutations";
import * as getters from "./getters";
import {setCardList} from "./mutations";

Vue.use(Vuex);
const state = {
    localLang: {
        code: 'en-us', 
        language: "English"
    },
    isLoading: false, // 是否展示loading动画
    isShowdown: false,
    isLogin: false,
    isShowCardrawer: false,
    cartList: [], // 购物车列表
    userInfo: {}, // 用户信息
    addressList: [], //收获地址列表
    curAddressIndex: 0, // 默认收货地址
    cartNum: 0,
    isSearch: false, // 是否为商品搜索状态
    search_name: '', //首页搜索框的值
    search_list: [], //商品搜索出的List
    store_list: [], //搜索的店铺
    search_page: 1,
    isShop_Store: false, //当前搜索的是商品还是店铺
    isShowFooter: false, // 是否显示底部
    isShowBuy_seller: false, //是否是买/卖家
    isShowModalLogin: false, //是否显示登录框
    Merchantinformation: {}, //商家信息
    langlist: [],
    lang: '',
    phonelist: [],
    qh: {},
    categoryIndex: 1, // 分类传值
    oenAStoreVal: false,
    historyList: [],//搜索店铺历史
    msgNum: 0,//消息数量
    isChatMSG:false, // 控制铃铛的消息
    isChatSeller:false, // 控制联系卖家的
    serviceUser:{}, // 系统客服消息
    userTit:{},
};
export default new Vuex.Store({
    plugins: [
        createVuexPersistedState({
            key: "vuex",
            storage: window.localStorage,
            ...state
        })
    ],
    state,
    getters,
    actions,
    mutations
});
