import Vue from 'vue'
import VueRouter from 'vue-router'
import home from '@/views/home/index.vue'
import store from '../store/index'

const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function (location) {
    return originalPush.call(this, location).catch(err => {
    })
};
Vue.use(VueRouter)

const routes = [
    // 寄卖中心
    {
        path: '/consignment',
        name: 'consignment',
        component: () => import('@/views/consignment/index.vue')
    },
    // 寄卖商品详情
    {
        path: '/goodsDetails',
        name: 'goodsDetails',
        component: () => import('@/views/consignment/components/goodsDetails/index.vue')
    },
    // 商家商品详情
    {
        path: '/goodsShopDetails',
        name: 'goodsShopDetails',
        component: () => import('@/views/consignment/components/goodsShopDetails/index.vue')
    },
    // 购物袋
    {
        path: '/shoppingBag',
        name: 'shoppingBag',
        component: () => import('@/views/shoppingBag/index.vue')
    },
    // 立即结算
    {
        path: '/shopCard',
        name: 'shopCard',
        component: () => import('@/views/shoppingBag/shopCard.vue')
    },
    // {
    //     path: '/',
    //     name: 'home',
    //     component: home
    // },
    // 拍卖场
    {
        path: '/',
        name: 'home',
        component: () => import('@/views/auctionCompany/index.vue')
    },
    // 拍卖场
    // {
    //     path: '/auctionCompany',
    //     name: 'auctionCompany',
    //     component: () => import('@/views/auctionCompany/index.vue')
    // },
    // 拍卖详情
    {
        path: '/AuctionentryDetails',
        name: 'AuctionentryDetails',
        component: () => import('@/views/auctionCompany/AuctionentryDetails')
    },
    {
        path: '/openStore',
        name: 'openStore',
        component: () => import('@/views/openStore/index.vue')
    },
    {
        path: '/goodsDetail',
        name: 'goodsDetail',
        component: () => import('@/components/goodsDetail/index.vue')
    },
    {
        // 用户
        path: '/user',
        name: 'user',
        redirect: '/user/userInfo',
        component: () => import('@/views/user/index'),
        children: [
            {
                // 1.基础信息
                path: 'userInfo',
                name: 'userInfo',
                component: () => import('@/views/user/userInfo')
            },
            {
                // 1.5我的店铺
                path: 'myShop',
                name: 'myShop',
                component: () => import('@/views/user/myShop')
            },
            {
                // 2.存款
                path: 'deposit',
                name: 'deposit',
                component: () => import('@/views/user/deposit')
            },
            {
                // 3.提现
                path: 'withdraw',
                name: 'withdraw',
                component: () => import('@/views/user/withdraw')
            },
            {
                // 4.账单明细
                path: 'billingDetails',
                name: 'billingDetails',
                component: () => import('@/views/user/billingDetails')
            },
            {
                // 5.存款日志
                path: 'depositList',
                name: 'depositList',
                component: () => import('@/views/user/depositList')
            },
            {
                // 6.提现日志
                path: 'withdrawalList',
                name: 'withdrawalList',
                component: () => import('@/views/user/withdrawalList')
            },
            {
                // 7.绑定USDT
                path: 'bindingUSDT',
                name: 'bindingUSDT',
                component: () => import('@/views/user/bindingUSDT')
            },
            {
                // 8.我的订单
                path: 'MyOrder',
                name: 'MyOrder',
                component: () => import('@/views/user/MyOrder')
            },
            {
                // 8.送货地址
                path: 'shippingAddress',
                name: 'shippingAddress',
                component: () => import('@/views/user/shippingAddress')
            },
            {
                // 9.精选商铺
                path: 'selected',
                name: 'selected',
                component: () => import('@/views/user/selected')
            },
            {
                // 10.申请供应商
                path: 'openAStore',
                name: 'openAStore',
                component: () => import('@/views/user/openAStore')
            },
            {
                // 11.更改密码
                path: 'changPwd',
                name: 'changPwd',
                component: () => import('@/views/user/changPwd')
            },
            {
                // 12.更改支付密码
                path: 'changzfPwd',
                name: 'changzfPwd',
                component: () => import('@/views/user/changzfPwd')
            },
            {
                // 14.关于我们
                path: 'aboutUs',
                name: 'aboutUs',
                component: () => import('@/views/user/aboutUs')
            },
            {
                // 15.帮助服务
                path: 'help',
                name: 'help',
                component: () => import('@/views/user/help')
            },
            {
                // 15.修改个人信息
                path: 'EditPerson',
                name: 'EditPerson',
                component: () => import('@/views/user/EditPerson')
            },
            {
                // 16.商家管理
                path: 'product',
                name: 'product',
                component: () => import('@/views/user/product')
            },// 我的竞拍
            {
                path: 'Mybid',
                name: 'Mybid',
                component: () => import('@/views/user/Mybid')
            },
            {
                // 17.站内信
                path: 'inForm',
                name: 'inForm',
                component: () => import('@/views/user/inForm')
            },
        ]
    },
    {
        // 店铺页
        path: '/shopDetail',
        name: 'shopDetail',
        component: () => import('@/views/shopDetail/index')
    },
    {
        // 我的关注
        path: '/myHeart',
        name: 'myHeart',
        component: () => import('@/views/myHeart/index')
    },
    {
        // 分类店铺大页面
        path: '/category',
        name: 'category',
        component: () => import('@/views/category/index')
    },
    // 系统客服
    {
        path: '/customer',
        name: 'customer',
        component: () => import('@/components/Customer/index.vue'),
    },
    // 底部
    {
        path: '/our-concept-page',
        name: 'our-concept-page',
        component: () => import('@/views/Footer/our-concept-page.vue'),
    },
    {
        path: '/kr-why-sell-with-consignment',
        name: 'kr-why-sell-with-consignment',
        component: () => import('@/views/Footer/kr-why-sell-with-consignment.vue'),
    },
    {
        path: '/trust-expert-authentication',
        name: 'trust-expert-authentication',
        component: () => import('@/views/Footer/trust-expert-authentication.vue'),
    },
    {
        path: '/tips-for-sellers',
        name: 'tips-for-sellers',
        component: () => import('@/views/Footer/selling-hacks-10-tips-to-know.vue'),
    },
    {
        path: '/start-selling',
        name: 'start-selling',
        component: () => import('@/views/Footer/start-selling.vue'),
    },
    {
        path: '/welcome-to-vestiaire-collective',
        name: 'welcome-to-vestiaire-collective',
        component: () => import('@/views/Footer/welcome-to-vestiaire-collective.vue'),
    },
    {
        path: '/our-sustainability-manifesto',
        name: 'our-sustainability-manifesto',
        component: () => import('@/views/Footer/our-sustainability-manifesto.vue'),
    },
    {
        path: '/terms-and-conditions',
        name: 'terms-and-conditions',
        component: () => import('@/views/Footer/terms-and-conditions.vue'),
    },
    {
        path: '/articles/4432099937425',
        name: '4432099937425',
        component: () => import('@/views/Footer/articles/4432099937425.vue'),
    },
    {
        path: '/articles/360004969178',
        name: '360004969178',
        component: () => import('@/views/Footer/articles/360004969178.vue'),
    },
    {
        path: '/user-notice',
        name: 'user-notice',
        component: () => import('@/views/Footer/articles/user-notice.vue'),

    }
];

const router = new VueRouter({
    routes
})
router.beforeEach((to, from, next) => {
    if (to.name === 'goodsDetail' && !localStorage.getItem('token')) {
        // if (!store.state.isLogin) {
        //     store.commit('SET_MODAL_LOGIN', true)
        //     return
        //   }
    }
    // 在导航开始之前滚动到页面顶部
    window.scrollTo(0, 0);
    next(); // 继续导航
})
export default router
