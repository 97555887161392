<template>
  <div class="chat">
    <transition name="collapse">
      <div class='chat_item' v-if="isShowChat">
        <div class="title">
          <div class="icon" v-if="isShowChatcontent" @click="goback">
            <i class="el-icon-arrow-left"></i>
          </div>
          <div class="icon" v-else>
          </div>
          <div v-if="!isShowChatcontent">{{ $t('chat.t1') }}({{ msgNum }})</div>
          <div v-else>{{ to_username || shopname }}</div>
          <div class="icon" @click="handleClose">
            <i class="el-icon-close"></i>
          </div>
        </div>
        <chat-content v-if="isShowChatcontent" :shopToken="shopToken" :id="id"
          @close="handleClosechatcontent"></chat-content>
        <div class="nodata content" v-if="chatList.length === 0 && !shopToken">
          <SvgIcon iconClass="nodata" color="#333" width="80" height="80" />
          {{ $t('information.t2') }}
        </div>

        <div class="content" v-if="chatList.length !== 0 && !isShowChatcontent">
          <div class="chatList_item" v-for="(item, index) in chatList"
            @click="handleClickChatDetails(item.toid, index, item.to_username)">
            <img class="img" :src="item.to_headimgurl" alt="">
            <div>
              <div>
                <div class="name">{{ item.to_username }}</div>
                <div class="ccc">{{ item.dates }}</div>
              </div>
              <div>
                <div class="ccc">
                  {{
                    item.message_type === 'img' ? getImageUrl(item.msg) :
                    item.message_type === 'goods' ? parseGoodsStr(item.message) : item.msg
                  }}
                </div>
                <div class="read" v-if="item.msgcount > 0">{{ item.msgcount }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </transition>
    <div class="bell" @click=" handleClickOpenChats()" v-if="!isShowBell">
      <i class="el-icon-bell"></i>
    </div>
  </div>
</template>
<script>
import { Socket } from "@/utils/socket";
import ChatContent from "./chatcontent.vue";
import Store from "@/store";
import { mapMutations, mapState } from 'vuex';
export default {
  props: {
    isShowChat: {
      default: false,
      type: Boolean
    },
    isShowBell: {},
    shopToken: {

    },
    shopname: {

    }
  },
  data() {
    return {
      chatList: [],
      personlist: [],
      isShowChatcontent: false,
      id: '',
      to_username: '',
      chatVal: '',
      formData: '',
      content_username: '',
      token: localStorage.getItem('token'),
      socketObj: Socket.getInstance()
    }
  },
  components: {
    ChatContent
  },
  async created() {
    // this.isShowChatcontent = true
    // if(this.shopToken){
    //   this.id = this.shopToken
    //   this.isShowChatcontent = true
    //   console.log(this.id,this.isShowChatcontent);
    // }
    this.formData = this.$http.returnCommonParams(
      "api/Common/uploadPic",
      "usernfo"
    );
  },
  mounted() {
    if (this.shopToken) {
      this.isShowChatcontent = true
    }
    console.log(this.socketObj.socket);
    if (!this.socketObj.socket) {
      //  判断连接
      this.timer = setInterval(() => {
        if (this.socketObj.socket?.readyState) {
          this.init()
        }
      }, 1500);
      return;
    }
    this.timer && clearInterval(this.timer);
    if (!this.socketObj.socket?.readyState) {
      //  判断连接
      this.timer = setInterval(() => {
        if (this.socketObj.socket?.readyState) {
          this.init()
          clearInterval(this.timer);
        }
      }, 1500);
      return;
    }
    this.init()
  },
  beforeDestroy() {
    clearInterval(this.timer);
  },
  computed: {
    ...mapState(['msgNum', 'isLoading']),
  },
  filters: {
    formatTime: function (timestamp) {
      // 将时间戳转换成毫秒
      const date = new Date(timestamp * 1000);

      // 获取小时和分钟
      const hours = date.getHours();
      const minutes = date.getMinutes();

      // 格式化小时和分钟
      const formattedTime = `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;

      return formattedTime;
    },
  },
  methods: {
    handleClickOpenChats() {
      this.$emit('open')
    },
    goback() {
      this.isShowChatcontent = false
      this.init()
    },
    // 关闭弹窗
    handleClose() {
      this.$emit('close')
    },
    handleClosechatcontent() {
      this.isShowChatcontent = false
    },
    getImageUrl(str) {
      const regex = /\[([^)]+)\]/;
      const match = str.match(regex);

      // 如果有匹配的结果，返回提取到的链接，否则返回空字符串
      return match && match.length > 1 ? match[1] : str;
    },
    parseGoodsStr(str) {
      const goodsStr = str.replace(/(^goods\[)|(\]$)/g, "");
      const a = goodsStr && JSON.parse(goodsStr)
      return a.goodsThumb;
    },
    init() {
      this.socketObj.socket.send(JSON.stringify({
        type: 'chatlist',
        data: {
          fromid: this.token
        }
      }))
      this.socketObj.socket.onmessage = res => {
        const data = JSON.parse(res.data)
        if (data.type === 'chatlist') {
          this.chatList = data.data.map(item => {
            if (item.fromid === this.token) {
              item.userid = item.toid
              item.username = item.to_username
              item.userAvatar = item.to_headimgurl
            } else {
              item.userid = item.fromid
              item.username = item.from_username
              item.userAvatar = item.from_headimgurl
            }
            return item
          })
        }

        if (data.type === 'say') {
          this.socketObj.socket.send(JSON.stringify({
            type: 'chatlist',
            data: {
              fromid: this.token
            }
          }))
        }
        if (data.type === 'imcountnum') {
          Store.commit('setMsgNum', data.data.imcountnum)
        }
      }
    },
    handleClickChatDetails(id, index, name) {
      this.id = id
      this.to_username = name
      this.isShowChatcontent = true
    },
  },
}
</script>
<style lang="less" scoped>
.chat {
  position: fixed;
  right: 20px;
  bottom: 300px;

  .bell {
    font-size: 22px;
    height: 48px;
    width: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #fff;
    border-radius: 50%;
    position: relative;

    &:hover {
      background-color: #222;
      color: #fff;
    }

    .num {
      background-color: red;
      padding: 0 2px;
      font-size: 12px;
      border-radius: 10px;
      position: absolute;
      top: 0;
      right: 0;
      min-width: 10px;
      min-height: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #fff;
      font-weight: bold;
    }
  }

  .chat_item {
    position: absolute;
    left: -400px;
    z-index: 2;
    top: -163px;
    width: 375px;
    height: 400px;
    background-color: #d9d9d9;
    overflow: hidden;
    box-shadow: 0 0 10px rgba(0, 0, 0, .25);
    background: #f6f6f6;
    border-radius: 2px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .title {
      display: flex;
      align-items: center;
      padding: 15px 0px;
      font-weight: 700;
      font-size: 16px;
      background-color: #222;
      color: #fff;
      justify-content: space-between;

      .icon {
        &>.el-icon-close {
          margin-right: 10px;
        }

        &>.el-icon-arrow-left {
          margin-left: 10px;
        }
      }

      .all {
        transform: rotate(45deg);
      }
    }

    .content {
      width: 100%;
      flex: 1;
      height: 100%;
      display: flex;

      flex-direction: column;
      position: relative;
      overflow: scroll;

      .item-wrap {
        flex: 1;
        display: flex;
        overflow-y: auto;
        flex-direction: column-reverse;
        align-items: inherit;


        .list-item {
          padding: 0 12px;
          display: flex;
          margin: 10px 0;

          &:first-child {
            margin-bottom: auto;
            margin-bottom: 20px;
          }

          &.my-chat {
            flex-direction: row-reverse;

            .chat-text {
              align-items: flex-end;

              .chat-con {
                background: #222;

                border-radius: 8px 8px 8px 8px;

                >p {
                  color: #fff;
                }

                .infos {
                  display: flex;

                  img {
                    width: 56px;
                    height: 56px;
                    border: 1px solid #000;
                    border-radius: 5px;
                  }

                  .txt {
                    flex: 1 1 0%;
                    width: 150px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    display: flex;
                    flex-direction: column;
                    margin-left: 10px;

                    .name {
                      height: 25px;
                      overflow: hidden;
                      text-overflow: ellipsis;
                      white-space: nowrap;
                    }

                    .price {
                      color: red;
                    }
                  }
                }
              }

              .time {
                text-align: right;
              }
            }
          }


          .chat-text {
            margin: 0 8px;
            display: flex;
            flex-direction: column;
            position: relative;

            .chat-con {
              background: #fff;
              border-radius: 0px 8px 8px 8px;
              padding: 10px 12px;
              width: fit-content;


              p {
                font-size: 14px;
                line-height: 18px;
                color: #222;
                margin: 0;
              }
            }

            .time {
              position: absolute;
              bottom: -12px;
              right: -1px;
              font-size: 12px;
              line-height: 12px;
              color: #757575;
              margin: 0;
              margin-top: 4px;
            }
          }
        }
      }
    }

    .nodata {
      align-items: center;
      justify-content: center;
    }

    .botton {
      background-color: #FCFCFC;

      &>.send {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 20px 10px;
      }

      .upload-demo {
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
    }

  }
}

::v-deep {
  .inputDeep {

    .el-textarea__inner {
      border: 0 !important;
      resize: none !important;
      background-color: #FCFCFC;
      height: 100%;
      box-shadow: none !important;

    }
  }

  .el-loading-spinner {
    margin-top: 0;
  }

  .el-button--primary {
    background-color: #222;
    border: none;
  }
}

// 折叠加隐藏过渡效果
.collapse-enter-active,
.collapse-leave-active {
  transition: all 0.5s;
  // 防止折叠时元素溢出
  overflow: hidden;
}

.collapse-enter-from,
.collapse-leave-to {
  height: 0px !important;
  opacity: 0 !important;
}

.chatList {
  flex: 1;
}

.chatList_item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #fff;
  margin-bottom: 2px;
  padding: 10px 20px;

  &>div:last-child {
    flex: 1;
    width: 100%;
    overflow: hidden;

    &>div {
      line-height: 18px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 10px;
    }

    .ccc {
      // width: 80%;
      color: #b4afaf;
      font-size: 12px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .name {
      color: #000;
      font-size: 14px;
      margin-right: 20px;
      flex: 1;
    }

    .read {
      background-color: #222;
      color: #fff;
      padding: 2px 10px;
      line-height: 12px;
      font-size: 12px;
      border-radius: 10px;
    }
  }

  .img {
    //background-color: red;
    width: 48px;
    height: 48px;
    margin-right: 8px;
  }
}
@media screen and (max-width: 576px){
  .chat{
    position: static;
  }
  .chat_item{
    left: 0 !important;
    top: 0 !important;
    width: 100vw !important;
    height: 100vh !important;
  }
}
</style>
