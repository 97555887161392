import {LIVESOCKET} from "@/config";
import Store from "@/store";

let timer = null;
let timerCount = null;
let token = localStorage.getItem('token')

console.log(Store.getters.getMsgNum)
export const Socket = function () {
    this.socket = null;
    this.instance = null;
}
Socket.prototype.init = function () {
    this.socket = new WebSocket(LIVESOCKET);
    this.socket.onopen = () => {
        console.info(`${LIVESOCKET} 连接打开 开启心跳机制💚...`);
        timer = setInterval(() => {
            this.socket?.send(JSON.stringify({
                type: "ping",
                id: token,
            }));
        }, 9000);


        timerCount = setInterval(() => {
            this.socket?.send(JSON.stringify({
                type: "imcountnum",
                id: token,
            }));
        }, 2000);
    };

    this.socket.onmessage = (e) => {
        const data = JSON.parse(e.data)
        if (data.type === 'init') {
            const client_id = data.data.client_id
            if (token) {
                this.socket?.send(JSON.stringify({
                    type: 'bind_id',
                    id: token,
                    client_id
                }))
            }
            return false
        }
        if (data.type === 'imcountnum') {
            Store.commit('setMsgNum', data.data.imcountnum)
        }
    };

    // 监听socket错误信息
    this.socket.onerror = (err) => { //監聽錯誤(onerror)
        console.error(`📵${LIVESOCKET} WebSocket连接打开失败，请检查！异常：${err}`);
        this.init();
    };

    // 监听socket关闭
    this.socket.onclose = () => { //監聽關閉(onclose)
        timer && clearInterval(timer);
        timerCount && clearInterval(timerCount);
        console.log('WebSocket 已关闭！');
        this.init();
    };
}

Socket.getInstance = function () {
    if (this.instance) {
        return this.instance;
    }
    this.instance = new Socket()
    return this.instance;
}

