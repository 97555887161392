<template>
  <div class="footer footer_footer__1kDaT iskr">
    <div class="footer_footer__backgroundColor__SfWqi">
      <div class="footer_footer__container--upper__9JJhr text--kr">
        <div class="footer_footer__upperContainer--navigation__k_XPz">
          <div class="footer-nav-section_footerNav__OsikF" data-cy="footer-links-list">
            <details class="footer-nav-section_footerNav__block__xHogR" :open="is_open">
              <div>
                <label
                    class="footer-nav-section_footerNav__title__mobile_hide__pCPr9 footer-nav-section_footerNav__title__2z3ET">
                  <font style="vertical-align: inherit;">
                    <font style="vertical-align: inherit;">{{ $t('footer.t1') }}</font>
                  </font>
                </label>
                <div class="footer-nav-section_footerNav__list__VqII7 footer-nav-section_footerNav__subMenu__US2a_"
                     data-cy="footer-block-links-list">
                  <div class="footer-nav-section_footerNav__listItem__VIY0y">
                    <a href="#/our-concept-page" tabindex="0" rel="noopener">
                      <font style="vertical-align: inherit;">
                        <font style="vertical-align: inherit;">{{ $t('footer.t2') }}</font>
                      </font>
                    </a></div>
                  <div class="footer-nav-section_footerNav__listItem__VIY0y"><a
                      href="#/kr-why-sell-with-consignment" tabindex="0"
                      rel="noopener"><font style="vertical-align: inherit;"><font
                      style="vertical-align: inherit;">{{ $t('footer.t3') }}</font></font></a></div>
                </div>
              </div>
              <summary
                  class="footer-nav-section_accordionSummaryMenu__iyLO_ footer-nav-section_footerNav__title__2z3ET">
                <font style="vertical-align: inherit;"><font style="vertical-align: inherit;">{{
                    $t('footer.t1')
                  }}</font></font>
              </summary>
            </details>
            <details class="footer-nav-section_footerNav__block__xHogR" :open="is_open">
              <div><label
                  class="footer-nav-section_footerNav__title__mobile_hide__pCPr9 footer-nav-section_footerNav__title__2z3ET"><font
                  style="vertical-align: inherit;"><font style="vertical-align: inherit;">{{
                  $t('footer.t4')
                }}</font></font></label>
                <div class="footer-nav-section_footerNav__list__VqII7 footer-nav-section_footerNav__subMenu__US2a_"
                     data-cy="footer-block-links-list">
                  <div class="footer-nav-section_footerNav__listItem__VIY0y"><a
                      href="#/articles/4432099937425"
                      tabindex="0" rel="noopener"><font style="vertical-align: inherit;"><font
                      style="vertical-align: inherit;">{{ $t('footer.t5') }}</font></font></a></div>
                  <div class="footer-nav-section_footerNav__listItem__VIY0y"><a
                      href="#/trust-expert-authentication" tabindex="0"
                      rel="noopener"><font style="vertical-align: inherit;"><font
                      style="vertical-align: inherit;">{{ $t('footer.t6') }}</font></font></a>
                  </div>
                  <div class="footer-nav-section_footerNav__listItem__VIY0y"><a
                      href="#/articles/360004969178" tabindex="0"
                      rel="noopener"><font style="vertical-align: inherit;"><font
                      style="vertical-align: inherit;">{{ $t('footer.t7') }}</font></font></a></div>
                  <div class="footer-nav-section_footerNav__listItem__VIY0y"><a
                      href="#/user-notice" tabindex="0"
                      rel="noopener"><font style="vertical-align: inherit;"><font
                      style="vertical-align: inherit;">{{ $t('footer.t16') }}</font></font></a></div>
                </div>
              </div>
              <summary
                  class="footer-nav-section_accordionSummaryMenu__iyLO_ footer-nav-section_footerNav__title__2z3ET">
                <font style="vertical-align: inherit;"><font style="vertical-align: inherit;">{{
                    $t('footer.t4')
                  }}</font></font></summary>
            </details>
            <details class="footer-nav-section_footerNav__block__xHogR" :open="is_open">
              <div><label
                  class="footer-nav-section_footerNav__title__mobile_hide__pCPr9 footer-nav-section_footerNav__title__2z3ET"><font
                  style="vertical-align: inherit;"><font style="vertical-align: inherit;">{{
                  $t('footer.t8')
                }}</font></font></label>
                <div class="footer-nav-section_footerNav__list__VqII7 footer-nav-section_footerNav__subMenu__US2a_"
                     data-cy="footer-block-links-list">
                  <div class="footer-nav-section_footerNav__listItem__VIY0y"><a
                      href="#/start-selling" tabindex="0"
                      rel="noopener"><font style="vertical-align: inherit;"><font
                      style="vertical-align: inherit;">{{ $t('footer.t9') }}</font></font></a></div>
                  <div class="footer-nav-section_footerNav__listItem__VIY0y"><a href="#/tips-for-sellers"
                                                                                tabindex="0" rel="noopener"><font
                      style="vertical-align: inherit;"><font
                      style="vertical-align: inherit;">{{ $t('footer.t10') }}</font></font></a></div>
                </div>
              </div>
              <summary
                  class="footer-nav-section_accordionSummaryMenu__iyLO_ footer-nav-section_footerNav__title__2z3ET">
                <font style="vertical-align: inherit;"><font style="vertical-align: inherit;">{{
                    $t('footer.t8')
                  }}</font></font></summary>
            </details>
            <details class="footer-nav-section_footerNav__block__xHogR" :open="is_open">
              <div><label
                  class="footer-nav-section_footerNav__title__mobile_hide__pCPr9 footer-nav-section_footerNav__title__2z3ET"><font
                  style="vertical-align: inherit;"><font
                  style="vertical-align: inherit;">{{ $t('footer.t11') }}</font></font></label>
                <div class="footer-nav-section_footerNav__list__VqII7 footer-nav-section_footerNav__subMenu__US2a_"
                     data-cy="footer-block-links-list">
                  <div class="footer-nav-section_footerNav__listItem__VIY0y"><a
                      href="#/welcome-to-vestiaire-collective" tabindex="0"
                      rel="noopener"><font style="vertical-align: inherit;"><font
                      style="vertical-align: inherit;">{{ $t('footer.t12') }}</font></font></a></div>
                  <div class="footer-nav-section_footerNav__listItem__VIY0y"><a
                      href="#/our-sustainability-manifesto" tabindex="0"
                      rel="noopener"><font style="vertical-align: inherit;"><font
                      style="vertical-align: inherit;">{{ $t('footer.t13') }}</font></font></a></div>
                </div>
              </div>
              <summary
                  class="footer-nav-section_accordionSummaryMenu__iyLO_ footer-nav-section_footerNav__title__2z3ET">
                <font style="vertical-align: inherit;"><font
                    style="vertical-align: inherit;">{{ $t('footer.t11') }}</font></font></summary>
            </details>
          </div>
        </div>
        <div class="footer_footer__upperContainer--lower__LtNPk">
          <div class="footer_footer__upperContainer--legals__cbCfP d-md-flex">
            <div class="footer-legals_legals__widescreen___UHK0">
              <div class="footer-legals_legals__link__dIF8I"><a
                  href="#/terms-and-conditions" target="_blank"
                  rel="noreferrer"><font style="vertical-align: inherit;"><font
                  style="vertical-align: inherit;">{{ $t('footer.t14') }}</font></font></a></div>
              <div class="footer-legals_legals__link__dIF8I"><a
                  href="https://paimaih5.kogans.cyou/cgu-privacy-policy-kr.pdf"
                  target="_blank" rel="noreferrer"><font style="vertical-align: inherit;"><font
                  style="vertical-align: inherit;">{{ $t('footer.t15') }}</font></font></a></div>
            </div>
            <details class="footer-legals_legals__details__9IpJr" :open="is_open">
              <summary class="footer-legals_legals__summary__SEKib">{{ $t('footer.t15') }}</summary>
              <div class="footer-legals_legals__link__dIF8I">
                <a href="#/terms-and-conditions" target="_blank" rel="noreferrer">{{ $t('footer.t14') }}</a>
              </div>
              <div class="footer-legals_legals__link__dIF8I">
                <a href="https://paimaih5.kogans.cyou/cgu-privacy-policy-kr.pdf" target="_blank"
                   rel="noreferrer">{{ $t('footer.t15') }}</a>
              </div>
            </details>
          </div>
        </div>
      </div>
    </div>
    <div class="footer_footer__container--lower__zLE1P">
      <div class="footer_footer__lowerContainer--disclaimer__L3ndw">
        <div class="footer_footer__disclaimerText__11G_w">
<!--          <div><p><span>{{ $t('bottoms.t01') }}</span><span>{{ $t('bottoms.t02') }}: 190-86-02606</span><span><a-->
<!--              href="https://www.ftc.go.kr/bizCommPop.do?wrkr_no=1908602606" target="_blank"-->
<!--              rel="noreferrer">{{ $t('bottoms.t03') }}</a></span></p>-->
<!--            <p><span>{{ $t('bottoms.t04') }}</span></p>-->
<!--            <p><span>{{ $t('bottoms.t05') }}</span></p>-->
<!--            <p><span>{{ $t('bottoms.t06') }}: AWS</span></p>-->
<!--            <p><span>{{ $t('bottoms.t07') }}: 080-580-8774</span><span>Email: contact-kr@vestiairecollective.com</span>-->
<!--            </p></div>-->
        </div>
      </div>
      <div class="footer_footer__lowerContainer--paymentMode__k28pj">
        <div class="payment-methods_paymentMethods__Fh85h"><img width="36" height="24"
                                                                class="payment-card_paymentCard__05VEO payment-card_paymentCard--amex__CMWE4"
                                                                alt="amex" loading="lazy"
                                                                src="https://images.vestiairecollective.com/payment-method/logo/amex.svg?v=6349735521013"><img
            width="36" height="24" class="payment-card_paymentCard__05VEO payment-card_paymentCard--jcb__pi0Lo"
            alt="jcb" loading="lazy"
            src="https://images.vestiairecollective.com/payment-method/logo/jcb.svg?v=6349740fcef82"><img width="36"
                                                                                                          height="24"
                                                                                                          class="payment-card_paymentCard__05VEO"
                                                                                                          alt="mc"
                                                                                                          loading="lazy"
                                                                                                          src="https://images.vestiairecollective.com/payment-method/logo/mc.svg?v=6349736976a62"><img
            width="36" height="24" class="payment-card_paymentCard__05VEO payment-card_paymentCard--paypal__aLlnz"
            alt="paypal" loading="lazy"
            src="https://images.vestiairecollective.com/payment-method/logo/paypal.svg?v=6349708387d8f"><img width="36"
                                                                                                             height="24"
                                                                                                             class="payment-card_paymentCard__05VEO"
                                                                                                             alt="cup"
                                                                                                             loading="lazy"
                                                                                                             src="https://images.vestiairecollective.com/payment-method/logo/cup.svg?v=6349737649065"><img
            width="36" height="24" class="payment-card_paymentCard__05VEO payment-card_paymentCard--visa__A17mE"
            alt="visa" loading="lazy"
            src="https://images.vestiairecollective.com/payment-method/logo/visa.svg?v=6349718fa4756"></div>
        <div class="footer_paymentTips__tWEFW">
          <div>
            <div><p><span>{{ $t('bottoms.t08') }}</span>
              <a rel="noreferrer" target="_blank"
                 href="/documents/SGI_Certificate.pdf">({{ $t('bottoms.t09') }})</a><span>{{
                  $t('bottoms.t10')
                }}。</span>
            </p></div>
            <div><p>{{ $t('bottoms.t11') }}</p>
              <p>{{ $t('bottoms.t12') }}</p></div>
            <div><p>{{ $t('bottoms.t13') }}</p>
              <p>{{ $t('bottoms.t14') }}</p></div>
          </div>
        </div>
      </div>
      <div class="footer_footer__lowerContainer--BCorporation__zyCUy">
        <p class="footer_footer__BCorporationText__1EZIG footer_footer__BCorporationText--title__qAqDb">
          {{ $t('bottoms.t15') }}</p>
        <p class="footer_footer__BCorporationText__1EZIG">{{ $t('bottoms.t16') }}</p>
        <div class="footer_footer__lowerContainer--signature__YJle_ footer_right_copyright__OvrQf">
          <span class="footer_footer__signatureText__GZIA2">
            <span class="footer_footer__signatureText--year__5zx9z">©2024</span>&nbsp;
            <span itemprop="copyrightHolder" itemscope="" itemtype="https://schema.org/Organization">
              <span itemprop="name">{{ $t('bottoms.t17') }}</span></span></span></div>
      </div>
      <div class="footer_footer__lowerContainer--domains__1natV">

      </div>
      <div class="footer_footer__lowerContainer--signature__YJle_"><span
          class="footer_footer__signatureText__GZIA2"><span
          class="footer_footer__signatureText--year__5zx9z">©2024</span>
        <span itemprop="copyrightHolder" itemscope="" itemtype="https://schema.org/Organization"><span
          itemprop="name">{{ $t('bottoms.t17') }}</span></span></span></div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      is_open: true
    }
  },
  created() {
    this.is_open = window.innerWidth >= 768;
    window.onresize = () => {
      this.is_open = window.innerWidth >= 768;
    }
  },
  deactivated() {
    window.onresize = null;
  }
}
</script>
<style lang='less' scoped>
//.footer {
//  margin-top: 50px;
//  width: 100%;
//  display: flex;
//  flex-direction: column;
//  justify-content: center;
//}
p {
  margin: 0;
  padding: 0;
}

a {
  cursor: pointer;
  color: currentColor;
  text-decoration: none;
}

.d-none {
  display: none !important;
}

@media (min-width: 768px) {
  .d-md-flex {
    display: flex !important;
  }
}

.payment-card_ko-KR__CrRvg {
  font-family: -apple-system, Malgun Gothic, Apple SD Gothic Neo, sans-serif
}

.payment-card_impressionTrackingContainer__OAbj_ {
  height: 100%
}

.payment-card_paymentCard__05VEO {
  height: 1.5rem;
  -o-object-fit: contain;
  object-fit: contain
}

.payment-card_paymentCard--wallet__npt70 {
  width: 19px;
  fill: #ababab
}

.payment-card_paymentCard--visa__A17mE {
  width: 34px
}

.payment-card_paymentCard--mastercard__ZjAR4 {
  width: 28px
}

.payment-card_paymentCard--maestro__Lwwtf {
  width: 30px
}

.payment-card_paymentCard--cb__XLp1e {
  width: 26px
}

.payment-card_paymentCard--american_express__EH3TE {
  width: 21px
}

.payment-card_paymentCard--amex__CMWE4 {
  width: 1.5rem
}

.payment-card_paymentCard--discover__KLcUA {
  width: 34px
}

.payment-card_paymentCard--cofinoga__rdkT_ {
  width: 40px
}

.payment-card_paymentCard--paypal__aLlnz {
  width: 47px
}

.payment-card_paymentCard--payoneer__T1OOK {
  width: auto
}

.payment-card_paymentCard--3xcb__4_cWH {
  width: 32px
}

.payment-card_paymentCard--facilypay_oney__3Ql24 {
  width: 34px
}

.payment-card_paymentCard--afforditnow__Imo6s {
  width: 82px
}

.payment-card_paymentCard--cartasi__Lv6PN {
  width: 30px
}

.payment-card_paymentCard--affirm__ZD4xh {
  width: 38px
}

.payment-card_paymentCard--giropay__EeFFh {
  width: 37px
}

.payment-card_paymentCard--ideal__SlGDK {
  width: 22px
}

.payment-card_paymentCard--jcb__pi0Lo {
  width: 30px
}

.payment-card_paymentCard--klarna__SABK_ {
  width: 40px
}

.payment-card_paymentCard--pagantis__0jSPE {
  width: 80px
}

.payment-card_paymentCard--postpay__kWrF2 {
  width: 58px
}

.payment-card_paymentCard--sofort__GmlRR {
  width: 50px
}

.payment-card_paymentCard--splitit__po6rK {
  width: 42px
}

.payment-card_paymentCard--unionpay__7XkEf {
  width: 34px
}

.payment-card_paymentCard--bancontact__ArBuD {
  width: 42px
}

.payment-card_paymentCard--dinersclub__oTH1B {
  width: 30px
}

.payment-card_paymentCard--dhl_express__p3WE7 {
  width: 50px
}

.payment-methods_ko-KR__sMvZA {
  font-family: -apple-system, Malgun Gothic, Apple SD Gothic Neo, sans-serif
}

.payment-methods_impressionTrackingContainer__LGTqs {
  height: 100%
}

.payment-methods_paymentMethods__Fh85h {
  display: flex;
  flex-flow: row wrap;
  gap: .25rem .75rem
}

.footer-domains_ko-KR__Tti42 {
  font-family: -apple-system, Malgun Gothic, Apple SD Gothic Neo, sans-serif
}

.footer-domains_impressionTrackingContainer__DWI3o {
  height: 100%
}

.footer-domains_domains__ICiEG {
  display: flex;
  justify-content: center;
  flex-wrap: wrap
}

@media (min-width: 768px) {
  .footer-domains_domains__ICiEG {
    flex-wrap: nowrap;
    justify-content: flex-start
  }
}

.footer-domains_domains__link__dXy80 {
  display: inline-flex;
  color: #656565;
  font-size: .6875rem;
  line-height: .9375rem;
  white-space: nowrap;
  text-decoration: none !important
}

.footer-domains_domains__link__dXy80:not(:last-child):after {
  content: "-";
  display: inline-flex;
  margin: 0 .3125rem
}

.footer-legals_ko-KR__hmhPn {
  font-family: -apple-system, Malgun Gothic, Apple SD Gothic Neo, sans-serif
}

.footer-legals_impressionTrackingContainer__XlJcK {
  height: 100%
}

.footer-legals_legals__widescreen___UHK0 {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  padding: .75rem 0
}

@media (max-width: 767px) {
  .footer-legals_legals__widescreen___UHK0 {
    display: none
  }
}

@media (min-width: 1024px) {
  .footer-legals_legals__widescreen___UHK0 {
    padding-bottom: 0
  }
}

.footer-legals_legals__details__9IpJr {
  display: flex;
  margin-top: .5rem;
  height: 2.4rem;
  transition: height .3s
}

@media (min-width: 768px) {
  .footer-legals_legals__details__9IpJr {
    margin-top: unset;
    display: none
  }
}

.footer-legals_legals__details__9IpJr[open] {
  height: 15rem
}

.footer-legals_legals__details__9IpJr[open] .footer-legals_legals__summary__SEKib:after {
  transform-origin: center center;
  transform: rotate(-225deg);
  margin-bottom: -2px
}

.footer-legals_legals__details__9IpJr[open] .footer-legals_legals__menu__jRwNo {
  max-height: 300px
}

.footer-legals_legals__summary__SEKib {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: .8125rem;
  line-height: 1rem;
  color: #ababab;
  padding: .75rem;
  position: relative
}

.footer-legals_legals__summary__SEKib::marker {
  content: ""
}

.footer-legals_legals__summary__SEKib::-webkit-details-marker {
  display: none
}

.footer-legals_legals__summary__SEKib:after {
  content: "";
  display: inline-block;
  width: .4375rem;
  height: .4375rem;
  margin-left: .625rem;
  margin-bottom: 2px;
  border-left: 1px solid #fff;
  border-bottom: 1px solid #fff;
  transform-origin: center center;
  transform: rotate(-45deg);
  transition: all .3s ease
}

.footer-legals_legals__link__dIF8I {
  display: flex;
  text-align: center;
  color: #ababab;
  font-size: .8125rem;
  line-height: 2.125rem;
  flex-wrap: nowrap;
  white-space: nowrap;
  justify-content: space-around
}

.footer-legals_legals__link__dIF8I a {
  text-transform: capitalize
}

@media (min-width: 768px) {
  .footer-legals_legals__link__dIF8I {
    text-align: left;
    line-height: normal;
    display: flex
  }

  .footer-legals_legals__link__dIF8I:not(:last-of-type) {
    margin-right: 1rem
  }
}

@media (min-width: 1024px) {
  .footer-legals_legals__link__dIF8I {
    padding-bottom: .75rem
  }
}

.footer-legals_legals__link--accessibility__b45i3 {
  border-bottom: 1px solid hsla(0, 0%, 100%, .1)
}

@media (min-width: 768px) {
  .footer-legals_legals__link--accessibility__b45i3 {
    border-bottom: 0 transparent
  }
}

.iskr .footer-legals_legals__link__dIF8I {
  justify-content: center
}

.footer-nav-section_ko-KR__KLVKH {
  font-family: -apple-system, Malgun Gothic, Apple SD Gothic Neo, sans-serif
}

.footer-nav-section_impressionTrackingContainer__P2d8a {
  height: 100%
}

.footer-nav-section_footerNav__OsikF {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: stretch
}

.footer-nav-section_footerNav__block__xHogR {
  width: 100%;
  border-bottom: 1px solid hsla(0, 0%, 100%, .06)
}

.footer-nav-section_footerNav__block__xHogR:first-child {
  border-top: 1px solid hsla(0, 0%, 100%, .06)
}

.footer-nav-section_footerNav__title__2z3ET {
  display: block;
  position: relative;
  margin: .9375rem 0;
  text-transform: uppercase;
  font-weight: 500;
  font-size: .75rem;
  line-height: 1.125rem;
  letter-spacing: .15em;
  cursor: pointer
}

.footer-nav-section_footerNav__title__mobile_hide__pCPr9 {
  display: none
}

.footer-nav-section_footerNav__listItem__VIY0y {
  display: block;
  color: #ababab;
  font-weight: 400;
  font-size: 1rem;
  line-height: 2.875rem;
  text-transform: none
}

.footer-nav-section_footerNav__subMenu__US2a_ {
  opacity: 0;
  overflow: hidden;
  max-height: 0;
  transition-property: max-height, opacity;
  transition-timing-function: cubic-bezier(.645, .045, .355, 1);
  transition-duration: .16s
}

details[open] .footer-nav-section_accordionSummaryMenu__iyLO_ {
  display: block
}

.footer-nav-section_accordionSummaryMenu__iyLO_ {
  list-style: none;
  position: relative
}

.footer-nav-section_accordionSummaryMenu__iyLO_::-webkit-details-marker {
  display: none
}

.footer-nav-section_accordionSummaryMenu__iyLO_:after {
  transform-origin: center center;
  transform: rotate(-45deg);
  transition: all .3s ease;
  content: "";
  display: inline-block;
  position: absolute;
  top: 3px;
  right: 0;
  width: .4375rem;
  height: .4375rem;
  border-left: 1px solid #fff;
  border-bottom: 1px solid #fff
}

details[open] .footer-nav-section_accordionSummaryMenu__iyLO_:after {
  transform-origin: center center;
  transform: rotate(-225deg);
  top: 6px;
  right: 0
}

details[open] .footer-nav-section_footerNav__subMenu__US2a_ {
  opacity: 1;
  max-height: 625rem;
  transition-property: max-height, opacity;
  transition-timing-function: cubic-bezier(.645, .045, .355, 1);
  transition-duration: .3s;
  margin-bottom: 24px
}

@media (min-width: 768px) {
  details[open] .footer-nav-section_accordionSummaryMenu__iyLO_ {
    display: none
  }

  .footer-nav-section_footerNav__OsikF {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap
  }

  .footer-nav-section_footerNav__block__xHogR {
    width: 25%;
    flex-shrink: 0;
    padding-right: 1.875rem;
    box-sizing: border-box;
  }

  .footer-nav-section_footerNav__block__xHogR:first-child {
    border-top: none
  }

  .footer-nav-section_footerNav__block__xHogR:last-child {
    padding-right: 0
  }

  .footer-nav-section_footerNav__block__xHogR + .footer-nav-section_footerNav__block__xHogR {
    padding-left: 1.875rem
  }

  .footer-nav-section_footerNav__title__2z3ET {
    cursor: default;
    line-height: .9375rem;
    margin: 0 0 .9375rem
  }

  .footer-nav-section_footerNav__title__2z3ET:after {
    display: none
  }

  .footer-nav-section_footerNav__title__mobile_hide__pCPr9 {
    display: block
  }

  .footer-nav-section_footerNav__list__VqII7 {
    display: block;
    margin-bottom: 2.875rem
  }

  .footer-nav-section_footerNav__listItem__VIY0y {
    font-size: .875rem;
    line-height: 1.625rem
  }

  .footer-nav-section_footerNav__subMenu__US2a_ {
    opacity: 1;
    overflow: hidden;
    max-height: 100%;
    margin-bottom: 46px !important
  }
}

.footer-social-section_ko-KR__rGAMX {
  font-family: -apple-system, Malgun Gothic, Apple SD Gothic Neo, sans-serif
}

.footer-social-section_impressionTrackingContainer__1hf0J {
  height: 100%
}

.footer-social-section_socialIcon__SonsE {
  display: flex;
  opacity: .7;
  box-sizing: border-box
}

.footer-social-section_socialIcon__img__HovR_ {
  width: auto;
  height: 22px
}

@media (min-width: 768px) {
  .footer-social-section_socialIcon__img__HovR_ {
    height: 17px;
    margin: 0 1rem 0 0
  }

  .footer-social-section_socialIcon__SonsE:last-child {
    margin: 0
  }
}

.app-store-button_ko-KR__K8rd2 {
  font-family: -apple-system, Malgun Gothic, Apple SD Gothic Neo, sans-serif
}

.app-store-button_impressionTrackingContainer__fYzpi {
  height: 100%
}

.app-store-button_appStoreBtn__xlZBT {
  display: inline-block
}

.app-store-button_appStoreBtn__svg__UU1P7 {
  display: block;
  width: 100%;
  height: 100%
}

.i18n_ko-KR__su_zQ {
  font-family: -apple-system, Malgun Gothic, Apple SD Gothic Neo, sans-serif
}

.i18n_impressionTrackingContainer__6Ca5P {
  height: 100%
}

.i18n_i18nChoices__modalTitle__ZfTpT {
  margin-bottom: 30px;
  font-weight: 400;
  font-size: 38px;
  font-family: Albra-Regular;
  line-height: 45px;
  letter-spacing: -.05em;
  display: block
}

.i18n_i18nChoices__modalAction__4wTdQ {
  margin-top: 20px;
  text-align: right
}

.i18n_i18nChoices__select__v_75Z:focus {
  outline: 1px auto #5e9ed6 !important
}

.i18n_i18nChoices__gridContainer__KM8EO {
  display: grid;
  grid-template-columns: minmax(0, 1fr)
}

.i18n_i18n__modalCol__G1Ul8 {
  margin-bottom: 20px
}

.i18n_i18n__modalCol__G1Ul8 label {
  color: #717070
}

@media (min-width: 768px) {
  .i18n_i18nChoices__modalAction__4wTdQ {
    margin-top: 40px
  }

  .i18n_i18nChoices__gridContainer__KM8EO {
    grid-template-columns: repeat(auto-fit, minmax(9.5rem, 1fr));
    gap: 0 1.25rem
  }

  .i18n_i18nChoices__modalCol__GywnL {
    width: 100%;
    margin-bottom: 0
  }
}

.footer_ko-KR__f1BBE {
  font-family: -apple-system, Malgun Gothic, Apple SD Gothic Neo, sans-serif
}

.footer_impressionTrackingContainer__039qO {
  height: 100%
}

.footer_footer__1kDaT {
  padding-bottom: 4.875rem
}

@media (min-width: 1024px) {
  .footer_footer__1kDaT {
    padding-bottom: 0
  }
}

.footer_footer__backgroundColor__SfWqi {
  background-color: #1a1a1a;
  color: #fff
}

.footer_footer__container--upper__9JJhr {
  padding: 2.4375rem calc(.75rem + 20px) .75rem;
  margin: 0 auto;
  max-width: 91.25rem;
  display: grid;
  grid-template-columns: minmax(0, 1fr);
  grid-column-gap: 1.5rem;
  grid-template-areas: "navigation" "social" "lower" "i18n" "legals"
}

@media (min-width: 768px) {
  .footer_footer__container--upper__9JJhr {
    grid-row-gap: .5rem;
    padding: 2.4375rem 2rem 1.563rem;
    grid-template-columns: repeat(4, minmax(0, 1fr));
    grid-template-areas: "navigation navigation navigation navigation navigation" "i18n i18n . . ." "legals legals legals legals legals" "lower lower lower lower lower"
  }
}

@media (min-width: 1024px) {
  .footer_footer__container--upper__9JJhr {
    grid-template-areas:"navigation navigation navigation navigation navigation navigation" "i18n i18n . . . ." "lower lower lower lower lower lower"
  }
}

.footer_footer__container--lower__zLE1P {
  margin: 0 auto;
  max-width: 91.25rem;
  display: grid;
  grid-template-columns: minmax(0, 1fr);
  grid-gap: .5rem;
  grid-template-areas: "disclaimer" "paymentMode" "BCorporation" "signature" "domains";
  padding: 1.5rem
}

@media (min-width: 768px) {
  .footer_footer__container--lower__zLE1P {
    grid-gap: .5rem;
    grid-template-columns: repeat(5, minmax(0, 1fr));
    grid-template-areas: "disclaimer disclaimer disclaimer . BCorporation BCorporation" "paymentMode paymentMode paymentMode paymentMode paymentMode paymentMode" "domains domains  domains domains . signature"
  }
}

@media (min-width: 1024px) {
  .footer_footer__container--lower__zLE1P {
    padding: 1.5rem 1.25rem 1.25rem
  }
}

.footer_footer__upperContainer__RoJKv {
  display: flex
}

.footer_footer__upperContainer--navigation__k_XPz {
  grid-area: navigation
}

.footer_footer__upperContainer--lower__LtNPk {
  grid-area: lower;
  display: flex;
  flex-direction: column
}

@media (min-width: 768px) {
  .footer_footer__upperContainer--lower__LtNPk {
    flex-direction: row
  }
}

.footer_footer__upperContainer--legals__cbCfP {
  grid-area: legals;
  display: flex;
  flex-direction: row;
  justify-content: center
}

@media (min-width: 768px) {
  .footer_footer__upperContainer--legals__cbCfP {
    justify-content: flex-start
  }
}

.footer_footer__upperContainer--social__VUsdu {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  padding: 1.75rem 0;
  border-bottom: 1px solid hsla(0, 0%, 100%, .06)
}

@media (min-width: 768px) {
  .footer_footer__upperContainer--social__VUsdu {
    padding: 0;
    justify-content: unset;
    border-bottom: unset
  }
}

@media (min-width: 1024px) {
  .footer_footer__upperContainer--social__VUsdu {
    margin: 0 1rem
  }
}

.footer_footer__upperContainer--apps__L6cQ2 {
  padding: 2.25rem 0;
  display: flex;
  justify-content: center;
  border-bottom: 1px solid hsla(0, 0%, 100%, .06);
  align-items: center
}

@media (min-width: 768px) {
  .footer_footer__upperContainer--apps__L6cQ2 {
    padding: 0;
    flex-grow: 1;
    border-bottom: unset;
    justify-content: flex-end
  }
}

.footer_footer__upperContainer--i18n__ud4Vu {
  grid-area: i18n;
  display: flex;
  justify-content: center;
  padding: 1.25rem 0;
  border-bottom: 1px solid hsla(0, 0%, 100%, .06)
}

@media (min-width: 768px) {
  .footer_footer__upperContainer--i18n__ud4Vu {
    flex: 1 1 100%;
    justify-content: flex-start;
    order: 1;
    padding: .75rem 0 .5rem;
    border-bottom: unset
  }
}

@media (min-width: 1024px) {
  .footer_footer__upperContainer--i18n__ud4Vu {
    padding: 1.25rem 0 .5rem
  }
}

.footer_footer__upperContainer--i18n__ud4Vu .footer_i18n__choices__BxN_T {
  font-size: .8125rem;
  line-height: 1rem;
  cursor: pointer
}

.footer_footer__upperContainer--i18n__ud4Vu .footer_i18n__choices__BxN_T:after {
  content: "";
  display: inline-block;
  width: .4375rem;
  height: .4375rem;
  margin-bottom: .125rem;
  margin-left: .625rem;
  border-bottom: 1px solid #fff;
  border-left: 1px solid #fff;
  transform: rotate(-45deg);
  transform-origin: center center
}

.footer_footer__lowerContainer--disclaimer__L3ndw {
  display: flex;
  grid-area: disclaimer;
  flex-direction: column;
  margin-bottom: 1.5rem
}

.footer_footer__lowerContainer--paymentMode__k28pj {
  display: flex;
  grid-area: paymentMode;
  order: 2
}

.footer_footer__lowerContainer--BCorporation__zyCUy {
  display: flex;
  grid-area: BCorporation;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 1rem;
  font-size: .75rem
}

@media (min-width: 768px) {
  .footer_footer__lowerContainer--BCorporation__zyCUy {
    align-items: flex-end;
    margin-top: 0
  }
}

.footer_footer__lowerContainer--domains__1natV {
  display: flex;
  grid-area: domains;
  justify-content: center
}

@media (min-width: 768px) {
  .footer_footer__lowerContainer--domains__1natV {
    justify-content: flex-start
  }
}

.footer_footer__lowerContainer--signature__YJle_ {
  grid-area: signature;
  display: flex;
  flex-wrap: nowrap;
  justify-content: center;
  padding-top: 2.25rem
}

@media (min-width: 768px) {
  .footer_footer__lowerContainer--signature__YJle_ {
    justify-content: flex-end;
    padding-top: 0
  }
}

.footer_footer__disclaimerText__11G_w {
  font-size: .625rem;
  color: #656565;
  line-height: 1rem
}

.footer_footer__disclaimerText__11G_w p {
  display: inline-block
}

.footer_footer__BCorporationText__1EZIG {
  text-align: left
}

@media (min-width: 768px) {
  .footer_footer__BCorporationText__1EZIG {
    text-align: right
  }
}

.footer_footer__BCorporationText--title__qAqDb {
  margin-bottom: .25rem;
  font-weight: 700;
  font-size: .875rem
}

.footer_footer__signatureText__GZIA2 {
  color: #656565;
  font-size: .6875rem;
  line-height: .9375rem
}

.footer_footer__signatureText--year__5zx9z {
  margin-right: .3rem
}

.footer_footer__appButton__uDo8C {
  height: 2.625rem
}

@media (min-width: 768px) {
  .footer_footer__appButton__uDo8C {
    height: 1.875rem
  }
}

.footer_footer__appButton__uDo8C:first-of-type {
  margin-right: 1rem
}

.footer_footer__appButton__uDo8C img {
  display: block;
  width: 8.75rem;
  height: 100%
}

@media (min-width: 768px) {
  .footer_footer__appButton__uDo8C img {
    height: 1.875rem;
    width: 6.25rem
  }
}

.footer_full__width___Pxkh {
  width: 100%
}

@media (min-width: 768px) {
  .iskr .footer_footer__container--upper__9JJhr {
    padding: 4.4rem 1.25rem 1.5625rem;
    box-sizing: border-box;
  }
}

.iskr .footer_footer__container--lower__zLE1P {
  max-width: 91.25rem;
  padding: 1.5rem;
  position: relative;
  color: #656565;
  display: inherit
}

@media (min-width: 768px) {
  .iskr .footer_footer__container--lower__zLE1P {
    grid-gap: unset;
    left: 0
  }
}

@media (min-width: 1024px) {
  .iskr .footer_footer__container--lower__zLE1P {
    padding: 1.5rem 1.25rem 1.25rem;
    margin: 0 auto
  }
}

.iskr .footer_footer__lowerContainer--disclaimer__L3ndw {
  letter-spacing: -.5px
}

@media (min-width: 768px) {
  .iskr .footer_footer__lowerContainer--disclaimer__L3ndw {
    width: 66.51%;
    margin-bottom: .7rem
  }
}

.iskr .footer_footer__lowerContainer--paymentMode__k28pj {
  display: block;
  margin-bottom: 1.3rem
}

@media (min-width: 768px) {
  .iskr .footer_footer__lowerContainer--paymentMode__k28pj {
    width: 66.51%;
    margin-bottom: .95rem
  }
}

.iskr .footer_footer__lowerContainer--paymentMode__k28pj .footer_paymentTips__tWEFW {
  margin-top: .25rem
}

.iskr .footer_footer__lowerContainer--paymentMode__k28pj .footer_paymentTips__tWEFW div {
  font-size: .95rem;
  position: relative;
  margin-bottom: .45rem;
  line-height: 1.5;
  margin-left: -19.2%;
  transform: scale(.8333);
  left: 9.2%
}

.iskr .footer_footer__lowerContainer--BCorporation__zyCUy {
  display: flex;
  flex-direction: column;
  color: #1e1e1e;
  font-size: .75rem;
  font-family: HelveticaNowText, sans-serif;
  min-width: 14rem
}

@media (min-width: 768px) {
  .iskr .footer_footer__lowerContainer--BCorporation__zyCUy {
    align-items: flex-end;
    position: absolute;
    width: 21.5%;
    top: 1rem;
    right: 1.5rem;
    text-align: right
  }
}

.iskr .footer_footer__lowerContainer--BCorporation__zyCUy .footer_right_copyright__OvrQf {
  display: block;
  padding-top: 1.5rem;
  color: #656565
}

@media (max-width: 767px) {
  .iskr .footer_footer__lowerContainer--BCorporation__zyCUy .footer_right_copyright__OvrQf {
    margin: 0 auto;
    padding: 1.25rem 0 .5rem
  }
}

@media (min-width: 768px) {
  .iskr .footer_footer__lowerContainer--BCorporation__zyCUy .footer_right_copyright__OvrQf {
    padding-top: 1.5rem
  }
}

.iskr .footer_footer__lowerContainer--signature__YJle_ {
  display: none
}

.iskr .footer_footer__lowerContainer--domains__1natV {
  display: inline;
  display: initial;
  font-family: HelveticaNowText, sans-serif
}

.iskr .footer_footer__lowerContainer__appButton__8VRpS:last-child {
  margin-left: 1rem
}

.iskr .footer_footer__lowerContainer__appButton__8VRpS img {
  height: 2.625rem;
  width: 8.75rem
}

@media (min-width: 768px) {
  .iskr .footer_footer__lowerContainer__appButton__8VRpS img {
    height: 1.875rem;
    width: auto
  }
}

.iskr .footer_footer__lowerContainer__appButton__8VRpS img img {
  display: block;
  max-width: 100%
}

.iskr .footer_footer__lowerContainer__bCorp__o0yA6 {
  color: #1e1e1e
}

.iskr .footer_footer__lowerContainer__bCorp__title__2QSsJ {
  padding-bottom: .25rem;
  font-weight: 700;
  font-size: .875rem
}

.iskr .footer_footer__disclaimerText__11G_w, .iskr .footer_footer__lowerContainer____eR1 .footer_text--kr__IaIAr {
  line-height: 1.76
}

.iskr .footer_footer__disclaimerText__11G_w span {
  font-family: -apple-system, Malgun Gothic, Apple SD Gothic Neo, sans-serif;
  display: inline-block;
  margin-right: .75rem
}

.iskr .footer_footer__disclaimerText__11G_w a {
  -webkit-text-decoration-line: underline;
  text-decoration-line: underline
}

@media (max-width: 767px) {
  .iskr .footer_footer__disclaimerText__11G_w {
    width: 120.484%;
    margin-bottom: .45rem;
    margin-left: -10.2%;
    line-height: 2.1;
    transform: scale(.8333);
    margin-top: -.9rem
  }

  .iskr .footer_footer__disclaimerText__11G_w .footer_footer_paymentTips__K1WPU p {
    font-size: .75rem
  }
}

.iskr .footer_footer__upperContainer--social__VUsdu {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  padding: 1.75rem 0;
  border-bottom: 1px solid hsla(0, 0%, 100%, .06)
}

@media (min-width: 768px) {
  .iskr .footer_footer__upperContainer--social__VUsdu {
    padding: 0;
    margin: 0 1rem;
    justify-content: unset;
    border-bottom: unset
  }
}

.iskr .footer_footer__upperContainer--legals__cbCfP {
  align-items: center
}

@media (min-width: 768px) {
  .iskr .footer_footer__upperContainer--legals__cbCfP {
    display: none
  }
}

.issafari .footer_footer__lowerContainer--paymentMode__k28pj .footer_paymentTips__tWEFW div {
  font-size: .6rem;
  margin-bottom: .9rem;
  margin-left: -2px;
  transform: scale(.994);
  left: 0
}

.issafari .footer_footer__lowerContainer--paymentMode__k28pj .footer_paymentTips__tWEFW div span {
  font-size: .6rem
}

@media (max-width: 767px) {
  .issafari .footer_footer__disclaimerText__11G_w {
    font-size: .76rem;
    width: auto;
    margin-bottom: .9rem;
    margin-left: -20px;
    line-height: 2.1;
    transform: scale(.9)
  }

  .issafari .footer_footer__disclaimerText__11G_w p {
    display: block
  }
}


</style>

