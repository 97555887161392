export const getLocalLang = state => {
    return state.localLang
}
export const getsearch_name = state => {
    return state.search_name
}
export const getarea_code = state => {
    return state.qh
}
export const getOpenAStoreType = state => {
    return state.oenAStoreVal;
}
export const getMsgNum = state => {
    return state.msgNum;
}
