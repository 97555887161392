<template>
  <div class='Addcar'>
    <el-drawer :visible.sync="$store.state.isShowCardrawer" :direction="direction" :before-close="beforeClose"
      custom-class="drawers" :modal-append-to-body="false" :show-close="!step">
      <template slot="title">
        <div class="title" v-if="!step">My BAG</div>
        <div class="title" v-if="step">
          <div class="rowll_left" @click="handleClickToCarlist">
            <SvgIcon iconClass="rowll_left" color="#333" width="30" height="30" />
          </div>
          <div>{{ $t('AddCar.t1') }}</div>
        </div>
      </template>
      <div class="cartContainer" v-if="cartList.length !== 0">
        <div class="list" v-if="!step">
          <div class="item" v-for="(item, index) in getcarlist" :key="index">
            <div class="cartitem">
              <div class="left">
                <shop-img :src="item.thumb_url"></shop-img>
              </div>
              <div class="right">
                <div class="title">
                  <span>{{ item.shop_name }}</span>
                  <div class="close" @click="handleDeleteCar(item.id)">
                    <SvgIcon iconClass="Delete" color="#333" width="15" height="15" />
                  </div>
                </div>
                <div class="desc">{{ item.goods_name }}</div>
                <div class="desc" v-show="item.goods_attr_str">
                  {{ $t('AddCar.t2') }} ：{{ item.goods_attr_str | extractSize }}
                </div>
                <div class="action">
                  <div class="containers">
                    <div class="left" @click="handleLose(item.num, item.id)">-</div>
                    <div class="num">{{ item.num }}</div>
                    <div class="right" @click="handleAdd(item.num, item.id)">+</div>
                  </div>
                  <div class="price">
                    {{ item.shop_price }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="box" v-else>
          <div class="wallet">
            <div class="item">
              <div class="check">
                <SvgIcon iconClass="qianbao" color="#333" width="44" height="44" />
              </div>
              <div class="name title">
                {{ $t('AddCar.t3') }}
              </div>
              <div class="title">
                {{ $t('all.a1') }}{{ wallet_price }}
              </div>
            </div>
          </div>
          <div class="timeLine">
            <div class="content">
              <div class="item" v-for="(item, index) in addressList" @click="handleChangeRESS(index, item)">
                <div class="check">
                  <SvgIcon iconClass="check" color="#333" width="20" height="20" v-if="index === idx" />
                </div>
                <div class="name">
                  <div class="title">{{ item.contacts }}</div>
                  <div>{{ item.address }}</div>
                </div>
                <div class="edit" @click="editBtn">
                  <SvgIcon iconClass="edit" color="#333" width="16" height="16" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="bottom">
          <div class="top">
            <div class="left">{{ $t('AddCar.t4') }}{{ getCarSum.total }}{{ $t('AddCar.t5') }}</div>
            <div class="right">${{ getCarSum.totalPrice.toFixed(2) }}</div>
          </div>
          <button @click="throttle(handleClickpayment)">
            <span class="left">{{ $t('AddCar.t6') }}</span>
            <span class="text">
            </span>
            <span class="right">
              <SvgIcon iconClass="rollow_right" color="#333" width="15" height="15" />
            </span>
          </button>
        </div>
      </div>
      <div class="emjoy" v-if="cartList.length === 0">
        <img src="@/assets/images/name-dd12c625.png" alt="" style="width: 395px; height: 395px;">
        <div class="title" style="text-align: center !important; width: 375px; margin-left: 50%;"><span
            style="transform: translateX(-50%);">{{ $t('AddCar.t7') }}</span></div>
      </div>
    </el-drawer>
    <!--支付密码 -->
    <el-dialog :title="$t('AddCar.t8')" :modal="false" :visible.sync="dialogpayPwd" width="30%"
      :before-close="handleClose">
      <el-input v-model="zfmm" :maxlength="6" :placeholder="$t('AddCar.t8')" :type="isshowpwd ? 'password' : 'text'" oninput="value=value.replace(/^\.+|[^\d.]/g,'')">
        <template slot="suffix">
          <div v-if="isshowpwd && zfmm !== ''" @click="isshowpwd = false">
            <SvgIcon iconClass="close_eye" color="#333" width="14" height="14" />
          </div>
          <div v-if="!isshowpwd" @click="isshowpwd = true">
            <SvgIcon iconClass="view" color="#333" width="14" height="14" />
          </div>
        </template>
      </el-input>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="throttle(handleClickZF)" style="width: 100%;">{{ $t('AddCar.t9') }}</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { mapActions, mapState, mapMutations } from "vuex";
import { throttle } from "@/utils";
export default {
  props: {},
  data() {
    return {
      direction: 'rtl',
      step: false,
      radio: 0,
      idx: 0,
      wallet_price: '',
      haspwd: false,
      dialogpayPwd: false,
      zfmm: '',
      sum: 0,
      isshowpwd: true,
      order_number: '',
      isShowOrder_Number: false
    }
  },
  created() {
    if (this.isLogin && !this.isShowBuy_seller) {
      this.getCartList({ page: 1 })
      this.getress()
      this.getuserInfo()
    }
  },
  computed: {
    ...mapState(['cartList', 'isLoading', 'isLogin', 'addressList', 'isShowBuy_seller']),
    getCarSum: function () {
      const { cartList = [] } = this
      const _list = cartList.map(res => res.goodres).flat()
      if (!_list.length) return { total: 0, totalPrice: 0 }
      return { total: _list.length, totalPrice: _list.map(m => m.shop_price * m.num).reduce((pre, next) => pre + next) }
    },
    getcarlist: function () {
      const _list = this.cartList.map(res => res.goodres).flat()
      return _list
      // return goodres[0] && goodres[0]['shop_name']
    }
  },
  filters: {
    extractSize: function (value) {
      if (value) {
        const sizeIndex = value.indexOf("size:");
        if (sizeIndex !== -1) {
          const sizeStart = sizeIndex + "size:".length;
          return value.slice(sizeStart).split(";")[0];
        }
      }
    }
  },
  methods: {
    throttle,
    ...mapActions(['getCartList', 'actionAddressList', 'getCartNum']),
    ...mapMutations(['ADD_SHOPPING_CART', 'SET_SHOW_CAR']),
    beforeClose() {
      this.SET_SHOW_CAR(false)
      if (this.isShowOrder_Number) {
        this.zfmm = ''
        this.$message.error(this.$t('AddCar.t21'))
        this.$store.dispatch('getCartNum')
        this.$router.push('/user/MyOrder')
      }
    },
    handleClose() {
      this.dialogpayPwd = false
      this.zfmm = ''
      this.$store.dispatch('getCartNum')
    },
    // 返回购物车列表
    handleClickToCarlist() {
      if (this.order_number) {
        return this.$message.error(this.$t('AddCar.t22'))
      }
      this.step = false
    },
    handleDeleteCar(cart_id) {
      this.$http.getdelcart({ cart_id }).then(res => {
        if (res.status === 200) {
          this.$message.success(res.mess)
          this.getCartList({ page: 1 })
        }
      })
    },
    handleAdd(num, id) {
      num++
      this.add_lose(num, id)
    },
    handleLose(num, id) {
      num--
      this.add_lose(num, id)
    },

    add_lose(num, id) {
      this.$http.geteditcart({
        num,
        cart_id: id
      }).then(res => {
        if (res.status === 200) {
          this.$message.success(res.mess)
          this.getCartList()
        }
      })
    },
    // 结算
    async handleClickpayment() {
      if (this.step) {
        this.sum = 1
      }
      // 没有收货地址
      if (this.$store.state.addressList.length === 0) {
        this.SET_SHOW_CAR(false)
        this.$message.warning(this.$t('AddCar.t17'))
        return this.$router.push('/user/shippingAddress')
      }
      await this.getpaypwd()
      if (!this.haspwd) {
        this.SET_SHOW_CAR(false)
        return this.$router.push('/user/changzfPwd')
      }
      this.step = true
      if (this.sum === 1 && !this.isShowOrder_Number) {
        const a = this.addressList.filter((item, index) => {
          return this.idx === index
        })
        const { id } = a[0]
        const { cartList } = this
        const cart_idres = (cartList.map(res => res.goodres).flat().map(res => res.id) || []).join(',')
        if (this.wallet_price < this.getCarSum.totalPrice) {
          this.$message.error(this.$t('AddCar.t20'))
          return this.$router.push('/user/deposit')
        }
        await this.$http.getcartbuy({ cart_idres }).then(res => {
          if (res.status === 200) {
            this.isShowOrder_Number = true
            // 创建订单拿到收货地址的id
            this.$http.getaddorderPay({
              cart_idres,
              dz_id: id
            }).then(ret => {
              if (ret.status === 200) {
                this.dialogpayPwd = true
                this.order_number = ret.data.order_number
                this.$message.success(ret.mess)
              } else {
                this.$message.error(ret.mess)
              }
            })
          }
        })
      } else if (this.sum === 1 && this.isShowOrder_Number) {
        this.dialogpayPwd = true
      }
    },
    // 获取收货地址
    getress() {
      this.$http.getressList({ page: 1 }).then(res => {
        if (res.status === 200) {
          // 存值
          this.$store.commit('setAddressList', res.data)
        }
      })
    },
    // 获取是否设置支付密码
    async getpaypwd() {
      await this.$http.hasPayPwd().then(res => {
        if (res.status === 200) {
          this.haspwd = true
        } else {
          this.$message.error(res.mess);
        }
      })
    },
    // 切换收货地址
    handleChangeRESS(i, item) {
      this.idx = i
    },
    // // 获取用户信息
    getuserInfo() {
      // let user = localStorage.getItem('userInfo')
      // this.wallet_price = JSON.parse(user).wallet_price
      // 获取用户信息
      this.$http.getUserInfo().then(res => {
        if (res.status == 200) {
          this.wallet_price = res.data.wallet_price;
        }
      })
    },
    // 支付
    handleClickZF() {
      this.$http.getzhifu({
        order_number: this.order_number,
        zf_type: 3,
        pay_password: this.zfmm,
        scene: 'goods'
      }).then(res => {
        if (res.status === 200) {
          this.$message.success(this.$t('AddCar.t18'))
          this.dialogpayPwd = false
          // 支付成功，重置购物车属性
          this.step = false
          this.sum = 0
          this.$store.commit('SET_SHOW_CAR', false)
          this.zfmm = ''
          this.order_number = ''
          // 重新获取购物车数量
          this.$store.dispatch('getCartNum')
          this.zfmm = '';
        } else {
          this.zfmm = ''
          this.$store.dispatch('getCartNum')
          this.$message.error(res.mess);
        }
      })
    },
    editBtn() {
      if (this.$route.name === 'shippingAddress') {
        return
      } else {
        this.$router.push('/user/shippingAddress')
      }
    },
  }
}
</script>
<style lang='less' scoped>
.Addcar {
  .title {}


  ::v-deep {
    .el-drawer__container {
      .drawers {
        width: 500px !important;
      }

      .el-drawer__header {
        padding-bottom: 0 !important;
        margin-bottom: 0;
      }

      .title {
        color: #000c22;
        font-family: -apple-system, BlinkMacSystemFont, "Helvetica Neue", Helvetica, Segoe UI, Arial, Roboto, "PingFang SC", "miui", "Hiragino Sans GB", "Microsoft Yahei", sans-serif;
        ;
        font-size: 30px;
        font-style: normal;
        font-weight: 700;
        line-height: 64px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        text-transform: none;
        flex: 1;
      }

      .cartContainer {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        position: relative;

        &::before {
          content: "";
          position: absolute;
          top: 0;
          left: 40px;
          width: 378px;
          height: 1px;
          background-color: #000;
          opacity: .2;
        }

        .list {
          flex: 1;
          overflow: auto;
          margin-top: 36px;
          width: 90%;

          .item {
            &:nth-child(n+2) {
              margin-top: 40px;
            }

            .cartitem {
              display: flex;
              flex-direction: row;
              align-items: flex-start;

              .left {
                .imgContainer {
                  width: 80px;
                  height: 80px;
                  border-radius: 10px;
                }
              }

              .right {
                margin-left: 20px;
                width: 300px;
                position: relative;

                .title {
                  color: #000c22;
                  font-family: Inter;
                  font-size: 24px;
                  font-style: normal;
                  font-weight: 500;
                  line-height: normal;
                  text-transform: none;
                  display: flex;
                  justify-content: space-between;
                  align-items: center;

                  span {
                    max-width: 240px;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                  }
                }

                .desc {
                  opacity: .5;
                  white-space: nowrap;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  color: #000c22;
                  font-family: Inter;
                  font-size: 18px;
                  font-style: normal;
                  font-weight: 400;
                  line-height: 24px;
                }

                .action {
                  display: flex;
                  flex-direction: row;
                  justify-content: space-between;
                  align-items: center;
                  margin-top: 10px;
                  color: #222;
                  text-align: right;
                  font-family: D-DIN-PRO;
                  font-size: 24px;
                  font-style: normal;
                  font-weight: 700;
                  line-height: 24px;

                  .containers {
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;

                    .left {
                      width: 36px;
                      height: 36px;
                      flex-shrink: 0;
                      background-color: #fff;
                      border-radius: 36px;
                      fill: var(--white, #FFF);
                      filter: drop-shadow(0px 4px 8px rgba(0, 0, 0, .1));
                      display: flex;
                      justify-content: center;
                      align-items: center;
                      color: #9b9b9b;
                      font-size: 24px;
                      cursor: pointer;
                    }

                    .num {
                      display: flex;
                      justify-content: center;
                      align-items: center;
                      width: 50px;
                      color: var(--black, #222);
                      font-family: D-DIN-PRO;
                      font-size: 24px;
                      font-style: normal;
                      font-weight: 500;
                      line-height: 20px;
                    }

                    .right {
                      width: 36px;
                      height: 36px;
                      flex-shrink: 0;
                      background-color: #fff;
                      border-radius: 36px;
                      fill: var(--white, #FFF);
                      filter: drop-shadow(0px 4px 8px rgba(0, 0, 0, .1));
                      display: flex;
                      justify-content: center;
                      align-items: center;
                      color: #9b9b9b;
                      font-size: 24px;
                      cursor: pointer;
                      margin: 0 !important;
                    }
                  }

                  .price {}
                }
              }
            }
          }
        }

        .box {
          background-color: #fff;
          margin-top: 40px;
          padding: 20px;
          width: 100%;
          box-sizing: border-box;

          .wallet {
            .item {
              display: flex;
              align-items: center;
              justify-content: space-between;
              padding: 20px;
              margin-bottom: 10px;
              border: 1px solid rgba(0, 13, 33, .1);

              .check {
                width: 44px !important;
                height: 44px !important;
                box-sizing: border-box;
              }

              .name {
                flex: 1;
                margin: 0 20px 0 30px;
              }

              .title {
                color: #222;
                font-family: D-DIN-PRO;
                font-size: 16px;
                font-style: normal;
                font-weight: 700;
                line-height: 16px;
              }
            }
          }

          .timeLine {
            display: flex;
            width: 100%;
            justify-content: space-between;

            .content {
              font-size: 16px;
              flex: 1;

              .item {
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding: 20px;
                margin-bottom: 10px;
                border: 1px solid rgba(0, 13, 33, .1);

                .check {
                  width: 20px !important;
                  height: 20px !important;
                  margin: 12px;
                  box-sizing: border-box;
                  border: 2px solid #9B9B9B;
                }

                .name {
                  flex: 1;
                  margin: 0 20px 0 30px;

                  .title {
                    color: #222;
                    font-family: Inter;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 14px;
                    margin-bottom: 15px;
                  }

                  div {
                    color: #5f5e5d;
                    font-family: Poppins;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 14px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                  }
                }
              }
            }
          }
        }

        ::-webkit-scrollbar {
          width: 5px;
          height: 5px;
        }

        ::-webkit-scrollbar-thumb {
          border-radius: 10px;
          box-shadow: inset 0 0 5px rgba(0, 0, 0, .1);
          background: #a8abb2;
        }

        ::-webkit-scrollbar-track {
          box-shadow: inset 0 0 5px rgba(0, 0, 0, .1);
          border-radius: 0;
          background: #c7ddfc;
        }

        ::-webkit-scrollbar-track-piece {
          background: #eff3f8;
          height: 5px;
        }

        .bottom {
          height: 172px;
          width: 100%;
          background-color: #fff;
          position: relative;
          padding: 40px;
          box-sizing: border-box;

          &::before {
            content: "";
            position: absolute;
            top: 0;
            left: 40px;
            width: 378px;
            height: 1px;
            background-color: #000;
            opacity: .2;
          }

          .top {
            display: flex;
            justify-content: space-between;
            align-items: center;

            .left {
              color: #000c22;
              font-family: Inter;
              font-size: 18px;
              font-style: normal;
              font-weight: 500;
              line-height: normal;
              text-transform: none;
            }

            .right {
              color: #222;
              text-align: right;
              font-family: D-DIN-PRO;
              font-size: 30px;
              font-style: normal;
              font-weight: 700;
              line-height: 30px;
            }
          }

          button {
            display: flex;
            justify-content: space-between;
            flex-direction: row;
            align-items: center;
            width: 100%;
            height: 54px;
            text-align: center;
            font-family: Inter;
            font-size: 18px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
            text-transform: none;
            border: none;
            background: rgb(34, 34, 34);
            color: rgb(255, 255, 255);
            padding: 25px;
            cursor: pointer;
            margin-top: 25px;
          }
        }

      }

      .emjoy {
        text-align: center;

        .title {
          span {
            font-size: 16px;
          }
        }
      }

    }
  }
}

::v-deep {
  .el-button--primary {
    background-color: #222222;
    border: none;
  }

  .el-input__suffix {
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
    height: 50%;
  }
}
</style>
