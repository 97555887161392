<template>
  <div class="chatcontent">
    <div class="content" >
      <div class="item-wrap" @scroll="handleScroll" ref="loadmore" v-loading="isLoading">
        <div class="list-item" v-for="(item, index) in personlist" :key="index"
          :class="{ 'my-chat': item.userType !== 'home' }">
          <div class="chat-text">
            <div class="chat-con">
              <p v-if="item.message_type === 'text'">
              <div v-if="item.message[0].content?.id" class="infos">
                <img :src="item.message[0].content?.thumb_url">
                <div class="txt">
                  <span class='name'>
                    {{ item.message[0].content?.goods_name }}
                  </span>
                  <span class="price">
                    {{ item.message[0].content?.zs_shop_price }}
                  </span>
                </div>
              </div>
              <div v-else>
                {{ item.message[0].content }}
              </div>
              </p>
              <p v-if="item.message_type === 'img'">
                <img width="100" height="100" :src="getImageUrl(item.message)" alt="">
              </p>
              <!-- <p v-else>
                <img width="100" height="100" :src="parseGoodsStr(item.message)" alt="">
              </p> -->
            </div>
            <p class="time">{{ item.createtime | formatTime }}</p>
          </div>
        </div>
      </div>
    </div>
    <div class="botton inputDeep">
      <el-input v-model="chatVal" @keydown.enter.native="Keydown($event)" :autosize="{ minRows: 3, maxRows: 3 }"
        :placeholder="$t('chat.t2')" show-word-limit type="textarea" />
      <div class="send">
        <el-upload class="upload-demo" :accept="'image/*'" :data="formData" :limit="1" :action="$upload_url"
          :show-file-list="false" :on-success="handleAvatarSuccess">
          <i class="el-icon-picture-outline"></i>
        </el-upload>
        <el-button type="primary" color="#222" @click="getInputMessage">{{ $t('chat.t3') }}</el-button>
      </div>
    </div>
  </div>
</template>
<script>
import { Socket } from "@/utils/socket";
import { mapState } from "vuex";
export default {
  props: {
    id: {},
    to_username: {},
    shopToken: {

    },
  },
  data() {
    return {
      personlist: [],
      showChatlist: false,
      chatVal: '',
      formData: '',
      content_username: '',
      token: localStorage.getItem('token'),
      socketObj: Socket.getInstance(),
      page: 1,
      isMore: true
    }
  },
  components: {},
  async created() {
    this.formData = this.$http.returnCommonParams(
      "api/Common/uploadPic",
      "usernfo"
    );
  },
  mounted() {
    if (!this.socketObj.socket) {
      //  判断连接
      this.timer = setInterval(() => {
        if (this.socketObj.socket.readyState) {
          this.init()
        }
      }, 1500);
      return;
    }
    if (!this.socketObj.socket.readyState) {
      //  判断连接
      this.timer = setInterval(() => {
        if (this.socketObj.socket) {
          clearInterval(this.timer);
          this.init()
        }
      }, 1500);
      return;
    }
    this.init()
    this.$refs.loadmore.addEventListener('scroll', this.handleScroll)
  },
  beforeDestroy() {
    this.socketObj.socket && this.socketObj.socket.send(JSON.stringify({
      type: 'readmsg',
      data: {
        fromid: this.token,
        toid: this.id || this.shopToken,
      }
    }))
  },
  computed: {
    ...mapState(['isLoading'])
  },
  filters: {
    formatTime: function (timestamp) {
      // 将时间戳转换成毫秒
      const date = new Date(timestamp * 1000);

      // 获取小时和分钟
      const hours = date.getHours();
      const minutes = date.getMinutes();

      // 格式化小时和分钟
      const formattedTime = `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;

      return formattedTime;
    },
  },
  methods: {
    goback() {
      this.$emit('close')
    },
    handleScroll(event) {
      let scrollTotalHeight = this.$refs?.loadmore?.scrollHeight // 滚动元素的总的高度
      let scrollHeight = this.$refs?.loadmore.scrollTop // 盒子被卷去的高度
      if (scrollTotalHeight - -scrollHeight < 400 && this.isMore) {
        console.log('发请求 加载更多数据.....');
        this.isMore = false;
        this.$http.getChatMsgList({
          page: ++this.page,
          toid: this.id || this.shopToken
        }).then(res => {
          if (res.status === 200) {
            if (res.data.length) {
              let recordList = res.data;
              let newList = [];
              newList = recordList.map((item) => {
                item.to_headimgurl = item.fromid === this.token ? item.to_headimgurl : item.from_headimgurl;
                item.message = this.generateRichTextNode(item.message, item.message_type);
                item.userType = item.fromid === this.token ? "customer" : "home";
                return item;
              });
              this.$nextTick(() => {
                this.personlist.push(...newList);
                // isMore.value = true;
              });
            } else {
              this.isMore = false;
            }
          } else {
            this.$message.warning(res.mess)
            this.isMore = false;
          }
        })

      }
    },
    getImageUrl(str) {
      const regex = /\[([^)]+)\]/;
      const match = str.match(regex);

      // 如果有匹配的结果，返回提取到的链接，否则返回空字符串
      return match && match.length > 1 ? match[1] : str;
    },
    // 生成富文本节点
    generateRichTextNode(text, type) {
      // console.log(typeof text);
      // if (typeof text === 'object') {
      //   text = JSON.stringify(text)
      //   console.log(text);
      // }
      if (!text) return false
      // 如果是数组，则直接返回
      if (type === 2 || type === 'img') return text

      let tempStr = text
      return [{
        type: 'text', content: tempStr
      }]
    },
    getGoodsimg(v) {
      const a = JSON.parse(v);
    },
    parseGoodsStr(str) {
      const goodsStr = str.replace(/(^goods\[)|(\]$)/g, "");
      const a = goodsStr && JSON.parse(goodsStr)
      return a.goodsThumb;
    },
    init() {
      // 标为已读
      this.socketObj.socket.send(JSON.stringify({
        type: 'readmsg',
        data: {
          fromid: this.token,
          toid: this.id || this.shopToken,
        }
      }))
      // 获取历史记录
      this.socketObj.socket.send(
        JSON.stringify({
          type: "history",
          data: {
            fromid: this.token,
            toid: this.id || this.shopToken,
          },
        })
      );
      this.socketObj.socket.onmessage = (res) => {
        const data = JSON.parse(res.data);
        console.log(data);
        if (data.type === "say") {
          console.log("消息say触发", data);
          const { data: receiveData = {}, data: { message_type } } = data;
          receiveData.fromid !== this.token && (receiveData.to_headimgurl = receiveData.from_headimgurl)
          if (message_type === "img") {
            receiveData.width = 150;
            receiveData.height = 150;
          } else {
            receiveData.message = this.generateRichTextNode(receiveData.message, receiveData.message_type);
          }
          receiveData.userType = receiveData.fromid === this.token ? "customer" : "home";
          receiveData.createtime = new Date().getTime() / 1000;
          if (receiveData.fromid === this.id || this.shopToken && receiveData.toid === this.token) {
            this.personlist.unshift(receiveData);
          }
        }

        if (data.type === "history") {
          // let receiveMsg = data.data.reverse();
          let receiveMsg = data.data;
          this.personlist = receiveMsg.map((item) => {
            if (item.fromid !== this.token) {
              item.to_headimgurl = item.from_headimgurl;
            }
            // if (item.message_type === 'text') {
            //   // item.message = this.generateRichTextNode(
            //   //   item.message,
            //   //   item.message_type
            //   // );
            //   const a = JSON.parse(item.message)
            //   console.log(a);
            //   if (a.id) {
            //     console.log(111231231);
            //     item.message = a
            //   } else {
            //     console.log('aaaaa');
            //     item.message = this.generateRichTextNode(
            //       item.message,
            //       item.message_type
            //     );
            //   }
            // }
            try {
              const dd = JSON.parse(`${item.message}`);
              if (dd.id) {
                item.message = dd;
                this.personlist.push(item)
              } else {
                // item.message =
                this.personlist.push(item)
              }
            } catch (e) {
              //TODO handle the exception
              this.personlist.push(item)
            }
            if (item.message_type === "img") {
              
            } else {
              item.message = this.generateRichTextNode(
                item.message,
                item.message_type,

              );
            }
            item.userType = item.fromid === this.token ? "customer" : "home";
            return item;
          });
        }
      }
    },
    // 回车发送
    Keydown() {
      // let e = window.event || arguments[0];
      // // 英文下｜中文下： 13 Enter Enter
      // // 中文下有文字没进入输入框情况是：299 Enter Enter
      // if (e.key == "Enter" && e.code == "Enter" && e.keyCode == 13) {
      //     if (!e.metaKey) { // e.metaKey == true证明是ctrl+enter
      //         e.returnValue = false;
      //         this.getInputMessage(e.target.value);
      //         return false;
      //     } else {
      //         this.chatVal = this.chatVal + '\n';
      //     }
      // }
    },
    getInputMessage() {
      if (this.chatVal.trim() === "") return;
      let sendData = {
        type: "say",
        data: {
          userType: "customer",
          fromid: this.token,
          toid: this.id || this.shopToken,
          message: this.chatVal,
          message_type: "text",
        },
      };
      if (sendData.data.fromid === sendData.data.toid) return;
      this.sendMsg(sendData);
      this.chatVal = "";
    },
    async sendMsg(data) {
      console.log("触发send", data);
      const _data = Object.assign({}, data);
      this.socketObj.socket.send(JSON.stringify(_data))
      const { data: receiveData = {} } = _data;
      const { message_type, message } = _data.data;
      if (message_type === "img") {
      } else {
        receiveData.message = this.generateRichTextNode(
          receiveData.message,
          receiveData.message_type
        );
      }
      receiveData.createtime = new Date().getTime() / 1000;
      this.personlist.unshift(receiveData);
    },
    handleAvatarSuccess(response) {
      this.$Message.success('上传成功')
      let sendData = {
        type: "say",
        data: {
          userType: "customer",
          fromid: localStorage.getItem("token"),
          toid: this.id || this.shopToken,
          message: response.data.full_path,
          message_type: "img"
        }
      };
      this.sendMsg(sendData);
    },
  },
}
</script>
<style lang="less" scoped>
.chatcontent {
  width: 100%;
  flex: 1;
  height: 100%;
  display: flex;

  flex-direction: column;
  position: relative;
  overflow: scroll;
}

.content {
  width: 100%;
  flex: 1;
  height: 100%;
  display: flex;

  flex-direction: column;
  position: relative;
  overflow: scroll;

  .item-wrap {
    flex: 1;
    display: flex;
    overflow-y: auto;
    flex-direction: column-reverse;
    align-items: inherit;


    .list-item {
      padding: 0 12px;
      display: flex;
      margin: 10px 0;

      &:first-child {
        margin-bottom: auto;
        margin-bottom: 20px;
      }

      &.my-chat {
        flex-direction: row-reverse;

        .chat-text {
          align-items: flex-end;

          .chat-con {
            // background: #222;

            border-radius: 8px 8px 8px 8px;

            >p {
              color: #fff;
            }

            .infos {
              display: flex;

              img {
                width: 56px;
                height: 56px;
                border: 1px solid #000;
                border-radius: 5px;
              }

              .txt {
                flex: 1 1 0%;
                width: 150px;
                overflow: hidden;
                text-overflow: ellipsis;
                display: flex;
                flex-direction: column;
                margin-left: 10px;

                .name {
                  height: 25px;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  white-space: nowrap;
                }

                .price {
                  color: red;
                }
              }
            }
          }

          .time {
            text-align: right;
          }
        }
      }


      .chat-text {
        margin: 0 8px;
        display: flex;
        flex-direction: column;
        position: relative;

        .chat-con {
          background: #fff;
          border-radius: 0px 8px 8px 8px;
          padding: 10px 12px;
          width: fit-content;


          p {
            font-size: 14px;
            line-height: 18px;
            color: #222;
            margin: 0;
          }
          .infos {
              display: flex;

              img {
                width: 56px;
                height: 56px;
                border: 1px solid #000;
                border-radius: 5px;
              }

              .txt {
                flex: 1 1 0%;
                width: 150px;
                overflow: hidden;
                text-overflow: ellipsis;
                display: flex;
                flex-direction: column;
                margin-left: 10px;

                .name {
                  height: 25px;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  white-space: nowrap;
                }

                .price {
                  color: red;
                }
              }
            }
        }

        .time {
          position: absolute;
          bottom: -12px;
          right: -1px;
          font-size: 12px;
          line-height: 12px;
          color: #757575;
          margin: 0;
          margin-top: 4px;
        }
      }
    }
  }
}

.nodata {
  align-items: center;
  justify-content: center;
}

.botton {
  background-color: #FCFCFC;

  &>.send {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 20px 10px;
  }

  .upload-demo {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}

::v-deep {
  .inputDeep {

    .el-textarea__inner {
      border: 0 !important;
      resize: none !important;
      background-color: #FCFCFC;
      height: 100%;
      box-shadow: none !important;

    }
  }

  .el-loading-spinner {
    margin-top: 0;
  }

  .el-button--primary {
    background-color: #222;
    border: none;
  }
}

// 折叠加隐藏过渡效果
.collapse-enter-active,
.collapse-leave-active {
  transition: all 0.5s;
  // 防止折叠时元素溢出
  overflow: hidden;
}

.collapse-enter-from,
.collapse-leave-to {
  height: 0px !important;
  opacity: 0 !important;
}

.chatList {
  flex: 1;
}

.chatList_item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #fff;
  margin-bottom: 2px;
  padding: 10px 20px;

  &>div:last-child {
    flex: 1;
    width: 100%;

    &>div {
      line-height: 18px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 10px;

      .ccc {
        color: #b4afaf;
        font-size: 12px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      .name {
        color: #000;
        font-size: 14px;
      }
    }

    .read {
      background-color: #222;
      color: #fff;
      padding: 2px 10px;
      line-height: 12px;
      font-size: 12px;
      border-radius: 10px;
    }
  }

  .img {
    //background-color: red;
    width: 48px;
    height: 48px;
    margin-right: 8px;
  }
}
</style>
