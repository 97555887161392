<template>
    <div class='searchAll'>
        <div class="container">
            <div class="container-page">
                <div class="containerPage">
                    <div class="searchHistory" v-if="search_list.length === 0 && store_list.length === 0" v-loading="isLoading">
                        <div class="title">
                            {{ $t('searchAll.t1') }}
                            <div class="del" @click="closeAll">
                                <SvgIcon iconClass="trash" color="#333" width="18" height="18" />
                            </div>
                        </div>
                        <div class="list">
                            <el-tag v-for="(item, index) in historyList[type]" :key="index" color="#222" closable
                                :disable-transitions="false" @close="handleClose(item, index)" @click="handleClick(item)">
                                {{ item }}
                            </el-tag>
                        </div>
                    </div>
                    <div class="searchRes" v-loading="isLoading">
                        <div class="list_pro" v-if="!isShop_Store">
                            <GoodsItem v-for="(item,index) in search_list" :key="index" :item="item" @clicks="handleClickToDetails(item.id)">
                            </GoodsItem>
                        </div>
                        <!-- <div class="list_shop" v-else>
                            <shopItem v-for="(info,index) in store_list" :key="index" :item="info"></shopItem>
                        </div> -->
                        <div class="warper" @click="handleClickLoad_More"
                            v-if="search_list.length > 0 && search_list.length === 10">{{
                                $t('searchAll.t2') }}
                        </div>
                        <div class="nodata" v-if="search_list.length === 0">
                            <SvgIcon iconClass="nodata" color="#333" width="80" height="80" />
                            <div class="title">
                                {{ $t('searchAll.t3') }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <headerNav v-show="false" ref="headerNavs"></headerNav>
    </div>
</template>
<script>
import { mapState } from "vuex";
import headerNav from "./headerNav.vue";
export default {
    data() {
        return {
            type: '',
        }
    },
    computed: {
        ...mapState(['search_list', 'search_name', 'isLoading', 'store_list', 'isShop_Store', 'historyList'])
    },
    components: {
        headerNav
    },
    created() {
        if (this.isShop_Store) {
            this.type = 1
        } else {
            this.type = 0
        }
    },
    watch: {
        isShop_Store: function (n, o) {
            if (n) {
                this.type = 1
            } else {
                this.type = 0
            }
        },
        deep: true,
    },
    mounted() {
        // console.log(this.$refs.headerNavs.handleSearch);
    },
    methods: {

        handleClickToDetails(id) {
            this.$router.push({
                path: '/goodsDetail',
                query: {
                    id: id
                },
            })
        },
        handleClickLoad_More() {
            this.$store.state.search_page++
            this.$store.commit('SET_SEARCH_PAGE', this.$store.state.search_page)
            this.$http.getsearchgoods({
                page: this.$store.state.search_page,
                keyword_name: this.search_name
            }).then(res => {
                if (res.status === 200) {
                    this.$store.commit('SET_SEARCH_LIST', res.data.goodres)
                }
            })
        },
        handleClose(item) {
            const i = this.historyList[this.type].indexOf(item)
            if (i >= 0) {
                this.historyList[this.type].splice(i, 1)
            }
            this.$store.commit('setHistoryList', this.historyList)
        },
        closeAll() {
            this.historyList[this.type].splice(0, this.historyList[this.type].length)
            this.$store.commit('setHistoryList', this.historyList)
        },
        handleClick(v) {
            this.$store.commit('SET_SEARCH_NAME',v)
            this.$refs.headerNavs.handleSearch()
        }
    },
}
</script>
<style lang='less' scoped>
.searchAll {
    .container {

        .container-page {
            .containerPage {
                .searchRes {
                    margin-top: 30px;

                    .list_pro {
                        display: grid;
                        grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
                        gap: 20px;
                        padding-bottom: 50px;
                        .item {}
                    }

                    .list_shop {}

                    .nodata {
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        align-items: center;
                        width: 100%;
                        height: fit-content;
                        min-height: 200px;

                        .title {
                            margin-top: 15px;
                            color: #a4a4a4;
                            text-align: center;
                            font-family: Poppins;
                            font-size: 14px;
                            font-style: normal;
                            font-weight: 500;
                            line-height: 14px;
                        }
                    }

                    .warper {
                        width: 100%;
                        height: 48px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        color: var(--main-thick-black, #040404);
                        text-align: center;
                        font-family: Poppins;
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 700;
                        line-height: 24px;
                        background-color: #fff;
                        margin-top: 20px;
                        cursor: pointer;
                    }
                }

                .searchHistory {
                    margin: 0 10px;
                    .title {
                        display: flex;
                        justify-content: space-between;
                        margin-top: 80px;
                        color: #222;
                        font-family: Poppins;
                        font-size: 18px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: 18px;
                    }

                    .list {
                        display: flex;
                        flex-direction: row;
                        justify-content: flex-start;
                        flex-wrap: wrap;
                        margin-top: 20px;

                        .item {
                            margin-top: 20px;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            padding: 12px;
                            flex-direction: row;
                            background-color: #222;
                            color: #fff;
                            font-family: Poppins;
                            font-size: 16px;
                            font-style: normal;
                            font-weight: 500;
                            line-height: 14px;
                            cursor: pointer;

                            &:nth-child(n+2) {
                                margin-left: 10px;
                            }
                        }
                    }
                }
            }
        }
    }
}

::v-deep {
    .el-tag {
        color: #fff;
        // padding: 12px;
        font-size: 16px;
        height: 100%;
        margin-right: 10px;

        .el-tag__close {
            color: #fff;
        }
    }
}
@media screen and (max-width: 1220px) {
    .searchRes{
        margin: 30px 20px 0px 20px !important;
        .list_pro{
            grid-template-columns: 1fr 1fr 1fr 1fr !important;
        }
    }
}
@media screen and (max-width: 950px) {
    .searchRes{
        .list_pro{
            grid-template-columns: 1fr 1fr 1fr!important;
        }
    }
}
@media screen and (max-width: 740px) {
    .searchRes{
        .list_pro{
            grid-template-columns: 1fr 1fr!important;
        }
    }
}
@media screen and (max-width: 500px) {
    .searchRes{
        .list_pro{
            .goods-item-wrapper{
                /deep/ .goods-img{
                    height: 165px !important;
                }
            }
        }
    }
}
@media screen and (max-width: 420px) {
    .searchRes{
        .list_pro{
            grid-template-columns: 1fr 1fr!important;
        }
    }
}
@media screen and (max-width: 390px) {
    .searchRes{
        .list_pro{
            .goods-item-wrapper{
                /deep/ .goods-img{
                    height: 135px !important;
                }
            }
        }
    }
}
</style>
