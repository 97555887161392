import _axios from "./index";
import md5 from 'md5';
import {
    SECRETKEY,
} from "@/config/index";

const baseAppoint = SECRETKEY;
import store from "@/store/index"

const loginInfo = localStorage.getItem("loginInfo");
const {
    token = ""
} = JSON.parse(loginInfo) || {};
const device_token = "";

let api_token = null;


function request(url, data = {}, method = 'POST', config = {isLoading: !0}) {
    // let lang = localStorage.getItem('lang') ? JSON.parse(localStorage.getItem('lang')).code : 'en'
    let lang = store.getters.getLocalLang.code || 'en'
    switch (lang) {
        case "zh-Hans": // 中文简体
            lang = "zh-cn";
            break;
        case "en": // 英文
            lang = "en-us";
            break;
        case "zh-Hant": // 中文繁体
            lang = "fan";
            break;
        case "ja": // 日语
            lang = "ja";
            break;
        case "ko": // 韩语
            lang = "ko";
            break;
        case "de": // 德语
            lang = "de";
            break;
        case "fr": // 法语
            lang = "fr";
            break;
        case "es": // 西班牙语
            lang = "es";
            break;
        case "ru": // 俄语
            lang = "ru";
            break;
        case "th": // 泰语
            lang = "th";
            break;
        case "ida": // 印尼语
            lang = "ida";
            break;
        case "may": // 马来语
            lang = "may";
            break;
        case "pt": // 葡萄牙语
            lang = "pt";
            break;
        case "yn": // 越南语
            lang = "yn";
            break;
        case "ara": // 阿拉伯语
            lang = "ara";
            break;
    }
    api_token = md5(`${url}${baseAppoint}`);
    // const config = {}
    // if (data.isLoading) {
    //     config.isLoading = data.isLoading || true
    // }
    // // console.log(`${url}${baseAppoint}`)
    return _axios.request({
        url: `${url}?lang=${lang}`,
        method,
        config: config,
        data: {
            ...data,
            api_token,
            client_id: 1,
            client_type: 'pc',
            token: localStorage.getItem('token') || ''
        }
    },);
}

export default {
    returnCommonParams(apiUrl = "", name = "common") {
        return {
            api_token: md5(`${apiUrl}${baseAppoint}`),
            client_id: 1,
            token,
            device_token,
            name
        };
    },

    /**
     * 登录
     *
     * @param {any} data
     * @returns
     */
    getPwdLogin(data) {
        return request("api/Login/login", data);
    },
    /**
     * 注册接口
     *
     * @param {any} data
     * @returns
     */
    getRegister(data) {
        return request("api/Register/register", data);
    },
    /**
     * 找回密码提交接口
     *
     * @returns
     */
    findBackPwd(data) {
        return request("api/MemberInfo/findBackPwd", data);
    },
    /**
     * 获取邮箱验证码
     *
     * @param {any} data
     * @returns
     */
    getEmailCode(data) {
        return request("api/EmailCode/send", data);
    },
    // 修改个人信息
    editprofile(data) {
        return request("api/MemberInfo/editprofile", data);
    },
    // 获取拍卖状态
    getpaistatus(data) {
        return request("api/shops/get_pai_status", data);
    },
    // 获取拍卖详情
    get_pai_list(data) {
        return request("api/Shops/get_pai_list", data);
    },
    // 开始拍卖
    addShopGoods(data) {
        return request("api/Shops/pu_product", data);
    },
    //获取正在竞拍的商品
    get_pai_my(data) {
        return request("api/Shops/get_pai_my", data);
    },
    /**
     * 轮播图
     *
     * @param {any} data
     * @returns
     */
    getAdByTag(data) {
        return request("api/Ad/getAdByTag", data);
    },
    /**
     * 主页获取商品信息接口
     *
     * @param {any} data
     * @returns
     */
    getGoodsList(data) {
        return request("api/Index/getGoodsList", data);
    },
    /**
     * 商品详情页
     *
     * @returns
     */
    getgoodsinfo(data) {
        return request("api/Goods/goodsInfo", data);
    },
    /**
     *  加入购物车
     *
     * @returns
     */
    getaddcart(data) {
        return request("api/Cart/addcart", data);
    },
    /**
     *  购物车商品总数
     *
     * @returns
     */
    getgetnum(data) {
        return request("api/Cart/getnum", data);
    },
    /**
     *  购物车商品列表
     *
     * @returns
     */
    getCartList(data) {
        return request("api/Cart/index", data);
    },
    /**
     *  删除购物车商品
     *
     * @returns
     */
    getdelcart(data) {
        return request("api/Cart/delcart", data);
    },
    /**
     *  修改购物车商品购买数量接口
     *
     * @returns
     */
    geteditcart(data) {
        return request("api/Cart/editcart", data);
    },
    /**
     *  个人中心基本信息接口
     *
     * @returns
     */
    getUserInfo(data) {
        return request("api/MemberInfo/readprofile", data);
    },
    /**
     * 获取我的关注列表
     *
     * @param {any} data
     * @returns
     */
    getshoucanglst(data) {
        return request("api/MemberColl/shoucanglst", data);
    },
    /**
     * 会员新增收货地址接口
     *
     * @param {any} data
     * @returns
     */
    getAddressadd(data) {
        return request("api/Address/add", data);
    },
    /**
     * 会员编辑收货地址接口
     *
     * @param {any} data
     * @returns
     */
    getAddressedit(data) {
        return request("api/Address/edit", data);
    },
    /**
     * 删除收货地址接口
     *
     * @param {any} data
     * @returns
     */
    getAddressdel(data) {
        return request("api/Address/del", data);
    },
    /**
     * 获取收货地址列表接口
     *
     * @param {any} data
     * @returns
     */
    getressList(data) {
        return request("api/Address/index", data);
    },
    /**
     * 修改登录密码接口
     *
     * @param {any} data
     * @returns
     */
    geteditpwd(data) {
        return request("api/MemberInfo/editpwd", data);
    },
    /**
     *    登录用户获取自己手机号
     *
     * @param {any} data
     * @returns
     */
    getUserPhone(data) {
        return request("api/MemberInfo/getUserPhone", data);
    },
    /**
     * 设置支付密码接口
     *
     * @param {any} data
     * @returns
     */
    setPayPwd(data) {
        return request("api/MemberInfo/setPayPwd", data);
    },
    /**
     * 修判断用户支付密码设置与否接口
     *
     * @param {any} data
     * @returns
     */
    hasPayPwd(data) {
        return request("api/MemberInfo/hasPayPwd", data);
    },
    // 根据文章标签获取文章
    getArticleByTag(data) {
        return request("api/Article/getArticleByTag", data);
    },
    /**
     * 申请入驻获取相关信息
     *
     * @param {any} data
     * @returns
     */
    getruzhuinfo(data) {
        return request("api/ApplyInfo/ruzhuinfo", data);
    },
    /**
     * 商家 购物车订单详情
     *
     * @returns
     */
    getPrePayOrder(data) {
        return request("api/Recharge/createOrder", data);
    },
    // 获取支付方式开启状态
    getPayOpenStatus(data) {
        return request("api/Pay/getPayOpenStatus");
    },
    // USDT配置接口
    getUsdtConfig(data) {
        return request("api/UsdtConfig/getUsdtConfig", data);
    },
    /**
     *  新品首发
     *
     * @returns
     */
    getPcIndexShowcase(data) {
        return request("api/PcIndex/getIndexShowcase", data)
    },
    /**
     *  店铺详情
     *
     * @returns
     */
    getListShops(data) {
        return request("api/Shops/getRecommendShops", data);
    },
    /**
     * 获取商家管理数据
     *
     * @param {any} data
     * @returns
     */
    getallgoods(data) {
        return request("api/Shops/getShopGoodsList", data);
    },
    /**
     *  热门商品/推荐
     *
     * @returns
     */
    getPcIndexGoodsHome(data) {
        return request("api/PcIndex/goodsHome", data)
    },
    /**
     *  获取语言种类
     *
     * @returns
     */
    getLangList() {
        return request("api/Lang/getLangList");
    },
    /**
     *  获取国际区号
     *
     * @returns
     */
    getCountryList() {
        return request("api/Country/getCountryLst");
    },
    /**
     * 获取首页顶部导航列表
     *
     */
    getIndexNavList() {
        return request("api/Index/getIndexNavList");
    },
    /**
     * 搜索商品接口
     *
     * @param {any} data
     * @returns
     */
    getsearchgoods(data) {
        return request("api/Search/searchGoods", data);
    },
    /**
     * 搜索店铺接口
     *
     * @param {any} data
     * @returns
     */
    getShopList(data) {
        return request("api/Shops/getShopList", data);
    },
    /**
     * 支付订单接口
     *
     * @param {any} data
     * @returns
     */
    getzhifu(data) {
        return request("api/Order/zhifu", data);
    },
    /**
     * 购物车创建订单支付接口
     *
     * @param {any} data
     * @returns
     */
    getaddorderPay(data) {
        return request("api/Order/addorder", data);
    },
    /**
     * 购物车购买确认订单接口(可以批量)
     *
     * @param {any} data
     * @returns
     */
    getcartbuy(data) {
        return request("api/Order/cartbuy", data);
    },
    //获取店铺关注数据
    getFollowShops(data) {
        return request("api/Shops/getFollowShopList", data);
    },
    /**
     * 取消关注商家接口
     *
     * @param {any} data
     * @returns
     */
    getshopcancelcoll(data) {
        return request("api/CollShops/cancelcoll", data);
    },
    /**
     * 关注商家接口
     *
     * @param {any} data
     * @returns
     */
    getshopcoll(data) {
        return request("api/CollShops/coll", data);
    },
    // 开店上传
    applyShop(data) {
        return request("api/ApplyInfo/applyShop", data);
    },
    getzhifut(data) {
        return request("api/Order/zhifu", data);
    },
    // 获取支付方式信息接口
    // getBankCardinfo(data) {
    //     return request("api/BankCard/bankcardlist", data);
    // },
    // 获取余额提现信息
    getBalanceWithdrawInfo(data) {
        return request("api/Withdraw/index", data);
    },
    // 买家提现请求
    doWithdraw(data) {
        return request("api/Withdraw/doWithdraw", data);
    },
    // usdt提现请求
    doWithdrawusdt(data) {
        return request("api/Withdraw/doWithdrawusdt", data);
    },
    // /**
    //  * 账单记录列表接口
    //  *
    //  * @param {any} data
    //  * @returns
    //  */
    // billList(data) {
    //     return request("api/Recharge/billList", data);
    // },
    // 存款日志记录
    getRechargeList(data) {
        return request("api/Recharge/getRechargeList", data);
    },
    /**
     * 余额提现记录列表接口
     *
     * @param {any} data
     * @returns
     */
    getWithdrawList(data) {
        return request("api/Withdraw/getWithdrawList", data);
    },
    /**
     * 所有订单列表接口
     *
     * @param {any} data
     * @returns
     */
    getMyOrder(data) {
        return request("api/MemberOrder/index", data);
    },
    /**
     * 取消订单接口
     *
     * @param {any} data
     * @returns
     */
    getMyOrderquxiao(data) {
        return request("api/MemberOrder/quxiao", data);
    },
    /**
     * 确认收货接口
     *
     * @param {any} data
     * @returns
     */
    getqrshouhuo(data) {
        return request("api/MemberOrder/qrshouhuo", data);
    },
    /**
     * 删除订单接口
     *
     * @param {any} data
     * @returns
     */
    deleteOrder(data) {
        return request("api/MemberOrder/delorder", data);
    },

    shouyiapi(data) {
        return request("api/Order/shouyiapi", data);
    },
    /**
     * 商家详情接口
     *
     * @returns
     */
    getorderCount(data) {
        return request("api/Merchant/orderCount", data);
    },


    getShopInfoByDay(data) {
        return request("api/shops/getShopInfoByDay", data);
    },


    /**
     * 订单详情接口
     *
     * @param {any} data
     * @returns
     */
    getMyorderinfo(data) {
        return request("api/MemberOrder/getOrderInfo", data);
    },
    /**
     * 获取入驻审核状态信息接口
     *
     * @param {any} data
     * @returns
     */
    getapplystatus(data) {
        return request("api/ApplyInfo/applystatus", data, 'POST', {isLoading: !1});
    },
    /**
     * 商品列表接口
     *
     * @param {any} data
     * @returns
     */
    getCategoryGoodsList(data) {
        return request("api/Goods/getCategoryGoodsList", data);
    },
    /**
     * 支付订单获取订单信息接口
     *
     * @param {any} data
     * @returns
     */
    getzhifuorder(data) {
        return request("api/MemberOrder/zhifuorder", data);
    },
    /**
     * 申请成为分销商
     *
     * @param {any} data
     * @returns
     */
    submitDistribution(data) {
        return request("api/DistributionUser/submitDistribution", data);
    },
    /**
     * 获取平台客服用户token
     *
     * @returns
     */
    getServiceUserToken() {
        return request("api/Index/getServiceUserToken");
    },
    //获取配置信息
    getConfig(data) {
        return request("api/Config/getConfig", data);
    },
    /**
     * 获取拍卖的商品
     *
     * @returns
     */
    getShopGoodsAllList(data) {
        return request("api/Shops/getShopGoodsAllList", data);
    },

    // -----商家接口
    /**
     *  商家基本信息接口
     *
     * @returns
     */
    getUserSellerInfo(data) {
        return request("shopapi/Shops/getShopDataProfile", data);
    },
    /**
     * 商家登录
     *
     * @param {any} data
     * @returns
     */
    getPwdSellerLogin(data) {
        return request("shopapi/Login/login", data);
    },
    /**
     * 获取卖家店铺详细接口
     *
     * @param {any} data
     * @returns
     */
    getShopInfo(data) {
        return request("shopapi/Shops/getShopInfo", data);
    },
    /**
     * 商家 获取成功的订单  获取失败的订单
     *
     * @returns
     */
    getShopperOrderList(data) {
        return request("shopapi/Order/getOrderList", data);
    },
    /**
     * 修改商家信息
     *
     * @returns
     */
    getShopsSetShopsInfo(data) {
        return request("shopapi/Shops/setShopsInfo", data);
    },
    /**
     * 获取店铺登录信息
     *
     * @returns
     */
    getShopAdminInfo(data) {
        return request("shopapi/ShopAdmin/getShopAdminInfo", data);
    },
    /**
     * 获取登录密码
     *
     * @returns
     */
    getEditShopAccount(data) {
        return request("shopapi/ShopAdmin/editShopAccount", data);
    },
    /**
     * 增加登录密码
     *
     * @returns
     */
    getAddpaypwd(data) {
        return request("shopapi/ShopAdmin/addpaypwd", data);
    },
    /**
     * 获取账户明细
     *
     * @returns
     */
    getShopWalletIndex(data) {
        return request("shopapi/ShopTxmx/getShopWalletIndex", data);
    },
    /**
     * 获取账户明细
     *
     * @returns
     */
    getShopDetailList(data) {
        return request("shopapi/ShopDetail/getShopDetailList", data);
    },
    /**
     * 获取账户明细
     *
     * @returns
     */
    getApplyShopTixian(data) {
        return request("shopapi/ShopTxmx/applyShopTixian", data);
    },
    /**
     * 增加修改钱包地址
     *
     * @returns
     */
    getSetShopBankCardInfo(data) {
        return request("shopapi/ShopBankcard/setShopBankCardInfo", data);
    },
    /**
     * 获取店铺提现账户信息
     *
     * @returns
     */
    getShopBankCardInfo(data) {
        return request("shopapi/ShopBankcard/getShopBankCardInfo", data);
    },
    /**
     * 我的委托(分销)
     *
     * @returns
     */
    getDistribCenter(data) {
        return request("api/DistributionUser/distribCenter", data);
    },
    /**
     * 获取订单销量图表
     *
     * @returns
     */
    getChartsDataColumn1(data) {
        return request("shopapi/StatisticsIndex/chartsDataColumn1", data);
    },
    /**
     * 获取订单列表
     *
     * @returns
     */
    getOrderList(data) {
        return request("shopapi/Order/getOrderList", data);
    },
    /**
     * 获取买家店铺详细接口
     *
     * @param {any} data
     * @returns
     */
    getBuyerShopInfo(data) {
        return request("api/Shops/getShopInfo", data);
    },
    /**
     * 获取订单详情
     *
     * @returns
     */
    getSellerOrderInfo(data) {
        return request("shopapi/Order/getOrderInfo", data);
    },
    /**
     * 获取商品评价列表
     *
     * @param {any} data
     * @returns
     */
    getCommentList(data) {
        return request("shopapi/Comment/getCommentList", data);
    },
    /**
     * 获取数据概况
     *
     * @param {any} data
     * @returns
     */
    getShopDataProfile(data) {
        return request("shopapi/Shops/getShopDataProfile", data);
    },
    /**
     * 获取我的商品
     *
     * @param {any} data
     * @returns
     */
    getSellerGoodsList(data) {
        return request("shopapi/Goods/getGoodsList", data);
    },
    /**
     * 商品上下架
     *
     * @param {any} data
     * @returns
     */
    onSale(data) {
        return request("shopapi/Goods/getByIdSale", data);
    },
    /**
     * 商品放入回收站
     *
     * @param {any} data
     * @returns
     */
    deleteGoods(data) {
        return request("shopapi/Goods/getByIdRecycle", data);
    },
    /**
     * 平台商品分类接口
     *
     * @param {any} data
     * @returns
     */
    getCategory(data) {
        return request("api/Category/index", data);
    },
    /**
     * 通过顶级分类id获取子类接口
     *
     * @param {any} data
     * @returns
     */
    getCategoryList(data) {
        return request("api/Category/getchild", data);
    },
    /**
     * 获取铺货的商品
     */
    puhuoGoodsLst(data) {
        return request("shopapi/PuhuoGoods/puhuoGoodsLst", data);
    },
    /**
     * 一键铺货
     */
    copyGoods(data) {
        return request("shopapi/PuhuoGoods/copyGoods", data);
    },
    /**
     * 已支付订单商家申请发货
     *
     * @param {any} data
     * @returns
     */
    applyGoods(data) {
        return request("shopapi/Order/applyGoods", data);
    },
    // 确定打款 退款
    payment(data) {
        return request("shopapi/Order/payment", data);
    },
    //获取直通车流量卡列表
    getThroughList(data) {
        return request("shopapi/ShopPlugin/getThroughList", data);
    },
    //根据id购买流量卡
    postIdThrough(data) {
        return request("shopapi/ShopPlugin/postIdThrough", data);
    },
    //直通车获取订单列表
    getThroughOrderList(data) {
        return request("shopapi/ShopPlugin/getThroughOrderList", data);
    },
    /**
     * 等级说明完整
     * @param {any} data
     * @returns
     */
    getShoplevelAll(data) {
        return request("shopapi/Shoplevel/getShoplevelAll", data);
    },
    /**
     * 提交等级
     *
     * @param {any} data
     * @returns
     */
    getShoplevelSubmitcheck(data) {
        return request("shopapi/Shoplevel/getShoplevelSubmitcheck", data);
    },
    /**
     * 账户充值
     *
     * @param {any} data
     * @returns
     */
    getShopAccountRecharge(data) {
        return request("shopapi/ShopRecharge/shopAccountRecharge", data);
    },
    //直通车店铺刷新
    refreshShop(data) {
        return request("shopapi/Shops/refreshShop", data);
    },
    /**
     * 消息
     *
     * @returns
     */
    getnotificationList(data) {
        return request("api/Notification/notificationList", data);
    },
    /**
     * 消息详情
     *
     * @returns
     */
    getNotificationInfo(data) {
        return request("api/Notification/notificationInfo", data);
    },
    /**
     * 获取聊天记录
     */
    getChatMsgList(data) {
        return request("api/ChatMessage/chatlist", data);
    },

    /**
     * 获取邀请链接
     */
    getShareData(data) {
        return request("api/Share/shareData", data);
    },
    /**
     * 获取分销团队
     */
    getDiffLevelUserList(data) {
        return request("api/DistributionUser/getDiffLevelUserList", data);
    },

    // /**
    //  * 买家获取银行卡信息接口
    //  *
    //  * @param {any} data
    //  * @returns
    //  */
    // getShopBankCardinfo(data) {
    //     return request("api/BankCard/index", data);
    // },
    // /**
    //  * 添加银行卡接口
    //  *
    //  * @param {any} data
    //  * @returns
    //  */
    /**
     * 店铺上架
     *
     * @param {any} data
     * @returns
     */
    upShopGoods(data) {
        return request("api/Shops/up", data);
    },
    /**
     * 店铺下架
     *
     * @param {any} data
     * @returns
     */
    downShopGoods(data) {
        return request("api/Shops/down", data);
    },
    /**
     * 店铺商品置顶
     *
     * @param {any} data
     * @returns
     */
    topShopGoods(data) {
        return request("api/Shops/settop", data);
    },
    /**
     * 钱包账变记录
     *
     * @param {any} data
     * @returns
     */
    getzbList(data) {
        return request("api/Recharge/getzbList", data);
    },
    /**
     * 获取Trc
     *
     * @param {any} data
     * @returns
     */
    getusdttrcinfo(data) {
        return request("api/BankCard/usdttrcindex", data);
    },
    /**
     * 获取Erc
     *
     * @param {any} data
     * @returns
     */
    getusdtercinfo(data) {
        return request("api/BankCard/usdtercindex", data);
    },
    /**
     * 获取Card
     *
     * @param {any} data
     * @returns
     */
    getBankCardinfo(data) {
        return request("api/BankCard/index", data);
    },
    /**
     * 解绑银行卡接口
     *
     * @param {any} data
     * @returns
     */
    getdeletecard(data) {
        return request("api/BankCard/deletecard", data);
    },
    /**
     * 解绑USDT
     *
     * @param {any} data
     * @returns
     */
    getdeleteusdt(data) {
        return request("api/BankCard/deleteusdt", data);
    },
    /**
     * 添加usdt   dqtype:1 Trc    dqtype:2 Erc
     *
     * @param {any} data
     * @returns
     */
    usdtAdd(data) {
        return request("api/BankCard/usdtadd", data);
    },
    /**
     * 添加银行卡接口
     *
     * @param {any} data
     * @returns
     */
    getBankCardAdd(data) {
        return request("api/BankCard/add", data);
    },
    /**
     * 获取商家订单
     *
     * @returns
     */
    getShopperList(data) {
        return request("api/Merchant/orderList", data);
    },
    /**
     * 商家 购物车订单详情
     *
     * @returns
     */
    getRoleOrderInfo(data) {
        return request("api/Merchant/orderInfo", data);
    },
    /**
     * 商家 提交发货
     *
     * @returns
     */
    fahuoapi(data) {
        return request("api/Order/fahuoapi", data);
    },
    /**
     * 商家 获取等级信息
     *
     * @returns
     */
    getLevel(data) {
        return request("api/Shops/getLevel", data);
    },

}
