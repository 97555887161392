<template>
    <div class="overlay" @click.stop="handleClickheader(1)"
        :class="[!$store.state.isShowModalLogin ? 'close-overlay' : '']">
        <el-dialog title="" :show-close="false" :visible.sync="$store.state.isShowModalLogin" width="30%" :modal="true"
            :modal-append-to-body="false" :close-on-click-modal="false" :before-close="handleClose">
            <template #title>
                <div class="close-svg" @click="handleCloseDialog" v-if="!showSeller">
                    <SvgIcon iconClass="Delete" color="#333" width="15" height="15" />
                </div>
            </template>
            <template #default>
                <div class="dialog_login">
                    <!-- 登录 -->
                    <div class="left" v-show="isShowLogin">
                        <div class="top">
                            <div class="imgContainer" style="height: 130px; width: 130px; border-radius: 0px;">
                                <div class="el-image" style="width: 100%; height: 100%; border-radius: 0px;">
                                    <img :src="require('@/assets/images/logo.jpg')" class="el-image__inner"
                                        style="object-fit:contain">
                                </div>
                            </div>
                        </div>
                        <div class="inputItem" :class="[isShowPhoneLogin ? 'lin' : '']" style="overflow:scroll;">
                            <input type="text" :placeholder="$t('login.t3')" v-model="form.email_phone"
                                v-show="!isShowPhoneLogin">
                            <selects :flag="true" :isShowdown="isShowdownpPhone" @clicks="handleClicklang(2)"
                                v-show="isShowPhoneLogin"></selects>
                            <input class="phoneipt" type="text" :placeholder="$t('login.t4')" v-model="form.email_phone"
                                v-show="isShowPhoneLogin">
                        </div>
                        <div class="inputItem">
                            <input :type="isChangePWD ? 'text' : 'password'" :placeholder="$t('login.t5')"
                                v-model="form.pwd">
                            <div class="s" @click="handleClickChangeipt">
                                <SvgIcon :iconClass="isChangePWD ? 'closeEye' : 'openEye'" color="#333" width="25"
                                    height="25" />
                            </div>
                        </div>
                        <div class="forget" v-show="!isShowRegister && !showSeller">
                            <div @click="isShowforget_password = true, isShowLogin = false">{{ $t('login.t6') }}？</div>
                            <div @click="isShowRegister = true, isShowLogin = false">{{ $t('login.t7') }}</div>
                        </div>
                        <div class="forget" v-if="showSeller">
                            <div @click="isShowforget_password = true, isShowLogin = false">{{ $t('login.t6') }}？</div>
                        </div>
                        <button @click="throttle(handleLogin)" v-loading="Loading">{{ $t('login.t23') }}</button>
                        <div class="bottoms">
                            <div class="lefts"></div>
                            <div class="rights">
                                <span>{{ $t('login.t10') }}</span>
                            </div>
                        </div>
                    </div>
                    <!-- 注册 -->
                    <div class="register" v-show="isShowRegister">
                        <div class="top">
                            <div class="imgContainer" style="height: 130px; width: 130px; border-radius: 0px;">
                                <div class="el-image" style="width: 100%; height: 100%; border-radius: 0px;">
                                    <img :src="require('@/assets/images/logo.jpg')" class="el-image__inner"
                                        style="object-fit:contain">
                                </div>
                            </div>
                        </div>
                        <div class="topIcon" @click="isShowRegister = false, isShowLogin = true">
                            <SvgIcon iconClass="rollLeft" color="#333" width="1em" height="1em" />
                        </div>
                        <div class="form">
                            <div class="inputItem" :class="[!isShowPhoneRegister_btn ? 'lin' : '']">
                                <selects :flag="true" :isShowdown="isShowPhoneRegister" @clicks="handleClicklang(3)"
                                    v-show="!isShowPhoneRegister_btn"></selects>
                                <input type="text" :placeholder="$t('login.t12')" v-model="RegisterForm.email_phone"
                                    v-show="!isShowPhoneRegister_btn">
                                <input type="text" :placeholder="$t('login.t13')" v-model="RegisterForm.email_phone"
                                    v-show="isShowPhoneRegister_btn">
                                <!-- <div class="btnschange" @click="handleClickRegisteris_e_p">{{
                                    isShowPhoneRegister_btn
                                    ? $t('login.t14') : $t('login.t15') }}</div> -->
                            </div>
                            <div class="inputItem" v-show="isShowPhoneRegister_btn">
                                <input type="text" :placeholder="$t('login.t16')" v-model="RegisterForm.code">
                                <div style="color: #000;;" v-if="showTime">{{ time }}s{{ $t('login.t17') }}</div>
                                <div style="margin-right: 10px;color:red;cursor: pointer;" @click="getCode" v-else>
                                    {{ $t('login.t18') }}
                                </div>
                            </div>
                            <div class="inputItem">
                                <input :type="isChangeRegisterPWD1 ? 'text' : 'password'" :placeholder="$t('login.t5')"
                                    v-model="RegisterForm.pwd">
                                <div class="s" @click="handleClickChangeRegisteript(1)">
                                    <SvgIcon :iconClass="isChangeRegisterPWD1 ? 'closeEye' : 'openEye'" color="#333"
                                        width="25" height="25" />
                                </div>
                            </div>
                            <div class="inputItem">
                                <input :type="isChangeRegisterPWD2 ? 'text' : 'password'" :placeholder="$t('login.t21')"
                                    v-model="RegisterForm.newpwd">
                                <div class="s" @click="handleClickChangeRegisteript(2)">
                                    <SvgIcon :iconClass="isChangeRegisterPWD2 ? 'closeEye' : 'openEye'" color="#333"
                                        width="25" height="25" />
                                </div>
                            </div>
                            <div class="btn-register" @click=throttle(handleRegister) v-loading="Loading">
                                {{ $t('login.t7') }}
                            </div>
                            <div class="bottoms">
                                <div class="lefts"></div>
                                <div class="rights">{{ $t('login.t10') }}</div>
                            </div>
                        </div>
                    </div>
                    <!-- 忘记密码 -->
                    <div class="forget_password" v-show="isShowforget_password">
                        <div class="top">
                            <div class="imgContainer" style="height: 130px; width: 130px; border-radius: 0px;">
                                <div class="el-image" style="width: 100%; height: 100%; border-radius: 0px;">
                                    <img src="https://ntbuy.life/buyer/file/getFile?fileId=8249156" class="el-image__inner">
                                </div>
                            </div>
                        </div>
                        <div class="topIcon" @click="isShowforget_password = false, isShowLogin = true">
                            <SvgIcon iconClass="rollLeft" color="#333" width="1em" height="1em" />
                        </div>
                        <div class="form">
                            <div class="inputItem" :class="[!isShowPhoneforget_password_btn ? 'lin' : '']">
                                <!-- <selects :flag="true" :isShowdown="isShowPhoneforget_password"
                                    @clicks="handleClicklang(4)" v-show="!isShowPhoneforget_password_btn"></selects> -->
                                <input type="text" :placeholder="$t('login.t12')" v-model="forget_passwordForm.email_phone"
                                    v-show="!isShowPhoneforget_password_btn">
                                <input type="text" :placeholder="$t('login.t13')" v-model="forget_passwordForm.email_phone"
                                    v-show="isShowPhoneforget_password_btn">
                                <!-- <div class="btnschange" @click="handleClickForget_passwordis_e_p">
                                    {{
                                        isShowPhoneforget_password_btn
                                        ? $t('login.t19') : $t('login.t20') }}</div> -->
                            </div>
                            <div class="inputItem">
                                <input :type="isChangeForget_passwordPWD1 ? 'text' : 'password'"
                                    :placeholder="$t('login.t5')" v-model="forget_passwordForm.pwd">
                                <div class="s" @click="handleClickChangeForget_passwordipt(1)">
                                    <SvgIcon :iconClass="isChangeForget_passwordPWD1 ? 'closeEye' : 'openEye'" color="#333"
                                        width="25" height="25" />
                                </div>
                            </div>
                            <div class="inputItem">
                                <input :type="isChangeForget_passwordPWD2 ? 'text' : 'password'"
                                    :placeholder="$t('login.t21')" v-model="forget_passwordForm.newpwd">
                                <div class="s" @click="handleClickChangeForget_passwordipt(2)">
                                    <SvgIcon :iconClass="isChangeForget_passwordPWD2 ? 'closeEye' : 'openEye'" color="#333"
                                        width="25" height="25" />
                                </div>
                            </div>
                            <div class="btn-register" @click="throttle(hanldeForgetPwd)">
                                {{ $t('login.t22') }}
                            </div>
                            <div class="bottoms">
                                <div class="lefts"></div>
                                <div class="rights">{{ $t('login.t10') }}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </template>
        </el-dialog>
    </div>
</template>
<script>
import { mapMutations, mapState } from 'vuex';
import selects from "./select.vue";
import { throttle } from "@/utils";
export default {
    name: 'dialogLogin',
    props: {
        showSeller: {
            type: Boolean,
        },
    },
    components: {
        selects
    },
    data() {
        return {
            isShowLogin: true,
            isChangePWD: false,
            isShowdown1: false,
            isShowPhoneLogin: true, // 是否显示电话登录
            isShowdownpPhone: false, // 是否显示电话的icon
            form: {
                email_phone: '',
                pwd: '',
                phone: ''
            },
            devicetoken: '',
            isShowRegister: false,
            isShowPhoneRegister: false,
            isShowPhoneRegister_btn: false,
            RegisterForm: {
                email_phone: '',
                pwd: '',
                newpwd: '',
                phone: '',
                code: ''
            },
            isChangeRegisterPWD1: false,
            isChangeRegisterPWD2: false,
            isShowforget_password: false,
            forget_passwordForm: {
                email_phone: '',
                code: '',
                pwd: '',
                newpwd: '',
            },
            isShowPhoneforget_password: false,
            isShowPhoneforget_password_btn: false,
            isChangeForget_passwordPWD1: false,
            isChangeForget_passwordPWD2: false,
            isChangePhone_email: 0, //1是手机，2是邮箱
            isClick: true, //避免用户重复点击
            time: 60, //60s后重置
            showTime: false, //时间与获取验证码切换
            Loading: false,
        }
    },
    created() {
        this.$store.commit('SET_MODAL_LOGIN', false)
    },
    computed: {
        ...mapState(['langlist', 'phonelist', 'qh', 'isLoading'])
    },
    methods: {
        ...mapMutations(['SET_LANG_ACTIVE', 'SET_BUY_SELLER']),
        throttle,
        handleClicklang(v) {
            // title右侧
            if (v === 1) {
                this.isShowdown1 = !this.isShowdown1
                this.isShowdownpPhone = false
                this.isShowPhoneRegister = false
                this.isShowPhoneforget_password = false
            } else if (v === 2) {
                // login
                this.isShowdownpPhone = !this.isShowdownpPhone
                this.isShowdown1 = false
            } else if (v === 3) {
                // 注册
                this.isShowPhoneRegister = !this.isShowPhoneRegister
                this.isShowdown1 = false
            } else if (v === 4) {
                // 忘记密码
                this.isShowPhoneforget_password = !this.isShowPhoneforget_password
                this.isShowdown1 = false
            }
            else {
                if (this.isShowdown) {
                    this.$store.commit('SET_LANG_ACTIVE', false)
                } else {
                    this.$store.commit('SET_LANG_ACTIVE', true)
                }
            }
        },
        handleClickheader(v) {
            if (v === 1 || this.isShowPhoneLogin) {
                this.isShowdown1 = false
            } else {
                this.$store.commit('SET_LANG_ACTIVE', false)
            }
            if (this.isShowPhoneLogin) {
                this.isShowdownpPhone = false
            }
            if (this.isShowPhoneRegister) {
                this.isShowPhoneRegister = false
            }
            if (this.isShowPhoneforget_password) {
                this.isShowPhoneforget_password = false
            }
        },
        // 点击关闭dialog
        handleClose() {
            this.$emit('close', false)
        },
        handleClickChangeipt() {
            this.isChangePWD = !this.isChangePWD
        },
        // 右上角x关闭
        handleCloseDialog() {
            this.$emit('close', false)
        },
        // 登录
        async handleLogin() {
            if (this.form.email_phone === '') {
                return this.$Message.warning(this.$t('login.t24'))
            }
            if (this.form.pwd === '') {
                return this.$Message.warning(this.$t('login.t25'))
            }
            if (this.isShowPhoneLogin) {
                this.Loading = true
                // 电话
                await this.$http.getPwdLogin({
                    phone: this.form.email_phone,
                    password: this.form.pwd,
                    devicetoken: this.devicetoken,
                    country_code: this.qh
                }).then(async (res) => {
                    if (res.status === 200) {
                        this.Loading = false
                        this.form = {}
                        this.$Message.success(res.mess)
                        localStorage.setItem('phone', this.form.email_phone);
                        this.$store.commit('loginStatus', true)
                        localStorage.setItem('token', res.data.token);
                        localStorage.setItem('identity', res.data.identity);
                        localStorage.setItem('role', res.data.role);
                        localStorage.setItem('role_id', res.data.shop_id);
                        localStorage.setItem('pid', res.data.shop_id);
                        localStorage.setItem('serviceShopId', res.data.serviceShopId);
                        // localStorage.setItem('locale', this.$i18n.locale)
                        // localStorage.setItem('UNI_LOCALE', this.$i18n.locale)
                        this.$emit('close', false)
                        // 获取用户信息
                        await this.$http.getUserInfo().then(ret => {
                            let userInfo = ret.data;
                            let users = JSON.stringify(userInfo)
                            // 存值
                            this.$store.commit('setUserInfo', userInfo);
                            localStorage.setItem('userInfo', users)
                        })
                        location.reload();
                    } else {
                        this.Loading = false
                        this.$message.error(res.mess);
                    }
                })
            } else {
                await this.$http.getPwdLogin({
                    email: this.form.email_phone,
                    password: this.form.pwd,
                    devicetoken: this.devicetoken,
                }).then(async (res) => {
                    if (res.status === 200) {
                        this.form = {}
                        this.$Message.success(res.mess)
                        localStorage.setItem('phone', this.form.email_phone);
                        this.$store.commit('loginStatus', true)
                        localStorage.setItem('token', res.data.token);
                        localStorage.setItem('identity', res.data.identity);
                        localStorage.setItem('role', res.data.role);
                        localStorage.setItem('role_id', res.data.shop_id);
                        localStorage.setItem('pid', res.data.shop_id);
                        localStorage.setItem('serviceShopId', res.data.serviceShopId);
                        // localStorage.setItem('locale', this.$i18n.locale)
                        // localStorage.setItem('UNI_LOCALE', this.$i18n.locale)
                        this.$emit('close', false)
                        // 获取用户信息
                        await this.$http.getUserInfo().then(ret => {
                            let userInfo = ret.data;
                            let users = JSON.stringify(userInfo)
                            // 存值
                            this.$store.commit('setUserInfo', userInfo);
                            localStorage.setItem('userInfo', users)
                        })
                        this.$emit('close', false)
                        location.reload();
                    } else {
                        this.$message.error(res.mess);
                    }
                })

            }
        },
        handleClickChangeRegisteript(v) {
            if (v === 1) {
                this.isChangeRegisterPWD1 = !this.isChangeRegisterPWD1
            } else {
                this.isChangeRegisterPWD2 = !this.isChangeRegisterPWD2
            }
        },
        handleClickChangeForget_passwordipt(v) {
            if (v === 1) {
                this.isChangeForget_passwordPWD1 = !this.isChangeForget_passwordPWD1
            } else {
                this.isChangeForget_passwordPWD2 = !this.isChangeForget_passwordPWD2
            }
        },
        handleClickShowe_p_login() {
            this.isShowPhoneLogin = !this.isShowPhoneLogin
            this.form = {
                email_phone: '',
                pwd: '',
                phone: ''
            }
        },
        handleClickRegisteris_e_p() {
            this.isShowPhoneRegister_btn = !this.isShowPhoneRegister_btn
            this.RegisterForm = {
                email_phone: '',
                pwd: '',
                newpwd: '',
                phone: '',
                code: ''
            },
                this.isShowPhoneRegister = false
        },
        handleClickForget_passwordis_e_p() {
            this.isShowPhoneforget_password_btn = !this.isShowPhoneforget_password_btn
            this.forget_passwordForm = {
                email_phone: '',
                pwd: '',
                newpwd: '',
                phone: '',
            },
                this.isShowPhoneforget_password = false
        },
        // 注册
        handleRegister() {
            if (this.RegisterForm.email_phone === '') {
                return this.$Message.warning(this.$t('login.t24'))
            }
            if (this.isShowPhoneRegister_btn) {
                if (this.RegisterForm.code === '') {
                    return this.$Message.warning(this.$t('login.t26'))
                }
                if (this.RegisterForm.pwd === '') {
                    return this.$Message.warning(this.$t('login.t25'))
                }
                if (this.RegisterForm.pwd) {
                    if (this.RegisterForm.pwd.length < 6 || this.RegisterForm.pwd.length > 10) {
                        return this.$Message.warning(this.$t('login.t27'))
                    }
                    if (this.RegisterForm.newpwd !== this.RegisterForm.pwd) {
                        return this.$Message.warning(this.$t('login.t28'))
                    }
                }
                // 邮箱
                this.$http.getRegister({
                    email: this.RegisterForm.email_phone,
                    invite: '',
                    password: this.RegisterForm.pwd,
                    repwd: this.RegisterForm.pwd,
                    xieyi: 1,
                    type: 0,
                }).then((res) => {
                    if (res.status == 200) {
                        this.$Message.success(res.mess);
                        this.isShowRegister = false
                        this.isShowLogin = true
                    }
                }).catch((err) => {
                    this.$Message.error(err.mess)
                })
            } else {
                if (this.RegisterForm.pwd === '') {
                    return this.$Message.warning(this.$t('login.t25'))
                }
                if (this.RegisterForm.pwd.length < 6 || this.RegisterForm.pwd.length > 10) {
                    return this.$Message.warning(this.$t('login.t27'))
                }
                if (this.RegisterForm.newpwd !== this.RegisterForm.pwd) {
                    return this.$Message.warning(this.$t('login.t28'))
                }
                this.Loading = true
                this.$http.getRegister({
                    phone: this.RegisterForm.email_phone,
                    invite: '',
                    password: this.RegisterForm.pwd,
                    repwd: this.RegisterForm.pwd,
                    country_code: this.qh,
                    xieyi: 1,
                    type: 0,
                }).then((res) => {
                    if (res.status == 200) {
                        this.Loading = false
                        this.$Message.success(res.mess);
                        this.isShowRegister = false
                        this.isShowLogin = true
                        this.RegisterForm.email_phone = ''
                        this.RegisterForm.newpwd = ''
                        this.RegisterForm.pwd = ''
                    } else {
                        this.Loading = false
                        this.$Message.error(res.mess)
                    }
                }).catch((err) => {
                    this.Loading = false
                    this.$Message.error(err.mess)
                })
            }


        },
        // 忘记密码
        hanldeForgetPwd() {
            if (this.forget_passwordForm.email_phone === '') {
                return this.$Message.warning(this.$t('login.t24'))
            }
            if (this.forget_passwordForm.pwd === '') {
                return this.$Message.warning(this.$t('login.t25'))
            }
            if (this.forget_passwordForm.pwd.length < 6 || this.forget_passwordForm.pwd.length > 10) {
                return this.$Message.warning(this.$t('login.t27'))
            }
            if (this.forget_passwordForm.newpwd !== this.forget_passwordForm.pwd) {
                return this.$Message.warning(this.$t('login.t28'))
            }
            if (!this.isShowPhoneforget_password_btn) {
                // 手机
                this.Loading = true
                this.isChangePhone_email = 1
                this.$http.findBackPwd({
                    phone: this.forget_passwordForm.email_phone,
                    phonecode: '',
                    password: this.forget_passwordForm.pwd,
                    showIndex: this.isChangePhone_email,
                }).then(res => {
                    if (res.status == 200) {
                        this.Loading = false
                        this.$Message.success(res.mess)
                        this.isShowforget_password = false
                        this.isShowLogin = true
                        this.forget_passwordForm.email_phone = ''
                        this.forget_passwordForm.newpwd = ''
                        this.forget_passwordForm.pwd = ''
                    }else{
                        this.Loading = false
                        this.$Message.error(res.mess)
                    }
                })
            } else {
                // 邮箱
                this.isChangePhone_email = 2
                this.$http.findBackPwd({
                    email: this.forget_passwordForm.email_phone,
                    phonecode: '',
                    password: this.forget_passwordForm.pwd,
                    showIndex: this.isChangePhone_email,
                }).then(res => {
                    if (res.status == 200) {
                        this.$Message.success(res.mess)
                        this.isShowforget_password = false
                        this.isShowLogin = true
                    }
                })
            }

        },
        // 获取验证码
        getCode() {
            if (this.isClick) {
                this.$http.getEmailCode({
                    email: this.RegisterForm.email_phone,
                    type: 1,
                    country_code: '',
                }).then((res) => {
                    if (res.status == 200) {
                        this.showTime = true;
                        this.$Message.success(res.mess)
                        this.timer = setInterval(() => {
                            this.isClick = false;
                            this.time = this.time - 1;
                            if (this.time <= 0) {
                                this.isClick = true;
                                this.time = 60;
                                this.showTime = false;
                                clearInterval(this.timer);
                                this.timer = null;
                            }
                        }, 1000)
                    }

                })
            }
        },
    },
}
</script>
<style lang='less' scoped>
.h120 {
    max-height: 120px !important;
}

.close-overlay {
    display: none;
}

.deg180 {
    transform: rotate(180deg) !important;
}

.overlay {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 2000;
    height: 100%;
    background-color: rgba(0, 0, 0, .5);

    ::v-deep {
        &>div:nth-child(2) {
            display: none;
        }

        .el-dialog__header {
            height: 7px;

            .close-svg {
                position: absolute;
                top: 6px;
                right: 0;
                padding: 0;
                width: 54px;
                height: 54px;
                background: 0 0;
                border: none;
                outline: 0;
                cursor: pointer;
                font-size: 26px;
                text-align: center;
                line-height: 54px;

                &:hover {
                    color: #409eff;
                }
            }
        }

        .el-dialog__wrapper {
            overflow: inherit;
        }

        .el-dialog {}

        .el-dialog__body {
            padding: 0;
        }
    }

    .dialog_login {
        padding-top: 50px;
        width: 100%;
        box-sizing: border-box;
        // display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 50px;
        position: relative;

        .left {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            flex: 1;

            .top {
                width: 100%;
                display: flex;
                justify-content: center;
                align-items: center;

                .imgContainer {
                    overflow: hidden;
                    background-color: #fff;
                    height: 130px;
                    width: 130px;
                    border-radius: 0px;

                    .el-image {
                        position: relative;
                        display: inline-block;
                        overflow: hidden;

                        .el-image__inner {
                            vertical-align: top;
                            opacity: 1;
                        }
                    }
                }
            }

            .changeButton {
                width: 100%;
                display: flex;
                justify-content: space-between;
                flex-direction: row;

                .item {
                    width: 215px;
                    height: 55px;
                    display: flex;
                    color: #000;
                    justify-content: center;
                    align-items: center;
                    text-align: center;
                    font-family: Inter;
                    font-size: 18px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: normal;
                    border: 1px solid #222;
                    margin-top: 36px;
                }

                .active_deal {
                    background-color: #222;
                    color: #fff !important;
                }
            }

            .inputItem {
                justify-content: flex-start;
                align-items: center;
                margin-top: 20px;
                width: 100%;
                height: 48px;
                display: flex;
                position: relative;
                border: 1px solid #e9e9e9;

                .containerCon {
                    min-width: 60px;
                    // margin-left: 20px;
                    height: 100%;
                    position: relative;
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    .active {
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        width: 100%;
                        cursor: pointer;
                        height: 25px;

                        .left {
                            display: flex;
                            justify-content: flex-start;
                            align-items: center;
                            margin-right: 4px;
                            flex-direction: row;
                            flex: none;

                            img {
                                height: 18px;
                                width: 24px;
                                border-radius: 5px;
                            }

                            .title {
                                margin-left: 4px;
                                white-space: nowrap;
                                text-align: right;
                                font-family: Inter;
                                font-size: 18px;
                                font-style: normal;
                                font-weight: 500;
                                line-height: normal;
                                text-transform: none;
                                color: #000c22;
                            }
                        }

                        .right {
                            width: 8px;
                            height: 16.5px;
                            padding: 0;

                            .svg-icon {
                                position: static;
                            }
                        }
                    }

                    .options {
                        max-height: 0px;
                        width: 112px !important;
                        transition: all .2s ease;
                        position: absolute;
                        z-index: 99999;
                        top: 102%;
                        // right: 0;
                        width: fit-content;
                        min-width: 100px;
                        height: fit-content;
                        overflow: auto;
                        background-color: #fff;
                        box-sizing: border-box;

                        .item {
                            width: 112px;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            height: 40px;
                            padding: 0 4px;
                            box-sizing: border-box;

                            .info {
                                display: flex;
                                flex-direction: row;
                                justify-content: center;
                                align-items: center;
                                width: 100%;

                                div {
                                    display: flex;
                                    flex-direction: row;
                                    align-items: center;
                                    justify-content: center;

                                    img {
                                        width: 24px;
                                        height: 18px;
                                        border-radius: 5px;
                                    }

                                    span {
                                        margin-left: 14px;
                                    }
                                }
                            }
                        }
                    }
                }
            }

            .phoneipt {
                flex: 1;
                padding-left: 10px;
                font-size: 18px;
            }

            input {
                border: 0;
                // border: 1px solid #e9e9e9;
                // height: 100%;
                flex: 1;
                padding-left: 10px;
                font-size: 18px;
                outline: none;
            }

            .s {
                width: 25px;
                height: 25px;
                position: absolute;
                right: 15px;
                top: 50%;
                z-index: 9999;
                transform: translateY(-50%);
            }

            .svg-icon {
                position: absolute;
                right: 0px;
                top: 50%;
                z-index: 9999;
                transform: translateY(-50%);
            }
        }

        .register {
            // height: 634px;

            .top {
                width: 100%;
                display: flex;
                justify-content: center;
                align-items: center;

                .imgContainer {
                    overflow: hidden;
                    background-color: #fff;
                    height: 130px;
                    width: 130px;
                    border-radius: 0px;

                    .el-image {
                        position: relative;
                        display: inline-block;
                        overflow: hidden;

                        .el-image__inner {
                            vertical-align: top;
                            opacity: 1;
                        }
                    }
                }
            }

            .topIcon {
                width: 40px;
                margin-top: 0;
                color: #222;
                display: flex;
                align-items: center;
                font-family: Poppins;
                font-size: 18px;
                font-style: normal;
                font-weight: 700;
                line-height: 22px;

                &>svg {
                    font-size: 40px;
                }
            }

            .form {
                .inputItem {
                    justify-content: flex-start;
                    align-items: center;
                    margin-top: 20px;
                    width: 100%;
                    height: 48px;
                    display: flex;
                    position: relative;
                    border: 1px solid #e9e9e9;

                    .containerCon {
                        min-width: 60px;
                        margin-left: 20px;
                        height: 100%;
                        position: relative;
                        display: flex;
                        justify-content: center;
                        align-items: center;

                        .active {
                            display: flex;
                            flex-direction: row;
                            align-items: center;
                            width: 100%;
                            cursor: pointer;
                            height: 25px;

                            .left {
                                display: flex;
                                justify-content: flex-start;
                                align-items: center;
                                margin-right: 4px;
                                flex-direction: row;
                                flex: none;

                                img {
                                    height: 18px;
                                    width: 24px;
                                    border-radius: 5px;
                                }

                                .title {
                                    margin-left: 4px;
                                    white-space: nowrap;
                                    text-align: right;
                                    font-family: Inter;
                                    font-size: 18px;
                                    font-style: normal;
                                    font-weight: 500;
                                    line-height: normal;
                                    text-transform: none;
                                    color: #000c22;
                                }
                            }

                            .right {
                                width: 8px;
                                height: 16.5px;
                                padding: 0;

                                .svg-icon {
                                    position: static;
                                }
                            }
                        }

                        .options {
                            max-height: 0px;
                            width: 112px !important;
                            transition: all .2s ease;
                            position: absolute;
                            z-index: 99999;
                            top: 102%;
                            right: 0;
                            width: fit-content;
                            min-width: 100px;
                            height: fit-content;
                            overflow: auto;
                            background-color: #fff;
                            box-sizing: border-box;

                            .item {
                                width: 112px;
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                height: 40px;
                                padding: 0 4px;
                                box-sizing: border-box;

                                .info {
                                    display: flex;
                                    flex-direction: row;
                                    justify-content: center;
                                    align-items: center;
                                    width: 100%;

                                    div {
                                        display: flex;
                                        flex-direction: row;
                                        align-items: center;
                                        justify-content: center;

                                        img {
                                            width: 24px;
                                            height: 18px;
                                            border-radius: 5px;
                                        }

                                        span {
                                            margin-left: 14px;
                                        }
                                    }
                                }
                            }
                        }
                    }

                    .btnschange {
                        width: 106px;
                        height: 48px;
                        line-height: 48px;
                        padding: 0 5px;
                        box-sizing: border-box;
                        color: #fff;
                        text-align: center;
                        font-family: Poppins;
                        font-size: 16px;
                        font-style: normal;
                        font-weight: 500;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                        background-color: #222;
                        cursor: pointer;
                    }
                }

                input {
                    border: 0;
                    // height: 100%;
                    flex: 1;
                    padding-left: 10px;
                    font-size: 18px;
                    outline: none;
                }

                .svg-icon {
                    position: absolute;
                    right: 0px;
                    top: 50%;
                    z-index: 9999;
                    transform: translateY(-50%);
                }

                .s {
                    width: 25px;
                    height: 25px;
                    position: absolute;
                    right: 15px;
                    top: 50%;
                    z-index: 9999;
                    transform: translateY(-50%);
                }
            }

            .btn-register {
                display: flex;
                background-color: transparent;
                justify-content: center;
                flex-direction: row;
                align-items: center;
                height: 54px;
                text-align: center;
                font-family: Inter;
                font-size: 18px;
                font-style: normal;
                font-weight: 700;
                line-height: normal;
                text-transform: none;
                border: none;
                background: rgb(34, 34, 34);
                color: rgb(255, 255, 255);
                width: 100%;
                margin-top: 60px;
            }

            .bottoms {
                width: 100%;
                margin-top: 50px;
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: space-between;

                .lefts {}

                .rights {}
            }
        }

        .forget_password {
            .top {
                width: 100%;
                display: flex;
                justify-content: center;
                align-items: center;

                .imgContainer {
                    overflow: hidden;
                    background-color: #fff;
                    height: 130px;
                    width: 130px;
                    border-radius: 0px;

                    .el-image {
                        position: relative;
                        display: inline-block;
                        overflow: hidden;

                        .el-image__inner {
                            vertical-align: top;
                            opacity: 1;
                        }
                    }
                }
            }

            .topIcon {
                width: 40px;
                margin-top: 0;
                color: #222;
                display: flex;
                align-items: center;
                font-family: Poppins;
                font-size: 18px;
                font-style: normal;
                font-weight: 700;
                line-height: 22px;

                &>svg {
                    font-size: 40px;
                }
            }

            .form {
                .inputItem {
                    justify-content: flex-start;
                    align-items: center;
                    margin-top: 20px;
                    width: 100%;
                    height: 48px;
                    display: flex;
                    position: relative;
                    border: 1px solid #e9e9e9;

                    .containerCon {
                        min-width: 60px;
                        margin-left: 20px;
                        height: 100%;
                        position: relative;
                        display: flex;
                        justify-content: center;
                        align-items: center;

                        .active {
                            display: flex;
                            flex-direction: row;
                            align-items: center;
                            width: 100%;
                            cursor: pointer;
                            height: 25px;

                            .left {
                                display: flex;
                                justify-content: flex-start;
                                align-items: center;
                                margin-right: 4px;
                                flex-direction: row;
                                flex: none;

                                img {
                                    height: 18px;
                                    width: 24px;
                                    border-radius: 5px;
                                }

                                .title {
                                    margin-left: 4px;
                                    white-space: nowrap;
                                    text-align: right;
                                    font-family: Inter;
                                    font-size: 18px;
                                    font-style: normal;
                                    font-weight: 500;
                                    line-height: normal;
                                    text-transform: none;
                                    color: #000c22;
                                }
                            }

                            .right {
                                width: 8px;
                                height: 16.5px;
                                padding: 0;

                                .svg-icon {
                                    position: static;
                                }
                            }
                        }

                        .options {
                            max-height: 0px;
                            width: 112px !important;
                            transition: all .2s ease;
                            position: absolute;
                            z-index: 99999;
                            top: 102%;
                            right: 0;
                            width: fit-content;
                            min-width: 100px;
                            height: fit-content;
                            overflow: auto;
                            background-color: #fff;
                            box-sizing: border-box;

                            .item {
                                width: 112px;
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                height: 40px;
                                padding: 0 4px;
                                box-sizing: border-box;

                                .info {
                                    display: flex;
                                    flex-direction: row;
                                    justify-content: center;
                                    align-items: center;
                                    width: 100%;

                                    div {
                                        display: flex;
                                        flex-direction: row;
                                        align-items: center;
                                        justify-content: center;

                                        img {
                                            width: 24px;
                                            height: 18px;
                                            border-radius: 5px;
                                        }

                                        span {
                                            margin-left: 14px;
                                        }
                                    }
                                }
                            }
                        }
                    }

                    .btnschange {
                        width: 106px;
                        height: 48px;
                        line-height: 48px;
                        padding: 0 5px;
                        box-sizing: border-box;
                        color: #fff;
                        text-align: center;
                        font-family: Poppins;
                        font-size: 16px;
                        font-style: normal;
                        font-weight: 500;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                        background-color: #222;
                        cursor: pointer;
                    }
                }

                input {
                    border: 0;
                    // height: 100%;
                    flex: 1;
                    padding-left: 10px;
                    font-size: 18px;
                    outline: none;
                }

                .svg-icon {
                    position: absolute;
                    right: 0px;
                    top: 50%;
                    z-index: 9999;
                    transform: translateY(-50%);
                }

                .s {
                    width: 25px;
                    height: 25px;
                    position: absolute;
                    right: 15px;
                    top: 50%;
                    z-index: 9999;
                    transform: translateY(-50%);
                }
            }

            .btn-register {
                display: flex;
                background-color: transparent;
                justify-content: center;
                flex-direction: row;
                align-items: center;
                height: 54px;
                text-align: center;
                font-family: Inter;
                font-size: 18px;
                font-style: normal;
                font-weight: 700;
                line-height: normal;
                text-transform: none;
                border: none;
                background: rgb(34, 34, 34);
                color: rgb(255, 255, 255);
                width: 100%;
                margin-top: 60px;
            }

            .bottoms {
                width: 100%;
                margin-top: 50px;
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: space-between;

                .lefts {}

                .rights {}
            }
        }

        // .lin {
        //     &::after {
        //         position: absolute;
        //         left: 100px;
        //         width: 1px;
        //         height: 20px;
        //         background-color: #ccc;
        //         content: "";
        //     }
        // }

        .forget {
            margin-top: 20px;
            width: 100%;
            height: 48px;
            text-align: right;
            cursor: pointer;
        }

        button {
            display: flex;
            cursor: pointer;
            background-color: transparent;
            justify-content: center;
            flex-direction: row;
            align-items: center;
            height: 54px;
            text-align: center;
            font-size: 18px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
            text-transform: none;
            border: none;
            background: rgb(34, 34, 34);
            color: rgb(255, 255, 255);
            width: 100%;

            &:nth-of-type(2) {
                background: transparent;
                color: rgb(0, 0, 0);
                border: 1px solid rgb(34, 34, 34);
                width: 100%;
                margin-top: 20px;
            }
        }

        .bottoms {
            width: 100%;
            margin-top: 50px;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;

            .lefts {}

            .rights {
                cursor: pointer;
            }
        }
    }

}

@media screen and (max-width: 1465px) {
    /deep/ .el-dialog {
        width: 40% !important;
    }
}

@media screen and (max-width: 1200px) {
    /deep/ .el-dialog {
        width: 50% !important;
    }
}

@media screen and (max-width: 1000px) {
    /deep/ .el-dialog {
        width: 70% !important;

        .dialog_login {
            padding: 10px 50px !important;
        }
    }
}

@media screen and (max-width: 865px) {
    /deep/ .el-dialog__wrapper {
        .el-dialog {
            width: 70% !important;

            .dialog_login {
                padding: 10px 50px !important;

                .register {
                    .form {
                        .inputItem {
                            .containerCon {
                                margin: 0 !important;
                            }
                        }
                    }
                }
            }
        }

    }
}

@media screen and (max-width: 600px) {
    /deep/ .el-dialog__wrapper {
        .el-dialog {
            width: 100% !important;
            margin: 0 !important;
            height: 100% !important;
            border-radius: 0 !important;
            overflow: auto !important;

            .el-dialog__body {
                padding: 0 !important;
                position: absolute;
                width: 100%;
                top: 15%;
            }

            .dialog_login {
                padding: 10px 50px !important;

            }
        }
    }
}

@media screen and (max-width: 390px) {
    /deep/ .el-dialog__wrapper {
        .el-dialog {
            .el-dialog__body {}

            .dialog_login {
                padding: 10px 20px !important;

                .containerCon {
                    margin: 0 !important;
                }

                .forget_password {
                    .form {
                        .inputItem {
                            .btnschange {
                                width: 85px !important;
                                font-size: 14px !important;
                            }
                        }
                    }
                }

                .register {
                    .form {
                        display: flex;
                        flex-direction: column;
                        align-items: center;

                        .inputItem {
                            width: calc(100% - 20px);

                            .containerCon {
                                margin: 0 !important;
                            }

                            input {
                                overflow: hidden;
                                flex: auto !important;
                            }

                            .btnschange {
                                width: 85px !important;
                                font-size: 14px !important;
                            }
                        }
                    }
                }
            }
        }
    }
}

/deep/ .inputItem {
    &::-webkit-scrollbar {
        display: none !important;
    }

    &::-webkit-scrollbar-thumb {
        display: none !important;
    }

    &::-webkit-scrollbar-track {
        display: none !important;
    }

    &::-webkit-scrollbar-track-piece {
        display: none !important;
    }
}
</style>
