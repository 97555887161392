// 设置点击语言时的状态
export const SET_LANG_ACTIVE = (state, step) => {
    state.isShowdown = step;
};
export const loginStatus = (state, isLogin = false) => {
    state.isLogin = isLogin
};
// 用户信息
export const setUserInfo = (state, data = {}) => {
    state.userInfo = data
};
// 分类状态
export const categoryInfo = (state, data = {}) => {
    state.categoryIndex = data
};
// 用户地址
export const setAddressList = (state, data) => {
    state.addressList = data
}
// 设置全局默认地址
export const SET_ADDRESS_INDEX = (state, data) => {
    state.curAddressIndex = data;
};
// 设置加载状态
export const SET_LOAD_STATUS = (state, status) => {
    state.isLoading = status;
};
// 添加购物车
export const ADD_SHOPPING_CART = (state, data) => {
    state.cartList = data;
};
// 购物车数量
export const SHOPPING_CART_NUM = (state, data) => {
    state.cartNum = data;
};
// 显示购物车抽屉
export const SET_SHOW_CAR = (state, data) => {
    state.isShowCardrawer = data;
};
// 判断是否是搜索状态
export const SET_SEARCH = (state, data) => {
    state.isSearch = data;
};
// 设置search的列表
export const SET_SEARCH_LIST = (state, data) => {
    state.search_list = state.search_list.concat(data);
};
// 取消搜索清空列表
export const CLEAR_DATA = (state, data) => {
    state.search_list = [];
    state.store_list = [];
};
// 设置search的值
export const SET_SEARCH_NAME = (state, data) => {
    state.search_name = data;
};
// 判断是否是商品或者店铺
export const SET_SHOP_STORE = (state, data) => {
    state.isShop_Store = data;
};
// 设置搜索店铺的值
export const SET_STORE_LIST = (state, data) => {
    state.store_list = state.store_list.concat(data);
};
// 设置搜索page
export const SET_SEARCH_PAGE = (state, data) => {
    state.search_page = data
};
// 是否显示footer
export const SET_FOOTER = (state, data) => {
    state.isShowFooter = data;
};
// 设置语言
export const SET_LANG_LIST = (state, data) => {
    state.langlist = data;
};
// 设置区号
export const SET_PHONE_LIST = (state, data) => {
    state.phonelist = data;
};
// 切换语言
export const SET_LOCAL_LANG = (state, data) => {
    state.localLang = data;
};
// 切换区号
export const SET_LOCAL_QH = (state, data) => {
    state.qh = data;
};
// 判断是否是买家或者卖家
export const SET_BUY_SELLER = (state, data) => {
    state.isShowBuy_seller = data;
};
// 判断是否显示登录框
export const SET_MODAL_LOGIN = (state, data) => {
    state.isShowModalLogin = data;
};
// 获取商家信息
export const SET_Merchant_data = (state, data) => {
    state.Merchantinformation = data;
};
// 还原申请店铺状态
// export const removeOpenAStoreType = (state) =>{
//     // state.openAStoreType = false;
// }
export const setOpenAStoreVal = (state, data) => {
    state.oenAStoreVal = data;
}
// 搜索商品历史
export const setHistoryList = (state, data) => {
    state.historyList = data;
}
// 清空店铺搜索历史
export const removeHistoryStoreList = (state, data) => {
    state.historyStoreList = [];
}
// 消息数量
export const setMsgNum = (state, data) => {
    state.msgNum = data;
}
// 打开小铃铛
export const isChatMSG = (state, data) => {
    state.isChatMSG = data;
}
// 打开联系卖家
export const isChatSeller = (state, data) => {
    state.isChatSeller = data;
}
// 系统客服消息
export const isServiceUser = (state, data) => {
    state.serviceUser = data;
}
export const setuserTit = (state, data) => {
    state.userTit = data;
}
