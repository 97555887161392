<template>
  <div class='information'>
    <el-drawer title="我是标题" :visible.sync="drawer" :direction="direction" :before-close="handleClose"
               :wrapperClosable="false" :modal-append-to-body="false" :modal="false" :show-close="false">
      <template slot="title">
        <div class="title">
          <div class="icon" style="width: 52px;"></div>
          <div>{{ $t('information.t1') }}(0)</div>
          <div class="icon" style="margin-right: 10px;cursor: pointer;" @click="handleClose">
            <i class="el-icon-close"></i>
          </div>
        </div>
      </template>
      <div class="nodata">
        <SvgIcon iconClass="nodata" color="#333" width="80" height="80"/>
        <div class="title">
          {{ $t('information.t2') }}
        </div>
      </div>
      <div class="content"></div>
    </el-drawer>
  </div>
</template>
<script>
export default {
  data() {
    return {
      direction: 'ttb'
    }
  },
  props: {
    drawer: {
      default: false,
    }
  },
  mounted() {

  },
  computed: {},
  methods: {
    handleClose() {
      this.$emit('close', false)
    }
  },
}
</script>
<style lang='less' scoped>
::v-deep {
  .el-drawer__container {

    .el-drawer {
      width: 420px;
      left: auto;
      top: auto;
      right: 56px;
      bottom: 25px;
      height: 520px !important;
      overflow: hidden;
      box-shadow: 0 0 10px #00000040;
      background: #f6f6f6;
      border-radius: 2px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      .el-drawer__header {
        margin-bottom: 0;
        padding: 0;

        .title {
          display: flex;
          align-items: center;
          height: 72px;
          font-weight: 700;
          font-size: 16px;
          background-color: #222;
          color: #fff;
          justify-content: space-between;

          .icon {
            .el-icon-close {
              font-weight: 600;
            }
          }
        }
      }

      .el-drawer__body {
        display: flex;
        flex-direction: column;
      }

      .nodata {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        min-height: 200px;
        flex: 1;
        height: 100%;
        position: relative;
        overflow: scroll;

        .title {
          margin-top: 15px;
          color: #a4a4a4;
          text-align: center;
          font-family: Poppins;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 14px;
        }
      }

      .content {
        flex: 1;
        height: 100%;
        display: flex;
        flex-direction: column;
        position: relative;
        overflow: scroll;
      }
    }
  }
}</style>
