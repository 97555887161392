<template>
  <div class='header' @click.stop="handleClickheader">
    <div class="containerPages">
      <!-- v-if="!($store.state.userInfo.rz_zt || $store.state.userInfo.shop_id == 1)" -->
      <div class="black_bar bgc" @click="setShop"
           v-if="!($store.state.userInfo.rz_zt || $store.state.userInfo.shop_id === 1)">
        <div class="bar_container">
          <div class="wrap">{{ $t('headerNav.t2') }}</div>
          <div class="siv">{{ $t('auctionCompany.t26') }}</div>
          <SvgIcon iconClass="rollow_right" color="#333" width="16" height="16"/>
        </div>
      </div>
      <div class="white_bar">
        <div class="white_bar_container">
          <div class="top">
            <div class="logo" @click="handlecancel">
              <img :src="require('@/assets/images/logo.jpg')" alt="">
            </div>
            <div class="search-bar">
              <!-- <div class="search">
                <el-select v-model="selectValue" slot="prepend" @change="handleChangeSelect">
                  <el-option v-for="(item, index) in searchlist" :key="index" :label="item.name" :value="item.name">
                  </el-option>
                </el-select>
              </div> -->
              <div class="input">
                <el-input v-model="$store.state.search_name" @input="handlInput"></el-input>
                <div class="actionbar" :class="[$store.state.search_name ? 'w200' : '']">
                  <div class="svg" @click="handlecancel">
                    <SvgIcon iconClass="Delete" color="#333" width="15" height="15"/>
                  </div>
                  <div class="cancel" @click="handlecancel">
                    {{ $t('headerNav.t4') }}
                  </div>
                </div>
              </div>
              <div class="searchIcon" @click="throttle(handleSearch)">
                <SvgIcon iconClass="search" color="#fff" width="15" height="15"/>
              </div>
            </div>
            <div class="logo-info" v-if="isLogin">
              <div class="item" v-for="(item, index) in logoiconlist" :key="index" @click="handleClickIconInfo(index)">
                <SvgIcon :iconClass="item.iconname" color="#333" :width="item.width" :height="item.hegith"/>
                <div class="dot" v-if="item.iconname === 'noty' && msgNum > 0">{{ msgNum }}</div>
                <div class="dot" v-if="item.iconname === 'blackHeart' && blackNum > 0">{{ blackNum }}</div>
                <div class="dot" v-if="item.iconname === 'cart'">{{ cartNum }}</div>
              </div>
              <div class="drawer" @click="drawer = true">
                <i class="el-icon-s-unfold" width="26"></i>
              </div>
            </div>
            <div class="logininfo" @click="handleLoginInfo" v-else>
              <div class="isNotLogin">{{ $t('headerNav.t5') }}</div>
            </div>
          </div>
          <div class="bottom">
            <div class="category" v-if="false">
              <!-- 品牌 -->
              <div class="brand cate">
                <div class="txt">
                  {{ $t('auctionCompany.t5') }}
                </div>
                <div class="w" :class="[ishover ? 'nothover' : '']">
                  <div class="sub-menu">
                    <div class="info" v-for="(item, index) in BrandList" :key="index">
                      <div class="item" v-for="info in item" :key="info.id" @click="handleClickGetsubNav(2, info.id)">
                        <a>
                          {{ info.brand_name }}
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- 分类列表 -->
              <div class="classify cate">
                <div class="txt">
                  {{ $t('auctionCompany.t6') }}
                </div>
                <div class="w">
                  <div class="sub-menu">
                    <div class="info" v-for="(item, index) in CategoryList" :key="item.id"
                         @click="handleClickGetsubNav(1, item.id)">
                      <a>
                        {{ item.cate_name }}
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div class="Auctioncenter cate" @click="gotoAuctioncenter">
                <div class="txt">
                  {{ $t('auctionCompany.t27') }}
                </div>
              </div>
            </div>
            <div class="search-bar" v-if="false">
              <div class="input">
                <el-input v-model="$store.state.search_name" @input="handlInput"></el-input>
                <!-- <div class="actionbar" :class="[$store.state.search_name ? 'w200' : '']">
                  <div class="svg" @click="handlecancel">
                    <SvgIcon iconClass="Delete" color="#333" width="15" height="15" />
                  </div>
                  <div class="cancel" @click="handlecancel">
                    {{ $t('headerNav.t4') }}
                  </div>
                </div> -->
              </div>
              <div class="searchIcon" @click="throttle(handleSearch)">
                <SvgIcon iconClass="search" color="#fff" width="14" height="14"/>
              </div>
            </div>
            <div class="right">
              <div class="containerPage">
                <selects :flag="false" :isShowdown="isShowdown" @clicks="handleClicklang"></selects>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 登录对话框 -->
    <dialogLogin :showSeller="false" @close="handleClose"></dialogLogin>
    <!-- 768=>抽屉 -->
    <el-drawer title="" :visible.sync="drawer" :direction="direction" :modal="false" @close="drawer = false"
               custom-class="seveendrawer">
      <div class="spmenu">
        <div class="item" v-for="(item, index) in drawerList" :key="item.name" @click="gotos(item.url)">
          <div class="info" v-if="!isLogin">
            {{ item.name }}
          </div>
          <div class="info" v-if="item.url === 'home'">
            {{ item.name }}
          </div>
        </div>
        <el-collapse v-model="activeNames" @change="handleChange">
          <el-collapse-item :title="$t('auctionCompany.t5')" name="1">
            <div class="openmenu">
              <div class="sub" v-for="item in BrandList1" :key="item.id"
                   @click="handleClickGetsubNav(2, item.id, 'drawer')">
                {{ item.brand_name }}
              </div>
            </div>
          </el-collapse-item>
          <el-collapse-item :title="$t('auctionCompany.t6')" name="2">
            <div class="openmenu">
              <div class="sub" v-for="item in CategoryList" :key="item.id"
                   @click="handleClickGetsubNav(1, item.id, 'drawer')">
                {{ item.cate_name }}
              </div>
            </div>
          </el-collapse-item>
          <el-collapse-item :title="$t(item.title)" :name="index" v-for="(item, index) in userInfoList" :key="index">
            <div class="openmenu">
              <div class="sub" v-for="info in item.itemArr" :key="info.index"
                   @click="gotouser(info.path, info.title, info.index)">
                <div class="info" v-if="info.path === 'openAStore'">
                  {{
                    $t(($store.state.userInfo.rz_zt || $store.state.userInfo.shop_id === 1) ? 'consignment.t64' :
                        info.title)
                  }}
                </div>
                <div class="info" v-else>
                  {{ $t(info.title) }}
                  <span class="pay_rum" v-if="info.index===13 && userInfo.shop_pay_num">
                    {{ userInfo.shop_pay_num }}
                  </span>
                </div>
              </div>
            </div>
          </el-collapse-item>
        </el-collapse>
        <!-- <div class="item" v-for="(item, index) in userInfoList" :key="index"
          @click="gotouser(item.path, item.title, item.index)">
          <div class="info" v-if="item.path === 'openAStore'">
            {{ $t(($store.state.userInfo.rz_zt || $store.state.userInfo.shop_id == 1) ? 'auctionCompany.t9' : item.title)
            }}
          </div>
          <div class="info" v-else>
            {{ $t(item.title) }}
          </div>
        </div> -->
        <div class="logout" @click="tuichu">
          {{ $t('personalCenter.t1') }}
        </div>
      </div>
    </el-drawer>
    <!--    退出登陆-->
    <el-dialog :title="$t('user.t21')" :visible.sync="dialogVisible" width="30%" :modal="false">
      <div class="dialog_con">
        <p>{{ $t('user.t22') }}</p>
        <span slot="footer" class="dialog-footer">
          <el-button class="btn" type="primary" @click="loginOut">{{ $t('user.t23') }}</el-button>
        </span>
      </div>
    </el-dialog>
    <!-- 购物车弹窗 -->
    <AddCar></AddCar>
    <chat-item v-if="isChatMSG" :isShowChat="drawer_information" @close="handleCloseInformation"
               :isShowBell="true"></chat-item>
  </div>
</template>
<script>
import {mapMutations, mapState} from 'vuex';
import selects from "./login/select.vue";
import dialogLogin from "./login/dialogLogin.vue";
import AddCar from "./goodsDetail/AddCar.vue";
import ChatItem from "@/components/chat/chatitem.vue";
import information from "./information.vue";
import {categoryInfo} from "@/store/mutations";
import {throttle} from "@/utils";

export default {
  name: 'headerNav',
  props: {},
  components: {
    dialogLogin,
    selects,
    AddCar,
    information,
    ChatItem
  },
  data() {
    return {
      blackNum: '',
      selectValue: this.$t('headerNav.t6'),
      searchlist: [
        {
          name: this.$t('headerNav.t6')
        },
        {
          name: this.$t('headerNav.t7')
        }
      ],
      logoiconlist: [
        {
          iconname: 'noty',
          width: '20',
          hegith: '20',
        },
        // {
        //   iconname: 'blackHeart',
        //   width: '26',
        //   hegith: '26',
        // },
        {
          iconname: 'persen',
          width: '26',
          hegith: '26',
        }
        // {
        //   iconname: 'cart',
        //   width: '26',
        //   hegith: '26',
        // }
      ],
      phoneList: [],
      carnum: '',
      direction: "rtl",
      drawer: false,
      drawer_information: false,
      page: 1,
      searchname: '',
      // gzNum: 0,
      searchType: 0,// 0: 商品 1: 店铺,
      isSubMenuVisible: false,
      CategoryList: [],
      BrandList: [],
      BrandList1: [],
      drawerList: [
        {
          name: this.$t("seller.t6"),
          url: "home"
        },
        {
          name: this.$t("login.t7"),
          url: "login"
        },
        {
          name: this.$t("login.t23"),
          url: "login"
        },
      ],
      userInfoList: [
        {
          title: "user.t1", // 个人中心
          itemArr: [
            {
              title: "user.t2",  // 基础信息
              path: "userInfo",
              index: 0,
            },
            {
              title: "shuaixuan.t10",  // 我的店铺
              path: "myShop",
              index: 1,
            },
            {
              title: "auctionCompany.t15",//我的竞拍
              path: "Mybid",
              index: 2,
            },
            {
              title: "footer.t16",//用户须知
              path: "user-notice",
              index: 3,
            }
          ],
        },
        {
          title: "user.t3",  //我的钱包
          itemArr: [
            // {
            //   title: "user.t4",  //存款
            //   path: "deposit",
            //   index: 4,
            // },
            {
              title: "user.t5",//提现
              path: "withdraw",
              index: 4,
            },
            {
              title: "user.t6",//账单明细
              path: "billingDetails",
              index: 5,
            },
            {
              title: "user.t7",//存款日志
              path: "depositList",
              index: 6,
            },
            {
              title: "user.t8",//提现日志
              path: "withdrawalList",
              index: 7,
            },
            {
              title: "shuaixuan.t12",//绑定银行卡
              path: "bindingUSDT",
              index: 8,
            },
          ],
        },
        // {
        //   title: "user.t10",//订单中心
        //   itemArr: [
        //     {
        //       title: "user.t11",//我的订单
        //       path: "MyOrder",
        //       index: 9,
        //     },
        //     {
        //       title: "user.t12",//送货地址
        //       path: "shippingAddress",
        //       index: 10,
        //     },
        //   ],
        // },
        {
          title: "user.t13",//功能服务
          itemArr: [
            // {
            //   title: "user.t14",//精选商铺
            //   path: "selected",
            //   index: 9,
            // },
            {
              title: "auctionCompany.t26",//申请供货商
              path: "openAStore",
              index: 9,
            },
            {
              title: "user.t16",//修改密码
              path: "changPwd",
              index: 10,
            },
            {
              title: "user.t17",//设置支付密码
              path: "changzfPwd",
              index: 11,
            },
            {
              title: "auctionCompany.t10",//修改个人信息
              path: "EditPerson",
              index: 12,
            },
            {
              title: "user.t11",//我的订单
              path: "MyOrder",
              index: 13,
            },
            {
              title: "kefu.t4",// 站内信
              path: "inForm",
              index: 14,
            },
          ],
        },
        // {
        //   title: " ",
        //   itemArr: [
        //     {
        //       title: "user.t19",//关于我们
        //       path: "aboutUs",
        //       index: 15,
        //     },
        //     {
        //       title: "user.t20",//帮助服务
        //       path: "help",
        //       index: 16,
        //     },
        //   ],
        // },
      ],
      activeNames: [],
      dialogVisible: false,
      ishover: false,
      cateId: 273,
      brandId: '',
    }
  },
  created() {
    // 获取用户信息,并存值
    // this.$http.getUserInfo().then(res => {
    //   if (res.status == 200) {
    //     let userInfo = res.data;
    //     // 存值
    //     this.$store.commit('setUserInfo', userInfo);
    //     if (userInfo.rz_zt || userInfo.shop_id == 1) {
    //       // 已经是拍手
    //       this.listArry?.forEach(item => {
    //         if (item.title === 'user.t13') {
    //           item.itemArr.forEach(info => {
    //             if (info.path === 'openAStore') {
    //               info.path = ''
    //               info.title = '商家管理'
    //             }
    //           })
    //         }
    //       })
    //     }
    //   }
    // })
    if (localStorage.getItem('select_store_shop')) {
      this.selectValue = localStorage.getItem('select_store_shop')
    } else {
      localStorage.setItem('select_store_shop', this.$t('headerNav.t6'))
    }
    this.$http.getLangList().then(res => {
      // this.langlist = res.data
      if (res.status == 200) {
        this.$store.commit('SET_LANG_LIST', res.data)
      }
    })
    this.$http.getCountryList().then(res => {
      if (res.status === 200) {
        for (let key in res.data) {
          let obj = res.data[key];
          for (let listItem of obj.list) {
            this.phoneList.push({
              id: listItem.id,
              country_code: listItem.country_code
            })
            this.$store.commit('SET_PHONE_LIST', this.phoneList)
          }
        }
      }
    })
    this.getCategory()
    if (this.search_name) return

  },
  computed: {
    ...mapState(['isShowdown', 'userInfo', 'isLogin', 'cartNum', 'search_name', 'langlist', 'isShowBuy_seller', 'historyList', 'msgNum', 'isChatMSG', 'isChatSeller']),
    // 取值
    userInfo() {
      return this.$store.state.userInfo;
    }
  },
  watch: {
    search_name(n, o) {
      return this.searchname = o
    },
    // $route(n,o){
    //   console.log(n);
    //   if(n.name !== 'consignment'){
    //     return this.ishover = false
    //   }
    // }
  },
  methods: {
    throttle,
    ...mapMutations(['SET_LANG_ACTIVE', 'SET_SEARCH', 'SET_SEARCH_LIST', 'SET_SEARCH_NAME', 'CLEAR_DATA', 'SET_SHOP_STORE', 'SET_LOCAL_LANG', 'SET_MODAL_LOGIN']),
    setShop() {
      // 开店免费立即开店
      if (this.isLogin) {
        if (this.$route.name !== 'openAStore') {
          this.$message.success(this.$t('headerNav.t11'));
          this.$router.push({name: 'openAStore'});
          // this.$router.push('/openAStore');
        } else {
          this.$message(this.$t('headerNav.t12'));
        }
      } else {
        this.$message.error(this.$t('headerNav.t8'));
        setTimeout(() => {
          this.SET_MODAL_LOGIN(true)
        }, 2000)
      }
    },
    handleClicklang() {
      if (this.isShowdown) {
        this.$store.commit('SET_LANG_ACTIVE', false)
      } else {
        this.$store.commit('SET_LANG_ACTIVE', true)
      }
    },
    handleClickheader() {
      this.$store.commit('SET_LANG_ACTIVE', false)
    },
    handleClose(v) {
      this.SET_MODAL_LOGIN(v)
    },
    handleLoginInfo() {
      this.SET_MODAL_LOGIN(true)
    },
    getCartNum(v) {
      this.carnum = v
    },
    handleCloseInformation() {
      this.drawer_information = false
    },
    handleClickIconInfo(i) {
      // // 打开购物车抽屉
      // if (i === 2) {
      //   if (!this.isLogin) {
      //     return this.$message.warning(this.$t('headerNav.t8'))
      //   }
      //   // this.$store.state.isShowCardrawer = true
      //   this.$router.push('/shoppingBag');
      // } else if (i === 1) {
      //   // 跳转至my
      //   // console.log(this.$route.matched[0].path);
      //   if (this.$route.matched[0].path === '/user') return;
      //   this.$router.push('/user')
      // } else if (i === 0) {
      //   // 我得关注店铺
      //   if (this.$route.name === 'myHeart') return;
      //   this.$router.push('/myHeart')
      // } else {
      //   // 消息
      //   // if (this.isChatSeller) {
      //   //   this.$store.commit('isChatMSG', true)
      //   //   this.$store.commit('isChatSeller', false)
      //   //   this.drawer_information = true
      //   // } else {
      //   //   this.$store.commit('isChatMSG', true)
      //   //   this.drawer_information = true
      //   // }
      // }

      if (i === 1) {
        // 跳转至my
        // console.log(this.$route.matched[0].path);
        if (this.$route.matched[0].path === '/user') return;
        this.$router.push('/user')
      } else {
        if (this.isChatSeller) {
          this.$store.commit('isChatMSG', true)
          this.$store.commit('isChatSeller', false)
          this.drawer_information = true
        } else {
          this.$store.commit('isChatMSG', true)
          this.drawer_information = true
        }
      }
    },
    // 获取品牌和分类
    getCategory() {
      this.$http.getCategory().then(res => {
        if (res.status == 200) {
          let tempArray = [];
          res.data.brand.forEach((item, index, arr) => {
            tempArray.push(item); // 将当前元素放入临时数组中
            if (tempArray.length === (arr.length / 5) || index === arr.length - 1) {
              // 当临时数组达到子数组大小，或者已经遍历完所有元素时，将临时数组存入 groupedData
              this.BrandList.push(tempArray);
              tempArray = []; // 清空临时数组
            }
          })
          this.CategoryList = res.data.cateres.slice(1)
          this.BrandList1 = res.data.brand
        }
      })
    },
    // 获取拍卖商品
    getShopGoodsAllLists(id) {
      this.$http.getShopGoodsAllList({
        page: 1,
        cate_id: id
      }).then(res => {
        if (res.status == 200) {
          this.goodsList = res.data
        }
      })
    },
    getGoodsList(id) {
      this.$http.getCategoryGoodsList({
        page: 1,
        brand_id: id,
        cate_id: this.cateId
      }).then(res => {
        if (res.status === 200) {
          this.goodsList = res.data.goodres
        }
      })
    },
    // 点击获取分类或者品牌
    handleClickGetsubNav(type, id, drawer) {
      if (drawer === 'drawer') {
        this.drawer = false
      }
      this.ishover = true
      // 分类
      if (type === 1) {
        setTimeout(() => {
          this.ishover = false
        }, 1000);
        this.$router.push({name: 'consignment', query: {cateid: id}});
      } else if (type === 2) {
        // 品牌
        setTimeout(() => {
          this.ishover = false
        }, 1000);
        this.$router.push({name: 'consignment', query: {brandid: id}});
      }
    },
    // 搜索商品
    handleSearch() {
      if (this.$route.name !== 'home') {
        this.$router.push('/')
      }
      if (this.$store.state.search_name === '') {
        return this.$message.warning(this.$t('headerNav.t9'))
      }
      if (this.$store.state.search_name !== this.searchname) {
        this.$store.commit('CLEAR_DATA')
        this.$store.commit('SET_SEARCH_PAGE', 1)
      }
      if (this.historyList[this.searchType]) {
        const i = this.historyList[this.searchType].indexOf(this.$store.state.search_name)
        if (i >= 0) {
          this.historyList[this.searchType].splice(i, 1)
        }
      } else {
        this.historyList[this.searchType] = []
      }
      this.historyList[this.searchType].unshift(this.$store.state.search_name)
      if (this.historyList[this.searchType].length > 10) {
        this.historyList[this.searchType].pop()
      }
      this.$store.commit('SET_SEARCH', true)
      this.$store.commit('setHistoryList', this.historyList)
      if (this.selectValue === this.$t('headerNav.t6')) {
        this.$store.commit('SET_SHOP_STORE', false)
        if (this.$store.state.search_list.length !== 0) {
          this.$store.state.search_page++
          this.$store.commit('SET_SEARCH_PAGE', this.$store.state.search_page)
          this.$http.getsearchgoods({
            page: this.$store.state.search_page,
            keyword_name: this.$store.state.search_name
          }).then(res => {
            if (res.status === 200) {
              if (res.data.goodres.length === 0) {

              } else {
                this.$store.commit('SET_SEARCH_LIST', res.data.goodres)
              }
            }
          })
          return
        } else {
          this.$http.getsearchgoods({
            page: 1,
            keyword_name: this.$store.state.search_name
          }).then(res => {
            if (res.status === 200) {
              if (res.data.goodres.length === 0) {
                this.$store.commit('CLEAR_DATA')
              } else {
                this.$store.commit('SET_SEARCH_LIST', res.data.goodres)
              }
            }
          })
        }
      }
    },
    // 实时获取搜索框的值
    handlInput(v) {
      this.SET_SEARCH_NAME(v)
    },
    // 取消搜索，或点击x
    handlecancel() {
      this.$router.push('/')
      this.$store.commit('SET_SEARCH_NAME', '')
      this.$store.commit('SET_SEARCH', false)
      this.$store.commit('CLEAR_DATA')
      this.$store.commit('SET_SEARCH_PAGE', 1)
    },
    // 获取是否是店铺和商品
    handleChangeSelect(v) {
      localStorage.setItem('select_store_shop', v)
      this.page = 1
      this.$store.commit('CLEAR_DATA')
      if (this.$store.state.search_name === '') {
        return
      }
      if (!this.search_name) {
        return
      }
      if (v === this.$t('headerNav.t6')) {
        this.$store.commit('SET_SHOP_STORE', false)
        this.searchType = 0
        this.$http.getsearchgoods({
          page: 1,
          keyword_name: this.$store.state.search_name
        }).then(res => {
          if (res.status === 200) {
            this.$store.commit('SET_SEARCH_LIST', res.data.goodres)
          }
        })
      } else {
        this.searchType = 1
        this.$store.commit('SET_SHOP_STORE', true)
        this.$http.getShopList({
          page: 1,
          keyword_name: this.$store.state.search_name
        }).then(res => {
          if (res.status == 200) {
            res.data.data.slice(0, 3)
            this.$store.commit('SET_STORE_LIST', res.data.data)
          }
        })
      }
      this.selectValue = v

    },
    // 点击回到首页
    handleClickToHome(name, indexId) {
      if (this.$route.name === 'home' && indexId === -1) {
        this.handlecancel();
      } else if (this.$route.name !== 'home' && indexId === -1) {
        this.handlecancel();
        this.$router.push('/');
      } else {
        // 存值
        if (name === this.$t('headerNav.t6')) {
          this.$store.commit('SET_SEARCH_NAME', '')
          this.$store.commit('SET_SEARCH', false)
          this.$store.commit('CLEAR_DATA')
          this.$store.commit('SET_SEARCH_PAGE', 1)
          if (this.$route.name === 'home') {
            return
          } else {
            this.$router.push('/')
          }
        } else {
          this.$store.commit('categoryInfo', indexId);
          if (this.$route.name === 'category') {
            return
          } else {
            this.$router.push('/category');
          }
        }
      }

      // if (this.$route.name !== 'home' && indexId === -1) {
      //   this.handlecancel();
      //   this.$router.push('/');
      // }else{
      //
      // }

    },
    handleChange(v) {
      this.activeNames = v
    },
    gotouser(path, title, idx) {
      if (this.$route.name === path) return;
      this.$store.commit('setuserTit', {
        title,
        index: idx,
        path,
      });
      localStorage.setItem('userItem', JSON.stringify({
        path,
        index: idx,
        title
      }))
      if (path === 'openAStore') {
        if (this.$store.state.userInfo.rz_zt || this.$store.state.userInfo.shop_id == 1) {
          // 是拍客
          this.$router.push({
            name: 'product', params: {
              path,
              index: idx,
              title: 'consignment.t64'
            }
          })
          localStorage.setItem('userItem', JSON.stringify({
            path: 'product',
            index: idx,
            title: 'consignment.t64'
          }))
        }
      } else {
        this.$router.push({
          name: path, params: {
            path,
            index: idx,
            title
          }
        });
      }
      this.drawer = false
    },
    tuichu() {
      // this.drawer = false
      this.dialogVisible = true
    },
    // 退出账号
    loginOut() {
      this.drawer = false
      this.dialogVisible = false
      // 清除缓存，跳转登陆
      localStorage.clear();
      this.$message.success(this.$t('personalCenter.t10'))
      this.$router.push('/')
      // 更改isLogin状态
      this.$store.commit('loginStatus', false);
      this.$store.commit('removeOpenAStoreType');
      this.$store.commit('setOpenAStoreVal', false);  // 申请店铺
    },
    gotos(url) {
      this.drawer = false
      if (this.$route.name === url) {
        this.$router.push({name: url})
      } else {
        if (url === 'login') {
          if (!this.isLogin) {
            this.SET_MODAL_LOGIN(true)
          }
        }
      }
    },
    gotoAuctioncenter() {
      if (this.userInfo.rz_zt || this.userInfo.shop_id == 1) {
        this.$router.push({name: "auctionCompany"})
      } else {
        this.$message.warning(this.$t('auctionCompany.t28'))
      }
    }
  },
}
</script>
<style lang='less' scoped>
.pay_rum {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  transform: scale(0.7);
  width: 17px;
  height: 17px;
  font-size: 13px;
  background-color: red;
  color: #fff;
  border-radius: 100px;
  //position: absolute;
  //top: -5px;
}

.header {
  position: sticky;
  top: 0;
  z-index: 18;
  width: 100vw;

  .containerPages {
    margin: 0 auto;

    .black_bar {
      width: 100%;
      height: 38px;
      // max-width: 1200px;
      display: flex;
      align-items: center;
      overflow: hidden;

      .bar_container {
        overflow: hidden;
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        color: #fff;
        font-size: 16px;
        cursor: pointer;

        .open {
        }

        .wrap {
          background-color: #fff;
          border-radius: 22px;
          height: 22px;
          line-height: 22px;
          padding: 0 5px;
          flex-shrink: 0;
          display: flex;
          justify-content: center;
          align-content: center;
          color: #000;
          margin: 2px 17px 0 25px;
          font-size: 14px;
          font-style: italic;
          font-weight: 700;
        }

        .siv {
          margin-right: .52rem;
        }

        .el-icon-right {
          width: 16px;
          height: 16px;
          font-weight: 600 !important;
        }
      }
    }

    .bgc {
    }

    .white_bar {
      filter: drop-shadow(0px 10px 50px rgba(0, 0, 0, .05));
      background-color: #fff;
      width: 100%;
      margin: 0 auto;
      display: flex;
      justify-content: center;

      .white_bar_container {
        max-width: 1200px;
        padding: 11px;
        padding-bottom: 0;
        width: 100%;
        box-sizing: border-box;

        .top {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;

          .logo {
            img {
              width: 100px;
              height: 35px;
            }
          }

          .logo-info {
            // width: 244px;
            display: flex;
            flex-direction: row;
            justify-content: flex-end;
            align-items: center;

            .item {
              width: 26px;
              background-color: #fff;
              height: 26px;
              position: relative;
              border-radius: 50%;
              display: flex;
              justify-content: center;
              align-items: center;
              cursor: pointer;

              &:not(:last-child) {
                margin-right: 20px;
              }

              &:last-child {
                background-color: #f2f2f2;
              }

              .dot {
                width: fit-content;
                min-width: 14px;
                height: 14px;
                border: 2px solid #fff;
                background-color: #222;
                border-radius: 50%;
                position: absolute;
                top: -3px;
                right: -3px;
                display: flex;
                justify-content: center;
                align-items: center;
                color: #fff;
                font-family: D-DIN-PRO;
                font-size: 12px;
                font-style: normal;
                font-weight: 600;
                line-height: normal;
                box-sizing: content-box;
              }
            }

            .el-icon-s-unfold {
              font-size: 20px;
            }
          }

          .logininfo {
            display: flex;
            flex-direction: row;
            justify-content: flex-end;
            align-items: center;

            .isNotLogin {
              display: flex;
              justify-content: center;
              align-items: center;
              color: #fff;
              background-color: #222;
              width: 86px;
              height: 30px;
              padding: 8px 29px;
              font-size: 14px;
              font-weight: 700;
              cursor: pointer;
            }
          }
        }

        .bottom {
          display: flex;
          flex-direction: row-reverse;
          justify-content: space-between;
          align-items: center;
          padding-left: 100px;

          .category {
            display: flex;
            justify-content: space-around;
            align-items: center;
            height: 40px;

            .cate {
              height: 40px;
              display: flex;
              align-items: center;
              cursor: pointer;
              margin-right: 40px;

              .txt {
                font-size: 18px;
                font-weight: 500;
              }
            }

            .brand {
              &:hover .sub-menu {
                visibility: visible !important;
                overflow-y: auto !important;
                opacity: .83;
                height: 580px !important;
              }

              &:hover .w {
                visibility: visible;
                height: 580px !important;
              }

              .w {
                visibility: hidden;
                width: 100vw;
                // min-width: 1200px;
                position: absolute;
                margin: 0 auto;
                z-index: 10;
                top: 106px;
                height: 0px;
                left: 0;
                justify-content: center;
                background-color: rgb(248, 248, 248);
                transition: all .5s ease-in-out;

                .sub-menu {
                  box-sizing: border-box;
                  // width: 1200px;
                  margin: 0 auto;
                  display: flex;
                  justify-content: center;
                  height: 0px;
                  // background-color: #222;
                  color: #000;
                  visibility: hidden;
                  overflow: hidden;
                  transition: all .5s ease-in-out;

                  &::-webkit-scrollbar {
                    display: none;
                  }

                  &::-webkit-scrollbar-thumb {
                    display: none;
                  }

                  &::-webkit-scrollbar-track {
                    display: none;
                  }

                  &::-webkit-scrollbar-track-piece {
                    display: none;
                  }

                  .info {
                    width: 186px;
                    box-sizing: border-box;

                    &:first-child {
                      padding-left: 24px;
                    }

                    .item {
                      margin-bottom: 14px;

                      &:first-child {
                        padding-top: 24px;
                      }

                      &:last-child {
                        padding-bottom: 24px;
                      }

                      a {
                        &:hover {
                          opacity: .7;
                          text-decoration: underline;
                        }
                      }
                    }
                  }
                }
              }
            }

            .classify {
              &:hover .sub-menu {
                visibility: visible !important;
                overflow-y: auto !important;
                opacity: .83;
                height: 200px !important;


              }

              &:hover .w {
                visibility: visible;
                height: 200px !important;
              }

              .w {
                // min-width: 1200px;
                visibility: hidden;
                width: 100vw;
                position: absolute;
                z-index: 10;
                top: 106px;
                height: 0px;
                left: 0;
                background-color: rgb(248, 248, 248);
                transition: all .5s ease-in-out;

                .sub-menu {
                  box-sizing: border-box;
                  width: 100%;
                  margin: 0 auto;
                  display: flex;
                  flex-wrap: wrap;
                  height: 0px;
                  color: #000;
                  visibility: hidden;
                  overflow: hidden;
                  transition: all .5s ease-in-out;

                  &::-webkit-scrollbar {
                    display: none;
                  }

                  &::-webkit-scrollbar-thumb {
                    display: none;
                  }

                  &::-webkit-scrollbar-track {
                    display: none;
                  }

                  &::-webkit-scrollbar-track-piece {
                    display: none;
                  }


                  .info {
                    width: 16.66%;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;

                    img {
                      width: 50%;
                      margin-bottom: 10px;

                    }

                    a {
                      min-height: 50px;
                      font-size: 16px;
                      text-align: center;
                    }
                  }
                }
              }
            }
          }

          .right {
            min-width: 80px;
            height: 40px;

            .containerPage {
              height: 40px;
              display: flex;
              align-items: center;

              .containerCon {
                width: fit-content;
                height: auto;
                position: relative;
                display: flex;
                justify-content: center;
                align-items: center;

                .actives {
                  display: flex;
                  flex-direction: row;
                  align-items: center;
                  width: 100%;
                  cursor: pointer;

                  .left {
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    margin-right: 4px;

                    .title {
                      margin-left: 4px;
                      white-space: nowrap;
                      text-align: right;
                      font-family: Inter;
                      font-size: 18px;
                      font-style: normal;
                      font-weight: 500;
                      line-height: normal;
                      text-transform: none;
                      color: #000c22;
                    }

                    img {
                      width: 24px;
                      height: 18px;
                      border-radius: 2px;
                    }
                  }

                  .rights {
                    height: 19px;
                  }
                }

                /deep/ .options {
                  transition: all .2s ease;
                  position: absolute;
                  z-index: 99999;
                  top: 102%;
                  right: 0;
                  // width: fit-content;
                  width: 100% !important;
                  // min-width: 100px;
                  height: fit-content;
                  overflow: auto;
                  background-color: #fff;
                  box-sizing: border-box;
                  max-height: 0;

                  .item {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    height: 40px;
                    padding: 0 4px;
                    box-sizing: border-box;

                    .select-item {
                      padding: 5px;
                      width: 100%;
                      display: flex;
                      flex-direction: row;
                      justify-content: center;
                      align-items: center;

                      div {
                        img {
                        }

                        span {
                        }
                      }

                      & > div {
                        width: 100%;
                        display: flex;
                        flex-direction: row;
                        justify-content: space-between;
                        align-items: center;

                        img {
                          width: 24px;
                          height: 18px;
                          border-radius: 2px;
                        }

                        span {
                          margin-left: 4px;
                          white-space: nowrap;
                          overflow: hidden;
                          flex: 1;
                          text-align: left;
                          text-overflow: ellipsis;
                          font-size: 19px;
                        }
                      }
                    }
                  }

                  .active {
                    background-color: #222;
                    color: #fff;
                  }
                }

                .h120 {
                }
              }
            }
          }
        }
      }
    }
  }
}

.nothover {
  display: none;
}

.w200 {
  width: 8rem !important;
}

.h120 {
  max-height: 120px !important;
  width: 100% !important;
}

.deg180 {
  transform: rotate(180deg) !important;
}

.search-bar {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  overflow: hidden;

  // .search {
  //   width: 91px;
  //   height: 32px;
  //   border-radius: 100px;
  //   margin-right: 14px;

  //   ::v-deep {
  //     .el-select {
  //       height: 32px;
  //       vertical-align: middle;

  //       .el-input {
  //         height: 32px;
  //       }

  //       .el-input__inner {
  //         height: 32px;
  //         border-radius: 100px;
  //         line-height: 32px;
  //         background-color: #f5f6f8;
  //         font-size: 14px;
  //         color: #606266;
  //       }

  //       .el-input__suffix {
  //         top: 5px;
  //       }
  //     }
  //   }


  // }

  .input {
    width: 300px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;

    ::v-deep {
      .el-input {
        height: 32px;
        font-size: 14px;
        display: inline-flex;
        width: 100%;
        line-height: 32px;
        box-sizing: border-box;
        vertical-align: middle;

        input {
          padding: 5px;
          height: 32px;
          border: 1px solid #ccc;
          border-radius: 40px;
        }
      }
    }

    .w200 {
    }

    .actionbar {
      width: 0;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      transition: all .2s ease;
      margin-left: 10px;

      .el-icon-close {
        width: 1em;
        height: 1em;
        font-weight: 600;
        color: #333;
        overflow: hidden;
        cursor: pointer;
      }

      .svg {
        overflow: hidden;
      }

      .cancel {
        cursor: pointer;
        white-space: nowrap;
        color: #222;
        text-align: right;
        font-family: Poppins;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 16px;
        margin-left: 15px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex: 1;
      }
    }
  }

  .searchIcon {
    width: 28px;
    height: 26px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 100%;
    background-color: #222;
    margin-left: 4px;
    color: #fff;

    .svg-icon {
      cursor: pointer;
    }
  }
}

::v-deep {
  .el-drawer__wrapper {
    .seveendrawer {
      width: 100% !important;

      header {
        .el-drawer__close-btn {
          font-size: 30px;
        }
      }

      .el-drawer__body {
        padding: 28px 4% 70px 4%;

        .spmenu {
          .item {
            font-weight: 500;
            border-bottom: 1px solid #ccc;
            border-radius: 5px 5px 0 0;

            &:nth-child(3) {
              border: 0;
              margin-bottom: 20px;
            }

            .info {
              padding: 8px 8px;
              position: relative;

              &::after {
                content: "";
                width: 7px;
                height: 7px;
                border-top: solid 2px #DDD1B6;
                border-right: solid 2px #DDD1B6;
                transform: rotate(45deg);
                position: absolute;
                right: 11px;
                top: 21px;
              }
            }
          }

          .el-collapse {
            border: 0;

            .el-collapse-item__header {
              padding: 8px;
              border-color: #ccc;
              height: auto;
              line-height: normal;
              position: relative;

              &::after {
                content: "";
                width: 7px;
                height: 7px;
                border-top: solid 2px #DDD1B6;
                border-right: solid 2px #DDD1B6;
                transform: rotate(135deg);
                position: absolute;
                right: 11px;
                top: 16px;
              }

              .el-collapse-item__arrow {
                display: none;
              }
            }

            .is-active {
              .el-collapse-item__header {
                &::after {
                  rotate: 180deg;
                }
              }
            }

            .el-collapse-item__wrap {
              border: 0;
            }
          }

          .openmenu {
            display: flex;
            flex-wrap: wrap;
            margin: 12px 0;

            .sub {
              width: 50%;
              padding: 8px;
              box-sizing: border-box;
            }
          }

          .logout {
            width: 100%;
            display: flex;
            justify-content: center;
            height: 40px;
            background: #000;
            color: #fff;
            align-items: center;
            margin-top: 20px;
          }
        }
      }
    }
  }
}

/deep/ .el-dialog {
  border-radius: 15px;
}

//弹窗样式
.dialog_con {
  padding: 0 30px;
  text-align: center;

  p {
    font-size: 18px;
    color: #000c22;
    margin-bottom: 50px;
  }

  .btn {
    background-color: #000c22;
    border: none;
    font-size: 18px;
    font-weight: 700;
    width: 100%;
    height: 50px;
  }
}

@media screen and (max-width: 1024px) {
  .white_bar_container {
    .top {

      .logo-info {
        .drawer {
          display: block !important;
        }
      }
    }
  }

}

@media screen and (max-width: 768px) {
  .white_bar_container {
    .top {
      .logo {
        img {
          width: 106px !important;
          max-height: 54px;
        }
      }

      .search-bar {
        display: none;
      }

    }

    .bottom {
      padding: 0 !important;
      justify-content: space-between !important;

      .category {
        display: none !important;
      }

      .search-bar {
        width: 70%;

        .input {
          width: 100%;
        }
      }
    }
  }
}

@media screen and (min-width: 1200px) {
  .classify {
    .w {
      .sub-menu {
        max-width: 1200px;
      }
    }
  }
}

@media screen and (min-width: 768px) {
  .white_bar_container {
    .top {
      .logo-info {
        .drawer {
          display: none;
        }
      }
    }

    .bottom {
      padding: 0 !important;

      .category {
        display: flex !important;
      }

      .search-bar {
        display: none;
      }
    }
  }
}

@media screen and (max-width: 865px) {
  /deep/ .el-dialog {
    width: 45% !important;
  }

  .dialog_con {
    padding: 0 !important;

    .btn {
      box-sizing: border-box;
      font-size: 16px !important;
      height: 40px !important;
    }
  }
}

@media screen and (max-width: 576px) {
  /deep/ .el-dialog {
    width: 55% !important;
  }
}

@media screen and (max-width: 445px) {
  /deep/ .el-dialog {
    width: 70% !important;
  }
}

@media screen and (max-width: 390px) {
  /deep/ .el-dialog {
    width: 70% !important;

    .el-dialog__body {
      padding: 10px 20px !important;
      padding-top: 0 !important;

      p {
        margin-bottom: 10px !important;
      }
    }
  }

  .white_bar_container {
    .top {
      .logininfo {
        .isNotLogin {
          height: auto !important;
          padding: 5px 16px !important;
        }
      }
    }

    .bottom {
      padding: 10px 0px !important;

      .right {
        .containerPage {
          justify-content: flex-end;

          .containerCon {
            /deep/ .lang {
              .actives {
                .left {
                  .title {
                    font-size: 14px !important;
                  }
                }
              }
            }
          }

          /deep/ .options {
            width: 100% !important;
            min-width: auto !important;

            &::-webkit-scrollbar {
              display: none;
            }

            &::-webkit-scrollbar-thumb {
              display: none;
            }

            &::-webkit-scrollbar-track {
              display: none;
            }

            &::-webkit-scrollbar-track-piece {
              display: none;
            }

            .item {
              padding: 0 !important;

              span {
                font-size: 14px !important;
              }
            }
          }
        }
      }
    }
  }
}
</style>
