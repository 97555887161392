<template>
    <div class="goods-item-wrapper" @click="handle">
        <div class="goods-img">
            <shop-img :src="item.thumb_url"></shop-img>
            <div class="goods-logo" v-if="logo">
                <shop-img :src="logo"></shop-img>
            </div>
        </div>
        <h1>{{ item.goods_name }}</h1>
        <div class="goods-botton">
            <div class="goods-info">
                <div class="no">{{ $t('GoodsItem.t1') }} :{{ }}</div>
                <div class="goods-price"><span> US <i>$</i>{{ item.zs_price
                }}</span>
                    <!-- /<span><i>$</i>{{ item.zs_price }}</span> -->
                </div>
            </div>
            <div class="activated1">
                <SvgIcon iconClass="shops" color="#333" width="16" height="16" />
            </div>
        </div>
    </div>
</template>
<script>
export default {
    props: {
        item: {
            type: Object,
            default: ''
        },
        logo: {
            type: String,
            default: ''
        }
    },
    watch: {
        logo(newVal, oldVal) {
            this.logo = newVal; // 在 prop 变化时更新本地副本
        }
    },
    mounted() {

    },
    computed: {

    },
    methods: {
        handle() {
            this.$emit('clicks')
        }
    },
}
</script>
<style lang='less' scoped>
.goods-item-wrapper {
    color: #000;
    background: #fff;
    border-radius: 6px;
    overflow: hidden;

    .goods-img {
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        height: 232px;

        .imgContainer {
            height: 100%;
            width: 100%;
            border-radius: 0px;
            background-color: #fff;
            overflow: hidden;

            .el-image {
                display: flex;
                align-items: center;
                justify-content: center;
                position: relative;
                height: 232px;
            }
        }

        .goods-logo {
            position: absolute;
            width: 40px;
            height: 40px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 25%;
            left: 6px;
            top: 6px;
            overflow: hidden;
        }
    }

    h1 {
        padding: 15px 15px 0;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        color: #080913;
        font-family: Mada;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
    }

    .goods-botton {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 5px 15px 10px;

        .goods-info {
            .no {
                font-size: 14px;
                line-height: 26px;
                color: #9b9b9b;
                height: 26px;
            }

            .goods-price {
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-line-clamp: 1;
                -webkit-box-orient: vertical;
                font-weight: 700;
                font-size: 12px;
                color: #969696;
                font-family: Mada;
                font-size: 18px;
                font-style: normal;
                font-weight: 500;
                line-height: 150%;

                span {
                    &:first-child {
                        color: #db3022;
                        font-size: 18px;
                        font-family: D-DIN-PRO;
                        font-weight: 700;
                        line-height: 20px;
                    }

                    &:nth-child(2) {
                        color: #969696;
                        font-family: Mada;
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: 150%;
                    }
                }
            }
        }

        .activated1 {
            width: 36px;
            height: 36px;
            background-color: #000;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 50%;

            img {
                width: 16px;
                height: 16px;
            }
        }
    }
}

@media screen and (max-width: 500px) {
    .goods-item-wrapper {
        .goods-img {
            height: 165px !important;
        }
    }
}
@media screen and (max-width:390px) {
    .goods-item-wrapper {
        .goods-img {
            height: 135px !important;
        }
    }
}
</style>
