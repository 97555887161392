const parseGoodsStr = (str) => {
    const goodsStr = str.replace(/(^goods\[)|(\]$)/g, "");
    return goodsStr && JSON.parse(goodsStr);
};

// 节流(throttle )函数
let timer = null
export const throttle = (fn, delay = 1000) => {
    if (timer == null) {
        timer = setTimeout(() => {
            fn()
            clearTimeout(timer)
            timer = null
        }, delay);
    }
}
/**
 * 格式化时间戳 秒
 *
 * @param {Number} timestamp
 * @param s
 */
 export const formatTime = (timestamp, s = 'YYYY-MM-DD hh:mm') => {
    if (!timestamp) return ''

    const date = new Date(timestamp * 1000)
    const year = date.getFullYear()
    const month = parseInt(date.getMonth()) + 1
    const day = date.getDate() > 9 ? date.getDate() : '0' + date.getDate()
    const hours = date.getHours() > 9 ? date.getHours() : '0' + date.getHours()
    const min = date.getMinutes() > 9 ? date.getMinutes() : '0' + date.getMinutes()

    // 根据s字符串进行格式化
    if (s === 'YYYY-MM-DD hh:mm') {
        return `${year}-${month}-${day} ${hours}:${min}`
    } else if (s === 'YYYY-MM-DD') {
        return `${year}-${month}-${day}`
    } else if (s === 'hh:mm') {
        return `${hours}:${min}`
    } else if (s === 'MM-DD') {
        return `${month}-${day}`
    }
}
export { parseGoodsStr };
