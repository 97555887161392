<template>
    <div class="imgContainer">
        <div class="el-image" style="width: 100%; height: 100%; border-radius: 0px;"><img :src="src"
                class="el-image__inner"></div>
    </div>
</template>
<script>
export default {
    props: {
        src: {
            type: String,
            default: ""
        }
    },
    data() {
        return {

        }
    },
    mounted() {

    },
    computed: {

    },
    methods: {

    },
}
</script>
<style lang='less' scoped>
.imgContainer {
    width: 100%;
    height: 100%;
    overflow: hidden;
    background-color: #fff;

    .el-image {
        position: relative;
        display: inline-block;
        overflow: hidden;

        .el-image__inner {
            vertical-align: top;
            opacity: 1;
        }
    }
}
</style>
