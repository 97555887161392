<template>
  <div id="app" @click="handleClickisShowdown">
    <headerNav v-if="!isShowHeaderNav"></headerNav>
    <router-view v-if="!isshowcustomer"/>
     <footers v-if="!isShowFooter"></footers>
    <router-view v-if="isshowcustomer" />
  </div>
</template>

<script>
import headerNav from '@/components/headerNav.vue'
import footers from '@/components/footer.vue'
import { mapState } from 'vuex';
export default {
  name: '',
  data() {
    return {
      showdown: '',
      isShowFooter: false,
      isShowHeaderNav: false,
      isshowcustomer: false
    }
  },
  created() {
    import('@/utils/socket').then(({ Socket }) => {
      Socket.getInstance().init()
    })
    this.$http.getConfig().then(res => {
      if (res.status === 200) {
        Object.assign(this.$store.state.serviceUser, res.data)
      }
    })
  },
  computed: {
    ...mapState(['isShowBuy_seller'])
  },
  components: {
    headerNav,
    footers
  },
  watch: {
    $route: function (r) {
      if (r.matched[0].path === '/seller') {
        this.isShowFooter = true
        this.isShowHeaderNav = true
      } else if (r.matched[0].path === '/user') {
        this.isShowFooter = true
      } else if (r.name === 'customer') {
        this.isShowFooter = true
        this.isShowHeaderNav = true
        this.isshowcustomer = false
      }else if(r.name === 'consignment'||r.name === 'goodsDetails'||r.name === 'shoppingBag'){
        // this.isShowFooter = true;
        // this.isShowHeaderNav = true;
        // this.isshowcustomer = false
      }else {
        this.isShowFooter = false
        this.isShowHeaderNav = false
      }
    }
  },
  methods: {
    handleClickisShowdown() {
      this.$store.commit('SET_LANG_ACTIVE', false)
    }
  },
}
</script>
<style lang="less">
@import '@/assets/css/shoppingDetails.less';
#app {
  background-color: #fff;
  height: 100%;
  width: 100%;
}
</style>
