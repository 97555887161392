<template>
    <div class="hotSwiper">
        <div class="title">
            <div>
                <SvgIcon iconClass="hot" color="#333" width="24" height="24" />
                {{ $t('hotSwiper.t1') }}
                <div class="toAllBtn" @click="handleClickTo">
                    {{ $t('hotSwiper.t2') }}
                    <SvgIcon iconClass="rollow_right" color="#333" width="17" height="12" />
                </div>
            </div>
            <div></div>
        </div>
        <SvgIcon iconClass="Left" color="#333" width="40" height="40" class="left"
            @click="changeCarousel('prev', hotSwipr)" />
        <swiper :slidesPerView="6" :spaceBetween="30" loop :modules="modules" class="mySwiper" :navigation="{
            nextEl: '.hotSwiper .right',
            prevEl: '.hotSwiper .left',
        }">
            <template v-slot:wrapper-start>
                <swiper-slide v-for="(item,index) of hotShopData" :key="index">
                    <div class="flex" @click="handleClick(item)">
                        <div class="hotSwipr_item shop">
                            <div class="hotSwipr_content">
                                <div class="item">s
                                    <div class="img">
                                        <shop-img :src="item.logo"></shop-img>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </swiper-slide>
            </template>
        </swiper>
        <SvgIcon iconClass="Right" color="#333" width="40" height="40" class="right"
            @click="changeCarousel('next', hotSwipr)" />
    </div>
</template>
<script>
import { Swiper, SwiperSlide } from 'swiper/vue';
import 'swiper/css';
import 'swiper/less/navigation'
import 'swiper/css/pagination'
import { Navigation, Pagination } from 'swiper/modules';
export default {
    props: {
        hotShopData: {
            default: ''
        }
    },
    data() {
        return {
            modules: [Pagination, Navigation],

        }
    },
    mounted() {

    },
    computed: {

    },
    components: {
        Swiper,
        SwiperSlide,
    },
    methods: {
        handleClick(v) {
            this.$emit('clicks', v)
        },
        handleClickTo() {
           this.$emit('clickto')
        }
    },
}
</script>
<style lang='less' scoped>
.hotSwiper {
    margin-top: 20px;
    background-color: #fff;
    padding: 15px 20px;
    max-width: 1320px;
    box-sizing: border-box;
    position: relative;

    .title {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 10px;

        svg {
            margin-right: 7px;
        }

        &>div {
            display: flex;
            align-items: center;

            &:first-child {
                color: #000c22;
                font-family: Inter;
                font-size: 20px;
                font-style: normal;
                font-weight: 700;
                line-height: 20px;

                .toAllBtn {
                    width: 134px;
                    height: 36px;
                    flex-shrink: 0;
                    background-color: #f4f4f4;
                    border-radius: 30px;
                    display: flex;
                    align-items: center;
                    margin-left: 22px;
                    justify-content: space-evenly;
                    color: #222;
                    font-family: Inter;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 14px;
                    text-transform: none;
                    cursor: pointer;
                }
            }

            &:last-child {
                color: #999;
                text-align: right;
                font-family: Poppins;
                font-size: 14px;
                font-style: normal;
                font-weight: 600;
                line-height: 14px;
                display: flex;
                align-items: center;
            }
        }
    }

    .left {
        position: absolute;
        cursor: pointer;
        left: -56px;
    }

    .right {
        position: absolute;
        cursor: pointer;
        right: -56px;
        top: 61px;
    }

    ::v-deep {
        .swiper {
            overflow: clip;
            display: flex;

            .swiper-wrapper {
                transform: translate3d(0px, 0px, 0px);
                transition-duration: 0ms;
                transition-delay: 0ms;
                // width: 0;
            }

            .swiper-slide {
                width: 188.333px;
                margin-right: 30px;

                .flex {
                    display: flex;
                    align-items: center;
                    max-width: 1320px;
                    box-sizing: border-box;

                    .hotSwipr_item {
                        box-sizing: border-box;
                        margin-right: 20px;
                        flex-shrink: 0;
                        display: flex;
                        padding: 5px 49px;
                        justify-content: center;
                        align-items: center;
                        width: auto;
                        height: auto;

                        .hotSwipr_content {
                            box-sizing: border-box;

                            .item {
                                position: relative;
                                height: auto;
                                z-index: 3;

                                .img {
                                    width: 80px;
                                    height: 80px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

::v-deep {
    .swiper {
        width: 100%;
        height: 100%;
    }

    .swiper-slide {
        text-align: center;
        font-size: 18px;
        height: 100px;
        background: #fff;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    // .swiper-slide img {
    //     display: block;
    //     width: 100%;
    //     height: 100%;
    //     object-fit: cover;
    // }
}
</style>
