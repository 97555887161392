<template>
  <div class="goods-product">
    <el-image :src="info.thumb_url" style="width: 100%;height: auto">
      <div slot="error" class="image-slot">
        <i class="el-icon-picture-outline"></i>
      </div>
    </el-image>
    <div class="tile-body">
      <div class="pdp-link">
        <span class="brand">{{ info.brand_name }}</span>
        <span class="name">{{ info.goods_name }}</span>
      </div>
      <div class="price">
        <span class="sales"> ${{ info.zs_price }} </span>
      </div>
    </div>
  </div>
</template>
<style lang="less" scoped>
.goods-product {
  max-width: 300px;
  height: 100%;
  cursor: pointer;

  .tile-body {
    padding-top: 5px;
    padding-bottom: 16px;

    .pdp-link {
      margin-bottom: 2px;

      .brand {
        display: block;
        font-size: 18px;
        font-weight: 600;
        font-family: Montserrat, sans-serif;
      }

      .name {
        font-size: 14px;
        color: #222;
        letter-spacing: 1px;
      }
    }

    .price {
      line-height: 30px;

      .sales {

      }
    }
  }
}

::v-deep {
  .el-image{
    img{
      display: block;
      width: 100%; /* 或者设置固定宽度 */
      height: auto; /* 自动保持比例缩放 */
      transition: transform 0.3s ease-in-out;
    }
    img:hover{
      transform: scale(1.1);
    }
  }
}
@media screen and (max-width: 1230px){
  //.el-carousel__indicators {
  //  display: none;
  //}
}
</style>
<script>
export default {
  props: {
    info: {
      type: Object,
      default: function () {
        return {}
      }
    }
  },
  data() {
    return {}
  },
  created() {

  },
  methods: {},
  computed: {
    // 改变价格格式
    // formatNumber(number) {
    //   return new Intl.NumberFormat('en-US', {
    //     style: 'currency',
    //     currency: 'USD', // 更改此处以适应所需的货币类型，如 'CNY'、'EUR' 等
    //     minimumFractionDigits: 2,
    //     maximumFractionDigits: 2,
    //   }).format(number);
    // },
  }

}
</script>