<template>
  <div class='shopItem'>
    <div class="title">
      <SvgIcon iconClass="shop" color="#333" width="1em" height="1em"/>
      <span>
                {{ item.shop_name }}
            </span>
    </div>
    <div class="lists">
      <div class="last">
        <div class="title">
          {{ item.shop_name }}
        </div>
        <div class="num">
          <div class="attention">{{ $t('shopItem.t1') }}：{{ item.coll_num }}</div>
          <div class="fans">{{ $t('shopItem.t2') }}：{{ item.check_num }}</div>
        </div>
        <div class="star" v-if="rate">
          <el-rate v-model="rate" disabled>
          </el-rate>
        </div>
        <div class="ntn" @click="shopBtn(item.id)">
          {{ $t('shopItem.t3') }}
          <SvgIcon
              style="margin-left: 3px"
              iconClass="jianRight"
              width="17"
              height="12"
          />
        </div>
      </div>
<!--      商品显示-->
      <div class="lists-item" v-for="info in item.goodres" @click="goolsTo(info.id)">
        <img :src="info.thumb_url" alt="">
        <div class="price">
          {{ info.zs_price }}
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    item: {
      default: ''
    }
  },
  data() {
    return {
      value: 0
    }
  },
  mounted() {

  },
  computed: {
    rate() {
      return this.item.praise_lv / 20
    }
  },
  methods: {
    shopBtn(shopId) {
      // 跳转店铺
      this.$router.push({
        path: "/shopDetail",
        query: {
          shop_id: shopId
        },
      });
    },
    // 跳转商品详情
    goolsTo(id) {
      // 跳转
      this.$router.push({
        path: "/goodsDetail",
        query: {
          id
        },
      });
    }
  }
}
</script>
<style lang='less' scoped>
.shopItem {
  background-color: #fff;
  padding: 10px;
  // padding-bottom: 20px;
  .title {
    display: flex;
    flex-direction: row;
    align-items: center;
    color: #000c22;
    font-family: Inter;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    span {
      margin-left: 10px;
    }
  }

  .lists {
    margin-top: 10px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
    gap: 0 10px;

    .lists-item {
      width: 200px;
      height: 211px;
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-bottom: 0;

      img {
        width: 200px;
        height: 200px;
      }

      .price {
        transform: translateY(-10%);
        width: 98px;
        height: 26px;
        flex-shrink: 0;
        color: #fff;
        font-family: D-DIN-PRO;
        font-size: 18px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        background-color: #222;
        justify-content: center;
        align-items: center;
        display: flex;
      }
    }

    .last {
      width: 200px;
      height: 200px;
      padding: 0 10px;
      box-sizing: border-box;
      flex-shrink: 0;
      border: 2px solid #F4F4F4;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin-bottom: 0;

      .title {
        //background-color: #0abb75;
        display: block;
        //background-color: #0abb75;
        white-space: nowrap; /* 防止文本换行 */
        overflow: hidden; /* 隐藏溢出的文本 */
        text-overflow: ellipsis; /* 显示省略号 */
        width: 100%; /* 设置元素宽度，可以根据需要调整 */
        height: 32px;
      }
      .num {
        width: 80%;
        margin-top: 12px;
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        align-items: center;
        color: #bdb1a1;
        text-align: center;
        font-family: PingFang SC;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 14px;
        white-space: nowrap;
      }

      .star {
      }

      .ntn {
        cursor: pointer;
        margin-top: 12px;
        width: 154px;
        height: 48px;
        background-color: #d73a4e;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #fff;
        font-family: Inter;
        font-size: 18px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        text-transform: none;
      }
    }
  }
}
</style>
