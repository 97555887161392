<template>
  <div class="containerCon">
    <div class="phone" v-if="flag">
      <div class="actives">
        <div class="left">
          <!-- <img src="https://ntbuy.life/static/svg/name-8a11689a.svg" alt=""> -->
          <!-- <div class="title" v-if="$store.getters.getarea_code.country_code == undefined">{{ '+' + 1 }}</div> -->
          <!-- <div class="title" v-else>{{ '+' + $store.getters.getarea_code.country_code }}</div> -->
        </div>
        <div class="rights" v-if="!flag">
          <SvgIcon iconClass="down" color="#333" width="8" height="8"
            style="transform: rotate(0deg); transition: all 0.2s ease 0s;" :class="[isShowdown ? 'deg180' : '']" />
        </div>
      </div>
      <div class="options" :class="[isShowdown ? 'h120' : '']">
        <div class="item" v-for="(item, index) in flag ? phonelist : langlist" :key="index"
          :class="[$store.getters.getLocalLang.language === item.language ? 'active' : '']"
          @click="handleClick(index, item, 'phone')">
          <div class="select-item">
            <div>
              <span v-if="item.country_code">+{{ item.country_code }}</span>
            </div>
          </div>
        </div>
      </div>
      <div class="phonesle">
        <el-select v-model="value" filterable>
          <el-option v-for="(item, index) in phonelist" :key="index" :no-match-text="notext" :value="item.country_code">
            +{{ item.country_code }}
          </el-option>
        </el-select>
      </div>
    </div>
    <div class="lang" v-else>
      <div class="actives" @click.prevent.stop="handleClicklang">
        <div class="left">
          <!-- <img src="https://ntbuy.life/static/svg/name-8a11689a.svg" alt=""> -->
          <div class="title">{{ $store.getters.getLocalLang.language || localLang.language }}</div>
          <!-- <div class="title">{{ title_language }}</div> -->
        </div>
        <div class="rights">
          <SvgIcon iconClass="down" color="#333" width="8" height="8"
            style="transform: rotate(0deg); transition: all 0.2s ease 0s;" :class="[isShowdown ? 'deg180' : '']" />
        </div>
      </div>
      <div class="options" :class="[isShowdown ? 'h120' : '']">
        <div class="item" v-for="(item, index) in langlist" :key="index"
          :class="[$store.getters.getLocalLang.language === item.language ? 'active' : '']"
          @click="handleClick(index, item, 'lang')">
          <div class="select-item">
            <div>
              <!-- <img :src="item.img" alt=""> -->
              <span>{{ item.language }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState, mapMutations } from 'vuex';
export default {
  props: {
    isShowdown: {
      default: ''
    },
    flag: {
      type: Boolean
    }
  },
  data() {
    return {
      idx: 0,
      title_language: '',
      title_country_code: '',
      value: '376',
      notext: '1111'
    }
  },
  created() {
  },
  computed: {
    ...mapState(['langlist', 'phonelist', 'localLang'])
  },
  methods: {
    ...mapMutations(["SET_LOCAL_LANG", "SET_LOCAL_QH"]),
    handleClicklang() {
      this.$emit('clicks')
    },
    handleClick(i, item, type) {
      if (type === 'lang') {
        if (item.code === 'fan') {
          item.code = 'zh-Hant'
        }
        this.title_language = item.language
        this.SET_LOCAL_LANG(item);
        this.idx = i
        location.reload();
      } else {
        this.SET_LOCAL_QH(item);
        this.title_country_code = item.country_code
      }
    }
  },
}
</script>
<style lang='less' scoped>
.containerCon {
  width: fit-content;
  height: auto;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;

  .actives {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    cursor: pointer;

    .left {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      margin-right: 4px;

      .title {
        margin-left: 4px;
        white-space: nowrap;
        text-align: right;
        font-family: Inter;
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        text-transform: none;
        color: #000c22;
      }

      img {
        width: 24px;
        height: 18px;
        border-radius: 2px;
      }
    }

    .rights {
      height: 19px;
    }
  }

  .options {
    transition: all .2s ease;
    position: absolute;
    z-index: 99999;
    top: 102%;
    // right: 0;
    width: fit-content;
    min-width: 100px;
    height: fit-content;
    overflow: auto;
    overflow-y: auto;
    background-color: #fff;
    box-sizing: border-box;
    max-height: 0px;

    &::-webkit-scrollbar {
      width: 2px;
      height: 2px;
    }

    &::-webkit-scrollbar-track {
      box-shadow: inset 0 0 5px rgba(0, 0, 0, .1);
      border-radius: 0;
      background: #c7ddfc;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 10px;
      box-shadow: inset 0 0 5px rgba(0, 0, 0, .1);
      background: #a8abb2;
    }

    &::-webkit-scrollbar-track-piece {
      background: #eff3f8;
      height: 5px;
    }

    .item {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 40px;
      padding: 0 4px;
      box-sizing: border-box;

      &:hover {
        background-color: #222;
        color: #fff;
        cursor: pointer;
      }

      .select-item {
        padding: 5px;
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;

        div {
          img {}

          span {}
        }

        &>div {
          width: 100%;
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;

          img {
            width: 24px;
            height: 18px;
            border-radius: 2px;
          }

          span {
            margin-left: 4px;
            white-space: nowrap;
            overflow: hidden;
            flex: 1;
            text-align: left;
            text-overflow: ellipsis;
            font-size: 19px;
          }
        }
      }
    }

    .active {
      background-color: #222;
      color: #fff;
    }
  }

  .phonesle {
    // width: 80px;
    width: auto;
    min-width: 50px;
    max-width: 120px;
    height: 48px;

    /deep/ .el-select {
      height: 100%;

      &>.el-input {
        height: 100%;

        input {
          height: 100%;
          border: 0;
          padding: 0 15px;
        }
      }
    }
  }

  .h120 {
    max-height: 120px !important;
  }

  .deg180 {
    transform: rotate(180deg) !important;
  }

  .active {
    background-color: #222;
    color: #fff;
  }
}

@media screen and(max-width:390px) {
  .phonesle {
    /deep/ .el-select {
      &>.el-input {

        input {
          height: 100%;
          border: 0;
          padding: 0 !important;
          padding-left: 15px !important;
        }

        .el-input__suffix {
          display: none;
        }
      }
    }
  }
}
</style>
