import Vue from 'vue'
import ElementUI, {Message} from 'element-ui';
import Vant from 'vant';
import 'vant/lib/index.css';
import 'element-ui/lib/theme-chalk/index.css';
import '@/utils/drag'
import App from './App.vue'
import router from './router'
import store from './store'
import VueI18n from 'vue-i18n'
import api from "./api/api";
import messages from './lang/index'
import './style/base.css'
import './utils/icon/index'
import shopimg from '@/components/shop-img.vue'
import GoodsItem from '@/components/GoodsItem.vue'
import shopItem from '@/components/shopItem.vue'
import {SERVERTAPI} from "@/config";
 
Vue.use(ElementUI);
Vue.use(Vant);
Vue.config.productionTip = false
Vue.prototype.$http = api;
Vue.prototype.$store = store;
Vue.prototype.$Message = Message
Vue.prototype.$upload_url = SERVERTAPI + 'api/Common/uploadPic'
Vue.prototype.$SERVERTAPI = SERVERTAPI
Vue.component('shop-img', shopimg)
Vue.component('GoodsItem', GoodsItem)
Vue.component('shopItem', shopItem)
let i18nConfig = {
    locale: store.getters.getLocalLang.code,
    messages
}
// router.beforeEach((to,from,next)=>{
//   console.log(to);
//   if(to.path === 'goodsDetail'){
//     if(store.state.isLogin){
//       next()
//     }else{
//       this.$Message.warning('请先登录')
    //   store.commit('SET_MODAL_LOGIN',true)
//     }
//   }
// })
Vue.filter('dateFormat', (val) => {
    const dt = new Date(val)
    const y = dt.getFullYear()
    const m = (dt.getMonth() + 1 + '').padStart(2, '0')
    const d = (dt.getDate() + '').padStart(2, '0')
    const hh = (dt.getHours() + '').padStart(2, '0')
    const mm = (dt.getMinutes() + '').padStart(2, '0')
    const ss = (dt.getSeconds() + '').padStart(2, '0')
    //时间格式年月日、时分秒
    return `${y}-${m}-${d} ${hh}:${mm}:${ss}`
})
Vue.filter('defaultValue', value => {
    if (!value) return 0;
    return value
})
Vue.use(VueI18n)
const i18n = new VueI18n(i18nConfig)
new Vue({
    router,
    store,
    i18n,
    render: h => h(App)
}).$mount('#app')
