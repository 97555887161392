<template>
    <svg :class="['svg-icon', className]" aria-hidden="true" :width="width" :height="height">
        <use :xlink:href="iconName" :fill="color" />
    </svg>
</template>
  
<script>
export default {

    name: "SvgIcon",
    props: {
        iconClass: {
            type: String,
            required: true,
        },
        className: {
            type: String,
            default: "",
        },
        color: {
            type: String,
            default: ''
        },
        width: {
            type: String,
            default: ''
        },
        height: {
            type: String,
            default: ''
        }
    },
    computed: {
        iconName() {
            return `#icon-${this.iconClass}`;
        },
    },
};
</script>
  
<style lang="less" scoped>
// svg图标样式，可自行进行修改
.svg-icon {
    overflow: hidden;
    cursor: pointer;
}
</style>
  
